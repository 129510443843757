import { array } from "prop-types"
import React, { useEffect, useState } from "react"
import { ListarLocalidad } from "utils/Querypanel"
import { insertLocalidad, getMapacolor, getLocalidadmapa } from "utils/Localidadmap"

import "./isvg.css"
import "./class.css"
import ExampleDataTable from "components/ReactTable/Datatable.js/ExampleTable"
import ModalFirma from "views/Components/MODAL/Modalfirma"
const Viewssvg = () => {
	const [localidadmap, setselection] = useState({
		name: "",
		color: '#A12121',
	})
	const [mapa, setmapa] = useState([])
	const [lista, setLsita] = useState([])
	const [localidad, setLocalidad] = useState([])

	function handelChange(e) {
		setselection({
			...localidadmap,
			[e.name]: e.value
		})

	}

	/*
		function agergaraALarray(dato,id,color){
		   let array = lista       
		  // let nuevo = mapa
		 // console.log(array)
				var index = array.findIndex(obj => obj.path==dato);
			  if (index == -1) { 
				array.push({path:dato,id:id, fill:color});
			    
			  } else {
				do {
				  array.splice(index, 1);
				 index = array.indexOf({path:dato,id:id, fill:color});
				} while (index != -1);
			  }
			  setLsita(array)
			//  console.log("mpap?",nuevo) 
			insertLocalidad(array,{path:dato,id:id, fill:color})
			cargarcolores()
			listadecolores()
		    
			}
		    
			function cargarcolores (){
				let colores = getMapacolor()
				colores.length>0? setLsita(colores):''
				colores.length>0? colores.map((e,i)=>{
					$("#"+e.path).attr("class","seleccion")               
					$("#"+e.path).attr("machfilal",e.fill,"class","seleccion")        
				}):''
			}
					 $(document).on("click",".none",function(){
					let co = document.getElementById("color").value;
					let id = document.getElementById("name").value;
					if(this.classList.contains('none')){
						if(id.trim()=== "") {
						return  }
						else
					agergaraALarray(this.getAttribute('id'),id,co)   
							  this.removeAttribute("class","")       
							  this.setAttribute("class","seleccion")   
							}
					 })
					$(document).on("click",".seleccion",function(){
					 if(this.classList.contains('seleccion')){
						this.removeAttribute("machfilal")   
						agergaraALarray(this.getAttribute('id'),'','')
						this.removeAttribute("class","seleccion")   
						this.setAttribute("class","")                             
							} 
					 })
	  
	   
					 async function GetLocalidad(){
						let obtent = await ListarLocalidad()
						setLocalidad(obtent.data)
						let nuevo = obtent.data.map((e,i)=>{
							return{ id:e.id, nombre:e.nombre,color:''}
						})
						setmapa(obtent.data.map((e,i)=>{
							return{ id:e.id, nombre:e.nombre,color:''}
						}))
						//console.log("localidades",nuevo)
						sessionStorage.localidad = JSON.straingify(obtent.data)
						cargarcolores()   
					}
					async function listadecolores(){
						let nuevo = getLocalidadmapa()
					  //  console.log("Function mapa",nuevo)
						let colores = getMapacolor()
						const valorDuplicadas = [];
						nuevo.length>0 && colores.length>0 ? colores.forEach(p => {
								if(valorDuplicadas.findIndex(pd => pd.id === p.id) === -1) {       
								 let index =nuevo.findIndex((e)=>parseInt(e.id)=== parseInt(p.id))
									valorDuplicadas.push({id:p.id,nombre:nuevo[index]?nuevo[index].nombre:'',color:p.fill});
								}
								}):''     
						nuevo.length>0 && colores.length>0 ? nuevo.map((L)=>{
								if(valorDuplicadas.findIndex((e)=>parseInt(e.id)=== parseInt(L.id))!=-1){
									L.color=valorDuplicadas[valorDuplicadas.findIndex((e)=>parseInt(e.id)=== parseInt(L.id))].color;
									return L
								}else{
									return L
								}
								}):''
					   //         console.log("mutado",nuevo)  
    
							 // console.log("duplicado",valorDuplicadas)
							  nuevo.length>0 && colores.length>0?setmapa(nuevo) :''
							  nuevo.length>0 && colores.length>0? sessionStorage.localidad = JSON.straingify(nuevo):''
					}*/

	useEffect(() => {
		const paths = document.querySelectorAll(" polygon.cuadro, rect.cuadro")
		paths.forEach(e => {
			e.addEventListener("click", function () {
				var t = document.createElementNS("http://www.w3.org/2000/svg", "text");
				var b = this.getBBox();
				console.log(b)
				console.log((b.x + b.width / 2) + " " + (b.y + b.height / 2))
				t.setAttribute("transform", "translate(" + (b.x + b.height / 8) + " " + (b.y + b.height / 2) + ")");
				t.textContent = "NOMBRE DE LOCALIDAD";
				t.setAttribute("machfilal", "white");
				t.setAttribute("font-size", (b.height / 10));
				this.parentNode.insertBefore(t, b.nextSibling);
				//  b.parentNode.insertBefore(t, b.nextSibling);

				//this.classList.add("class")   
				//this.setAttribute("machfilal","#DC2A27")
				//console.log( this)
			})
		})
		const textPaths = document.querySelectorAll("polygon.texto")

		textPaths.forEach(e => {
			e.addEventListener("click", function () {
				var t = document.createElementNS("http://www.w3.org/2000/svg", "text");
				var b = this.getBBox();
				console.log(b)
				//console.log((b.x + b.width/2) + " " + (b.y + b.height/2))
				t.setAttribute("transform", "translate(" + (b.x + b.height / 8) + " " + (b.y + b.height) + ")");
				t.textContent = "Sillas";
				t.setAttribute("machfilal", "white");
				t.setAttribute("font-size", (b.height));
				this.parentNode.insertBefore(t, b.nextSibling);
			})
		})


		//agregar id y class
		/*
 var t = document.createElementNS("http://www.w3.org/2000/svg", "text");
	var b = p.getBBox();
	t.setAttribute("transform", "translate(" + (b.x + b.width/2) + " " + (b.y + b.height/2) + ")");
	t.textContent = "a";
	t.setAttribute("machfilal", "red");
	t.setAttribute("font-size", "14");
	p.parentNode.insertBefore(t, p.nextSibling);*/

		/*
		const path = document.querySelectorAll("path")
		  $(path).each(function(index){ 
				this.setAttribute("id", "mapas"+index)
				this.setAttribute("class", "mapas")
			   // console.log(this)
				//alert($(this).text())
			});*/


	}, [])


	return (
		<>
			<ModalFirma/>
			<div className="d-flex flex-wrap">
				<div className="col-12 col-md-6">
					<form className="container">
						<div className="row">
							<div className="col-8">
								<label className="form-label">Selecione localidad elija el color de la Localidad </label>
								<select className="form-control" value={localidadmap.name} name="name" id="name" onChange={(e) => handelChange(e.target)}>
									<option value="">

									</option>


									{localidad.length > 0 ?
										localidad.map((e, i) => {
											return (
												<option key={i} value={e.id} >{e.nombre}</option>
											)
										}) : ''
									}
								</select>
							</div>
							<div className="col-sm">
								<label className="form-label" >.</label>
								<input
									className="form-control form-control-color"
									value={localidadmap.color} name="color" id="color"
									type="color"
									onChange={(e) => handelChange(e.target)}
								/>
							</div>

						</div>


					</form>

				</div>
				<div className=" d-flex flex-column text-center justify-content-center col-12 col-md-6">
					<div className="d-flex flex-wrap justify-content-center  pb-5">
						{mapa.length > 0 ?
							mapa.map((elm, i) => {
								return (
									<div className="d-flex flex-row px-3 precios align-items-center" key={i}  >
										<div className="mx-1  rounded-4" style={{ height: 20, width: 20, backgroundColor: elm.color }}></div>
										<span>{elm.nombre}</span>
									</div>
								)
							}) : ''
						}
					</div>
					<div className=" border d-flex text-center bg-azul justify-content-center" style={{ width: '100%' }}>


						<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1080 1080">

							<path class="stci0" d="M1010.06,615.57c0,0.5,0,0.99,0,1.49c-0.56,0.56-0.32,1.27-0.33,1.92c-0.07,3-0.1,5.99-0.15,8.99
	c-0.69-0.05-0.55,0.47-0.64,0.88c-0.65,2.98-1.22,5.96-2.23,8.87c-3.96,11.41-11.12,20.57-19.81,28.7
	c-5.31,4.97-11.24,9.13-17.41,12.94c-16.39,10.11-32.81,20.18-49.21,30.28c-26.94,16.57-53.88,33.16-80.82,49.73
	c-20.41,12.56-40.96,24.91-61.21,37.71c-16.47,10.41-33.83,18.57-52.67,23.59c-13.25,3.53-26.71,5.85-40.35,7.04
	c-7.75,0.68-15.51,1.07-23.33,0.75c-4.67-0.19-9.37,0.05-14.07-0.21c-9.25-0.52-18.43-1.52-27.57-3
	c-13.39-2.16-26.43-5.63-39.22-10.1c-10.14-3.55-19.77-8.24-29.17-13.4c-19.17-10.52-38.35-21.01-57.53-31.51
	c-13.8-7.55-27.61-15.1-41.41-22.65c-16.25-8.89-32.49-17.79-48.73-26.68c-13.85-7.58-27.71-15.15-41.57-22.73
	c-17.66-9.66-35.31-19.33-52.97-29c-13.8-7.55-27.61-15.09-41.41-22.64c-17.66-9.66-35.31-19.33-52.97-29
	c-15.32-8.39-30.65-16.76-45.97-25.15c-16.25-8.89-32.48-17.8-48.75-26.65c-10.26-5.58-20.11-11.76-28.65-19.81
	c-5.96-5.61-11.2-11.79-15.35-18.92c-1.54-2.65-2.89-5.4-4.03-8.17c-1.4-3.4-2.5-7.01-2.94-10.75c-0.15-1.32-0.15-2.73-0.9-3.94
	c-0.36-3.72-0.1-7.44,0.41-11.1c1.43-10.43,5.52-19.76,11.94-28.12c5.59-7.27,12.23-13.41,19.54-18.86
	c5.83-4.35,12.26-7.76,18.44-11.57c7.16-4.42,14.26-8.96,21.52-13.22c5.49-3.23,10.87-6.64,16.28-10
	c5.58-3.46,11.12-6.98,16.74-10.38c7.5-4.54,15.07-8.96,22.58-13.46c5.07-3.04,10.13-6.09,15.14-9.23
	c6.17-3.87,12.35-7.7,18.63-11.4c7.41-4.36,14.66-8.99,22-13.47c5.24-3.2,10.42-6.51,15.72-9.61c6.85-4.01,13.63-8.14,20.36-12.32
	c3.84-2.38,7.71-4.81,11.57-7.13c4.45-2.67,9.02-5.22,13.56-7.78c12.36-6.97,25.48-12.04,39.13-15.78
	c15.71-4.31,31.75-6.85,47.99-7.7c7.87-0.41,15.78-0.64,23.71-0.28c9.7,0.44,19.36,1.01,28.96,2.4c5.67,0.82,11.36,1.65,16.95,2.9
	c6.68,1.49,13.25,3.47,19.89,5.15c4.14,1.05,8.02,2.82,11.99,4.32c4.56,1.72,9.09,3.6,13.44,5.79c6.51,3.29,12.85,6.91,19.26,10.39
	c6.03,3.27,12.07,6.53,18.09,9.81c10.53,5.74,21.05,11.51,31.59,17.25c7.66,4.17,15.34,8.28,23,12.44
	c9.18,4.99,18.32,10.04,27.5,15.03c9.29,5.05,18.61,10.04,27.9,15.09c12.33,6.7,24.65,13.43,36.98,20.13
	c7.72,4.2,15.46,8.35,23.18,12.55c12.17,6.62,24.33,13.27,36.51,19.9c7.66,4.17,15.36,8.29,23.02,12.46
	c11.79,6.42,23.56,12.88,35.35,19.3c9.13,4.97,18.29,9.89,27.42,14.86c13.37,7.28,26.72,14.59,40.09,21.86
	c9.19,5,18.4,9.94,27.59,14.94c13.37,7.28,26.69,14.63,40.09,21.85c9.2,4.96,18.31,10.03,26.6,16.47
	c7.23,5.61,13.76,11.94,18.99,19.42c5.8,8.29,9.99,17.34,11.04,27.59C1009.4,615.13,1009.51,615.5,1010.06,615.57z"/>
							<path class="stci1" d="M650.88,892.5c0.34-1.03-0.52-0.7-0.9-0.71c-7.37-0.24-14.7-0.9-22.01-1.84c-24.81-3.22-48.71-9.56-70.9-21.32
	c-17.67-9.36-35.11-19.14-52.66-28.73c-21.56-11.8-43.13-23.6-64.69-35.4c-18.9-10.35-37.79-20.71-56.69-31.06
	c-21.34-11.69-42.69-23.36-64.03-35.04c-18.9-10.35-37.79-20.71-56.69-31.06c-20.53-11.24-41.06-22.47-61.59-33.71
	c-18.4-10.08-36.81-20.15-55.2-30.25c-11.4-6.26-22.81-12.49-34.13-18.87c-6.76-3.81-13-8.39-18.81-13.55
	c-9.94-8.82-17.92-19.03-22.19-31.8c-2.38-7.13-2.99-14.45-2.56-21.95c0.34-5.93,0.07-11.89,0.28-17.83
	c0.29-8.18,0.08-16.38,0.55-24.56c0.2-3.54,0.03-7.1,0.02-10.64c0.75,1.21,0.74,2.62,0.9,3.94c0.44,3.74,1.54,7.35,2.94,10.75
	c1.14,2.77,2.49,5.52,4.03,8.17c4.15,7.13,9.39,13.3,15.35,18.92c8.54,8.05,18.39,14.22,28.65,19.81
	c16.27,8.85,32.5,17.76,48.75,26.65c15.32,8.38,30.65,16.76,45.97,25.15c17.66,9.66,35.31,19.34,52.97,29
	c13.8,7.55,27.61,15.09,41.41,22.64c17.66,9.66,35.31,19.33,52.97,29c13.85,7.58,27.72,15.15,41.57,22.73
	c16.25,8.89,32.49,17.79,48.73,26.68c13.8,7.55,27.61,15.1,41.41,22.65c19.18,10.5,38.36,20.99,57.53,31.51
	c9.41,5.16,19.03,9.85,29.17,13.4c12.78,4.47,25.83,7.94,39.22,10.1c9.14,1.48,18.33,2.48,27.57,3c4.69,0.26,9.4,0.02,14.07,0.21
	c7.82,0.32,15.58-0.07,23.33-0.75c13.64-1.19,27.1-3.51,40.35-7.04c18.84-5.02,36.21-13.18,52.67-23.59
	c20.26-12.8,40.8-25.15,61.21-37.71c26.94-16.57,53.88-33.15,80.82-49.73c16.4-10.09,32.82-20.16,49.21-30.28
	c6.17-3.81,12.09-7.97,17.41-12.94c8.69-8.13,15.85-17.29,19.81-28.7c1.01-2.9,1.58-5.89,2.23-8.87c0.09-0.4-0.04-0.93,0.64-0.88
	c-0.09,0.54-0.24,1.09-0.26,1.63c-0.12,4.33-0.21,8.66-0.33,13c-0.38,14.67-0.75,29.34-1.15,44.01c-0.23,8.21-2.62,15.84-6.48,23.01
	c-6.63,12.31-16.28,21.99-27.92,29.37c-25.59,16.24-51.5,31.99-77.28,47.93c-11.18,6.91-22.36,13.82-33.56,20.7
	c-13.37,8.21-26.77,16.38-40.14,24.59c-15.79,9.7-31.61,19.37-47.34,29.18c-10.32,6.43-20.93,12.27-32.34,16.58
	c-16.7,6.31-33.96,10.24-51.67,12.32c-6.27,0.74-12.56,1.29-18.88,1.5c-0.38,0.01-1.24-0.31-0.92,0.71c-0.37,0-0.74,0-1.12,0
	c-0.13-0.87-0.84-0.55-1.28-0.54c-5.16,0.17-10.31,0.18-15.47,0.02c-0.39-0.01-1.03-0.27-1.1,0.52
	C651.87,892.5,651.38,892.5,650.88,892.5z"/>
							<path class="" fill="#A4D520" d="M103.7,501.7c-0.9-8.3,2-15.2,7.4-21.3c2.9-3.2,6.3-5.9,10-8.2c16.5-10.2,33-20.4,49.6-30.7
		c34.2-21.1,68.4-42.3,102.6-63.4c35.4-21.9,70.9-43.8,106.3-65.7c9-5.6,18.7-9,29-11c13-2.5,26.1-2.6,39.1-0.5
		c11.1,1.8,21.7,5,31.6,10.4c21,11.4,42.1,22.7,63.1,34c34.5,18.6,69,37.2,103.5,55.8c45.2,24.3,90.4,48.7,135.5,73
		c15.8,8.5,31.7,17,47.5,25.6c1.5,0.8,3,1.7,4.5,2.7c1.3,1,3,1.3,4.5,2.1c17.5,9.5,35,18.9,52.6,28.3c9.6,5.2,19.3,10.2,28.8,15.6
		c2.2,1.3,4.8,1.9,6.7,3.9c4.8,1.7,8.9,4.6,13.4,6.8c3,1.5,5.9,3.2,8.9,4.8c6.5,3.5,13,7,19.5,10.6c2.5,1.4,4.8,2.9,6.8,4.8
		c2.7,2.5,5.3,5.1,7.5,7.9c4.7,5.9,6.2,12.7,5.1,20.1c-1.7,7.9-6.3,13.9-12.6,18.6c-7.8,5.8-16.4,10.6-24.6,15.7
		c-20.7,13-41.5,25.9-62.2,38.9c-8.2,5.1-16.4,10.4-24.7,15.5c-6.2,3.9-12.4,7.6-18.5,11.5c-7.3,4.6-14.7,9.1-22,13.7
		c-11.6,7.2-23.2,14.5-34.8,21.7c-22.3,13.9-44.6,27.8-66.9,41.7c-4.7,3-9.4,6.1-14.3,8.7c-11.2,5.8-23.2,8.6-35.6,9.4
		c-10.5,0.7-20.9,0.3-31.3-1.8c-9.4-1.9-18.4-4.8-26.8-9.4c-14.8-8-29.7-16.1-44.5-24.1c-8.8-4.8-17.6-9.6-26.5-14.3
		c-4.7-2.5-9.4-4.9-14.1-7.4c-2.6-1.3-5.2-2.9-7.8-4.4c-3.9-2.3-7.9-4.2-11.8-6.5c-3.8-2.2-7.8-4-11.6-6.2
		c-5.8-3.3-11.7-6.4-17.6-9.5c-3-1.6-6.1-3-9-4.8c-2.8-1.7-5.9-3.1-8.7-4.7c-8.1-4.7-16.5-9.1-24.8-13.6
		c-17.3-9.4-34.7-18.8-52.1-28.2c-18.8-10.1-37.5-20.3-56.3-30.4c-1.4-0.8-2.7-1.6-4.3-2c-1.6-0.4-2.6-1.8-4-2.6
		c-3.3-1.7-6.7-3.5-10-5.3c-4.3-2.4-8.9-4.4-13.1-7.1c-0.8-0.5-1.9-0.5-2.6-1.1c-2.1-1.6-4.5-2.5-6.7-3.9c-2.6-1.6-5.6-2.6-8.1-4.3
		c-3.6-2.5-7.8-3.8-11.4-6.3c-2-1.4-4.3-2.4-6.5-3.6c-12.7-6.9-25.5-13.7-38.2-20.6c-18.3-9.9-36.5-19.8-54.8-29.7
		c-14.1-7.6-28.3-15.3-42.4-22.9c-5.2-2.8-10-6.2-14.1-10.6C107.8,513,104.7,507.9,103.7,501.7z"/>
							<g>
								
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<polygon class="stci2" points="461.07,338.5 475.92,346.86 461.22,355.13 446.37,346.78 						" />
													<polygon class="stci3" points="461.07,337.62 475.92,345.97 461.22,354.24 446.37,345.89 						" />
													<polygon class="stci2" points="461.22,354.24 461.22,355.13 475.92,346.86 475.92,345.97 						" />
													<polygon class="stci4" points="446.37,345.89 446.37,346.78 461.22,355.13 461.22,354.24 						" />
												</g>
												<g>
													<g>
														<polygon class="stci5" points="451.61,345.96 450.12,346.82 450.12,194.12 451.61,193.26 							" />
														<polygon class="stci6" points="448.64,345.96 450.12,346.82 450.12,193.34 448.64,192.49 							" />
													</g>
												</g>
												<g>
													<g>
														<polygon class="stci5" points="470.78,345.96 472.27,346.82 472.27,193.56 470.78,192.7 							" />
														<polygon class="stci6" points="473.75,345.96 472.27,346.82 472.27,193.56 473.75,192.7 							" />
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M471.05,323.31l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C471.41,323.56,471.27,323.35,471.05,323.31z"/>
															</g>
															<g>
																<path class="stci5" d="M471.22,323.38c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C471.43,323.76,471.39,323.51,471.22,323.38z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,316.19c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04V322l9.13-5.27
										C471.23,316.62,471.3,316.38,471.19,316.19z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,331.22c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C471.23,331.65,471.3,331.41,471.19,331.22z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M471.05,338.35l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C471.41,338.59,471.27,338.38,471.05,338.35z"/>
															</g>
															<g>
																<path class="stci5" d="M471.22,338.42c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C471.43,338.79,471.39,338.55,471.22,338.42z"/>
															</g>
															<g>
																<path class="stci5" d="M461.92,352.06l9.61-5.61c-0.15-0.33-0.26-0.73-0.45-0.62l-9.16,5.32V352.06z" />
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M471.05,293.25l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C471.41,293.49,471.27,293.29,471.05,293.25z"/>
															</g>
															<g>
																<path class="stci5" d="M471.22,293.32c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C471.43,293.7,471.39,293.45,471.22,293.32z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,286.13c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C471.23,286.56,471.3,286.32,471.19,286.13z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,301.16c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C471.23,301.59,471.3,301.35,471.19,301.16z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M471.05,308.28l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C471.41,308.53,471.27,308.32,471.05,308.28z"/>
															</g>
															<g>
																<path class="stci5" d="M471.22,308.35c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C471.43,308.73,471.39,308.48,471.22,308.35z"/>
															</g>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M471.05,263.19l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C471.41,263.43,471.27,263.23,471.05,263.19z"/>
															</g>
															<g>
																<path class="stci5" d="M471.22,263.26c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C471.43,263.63,471.39,263.39,471.22,263.26z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,256.07c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C471.23,256.5,471.3,256.26,471.19,256.07z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,271.1c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C471.23,271.53,471.3,271.29,471.19,271.1z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M471.05,278.22l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C471.41,278.46,471.27,278.26,471.05,278.22z"/>
															</g>
															<g>
																<path class="stci5" d="M471.22,278.29c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C471.43,278.67,471.39,278.42,471.22,278.29z"/>
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M471.05,233.13l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C471.41,233.37,471.27,233.16,471.05,233.13z"/>
															</g>
															<g>
																<path class="stci5" d="M471.22,233.2c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C471.43,233.57,471.39,233.33,471.22,233.2z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,226c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C471.23,226.43,471.3,226.19,471.19,226z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,241.04c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C471.23,241.47,471.3,241.23,471.19,241.04z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M471.05,248.16l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C471.41,248.4,471.27,248.2,471.05,248.16z"/>
															</g>
															<g>
																<path class="stci5" d="M471.22,248.23c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C471.43,248.6,471.39,248.36,471.22,248.23z"/>
															</g>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M450.94,323.31l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C450.58,323.56,450.72,323.35,450.94,323.31z"/>
															</g>
															<g>
																<path class="stci5" d="M450.77,323.38c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C450.56,323.76,450.6,323.51,450.77,323.38z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,316.19c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04V322l-9.13-5.27
										C450.76,316.62,450.69,316.38,450.8,316.19z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,331.22c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C450.76,331.65,450.69,331.41,450.8,331.22z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M450.94,338.35l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C450.58,338.59,450.72,338.38,450.94,338.35z"/>
															</g>
															<g>
																<path class="stci5" d="M450.77,338.42c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C450.56,338.79,450.6,338.55,450.77,338.42z"/>
															</g>
															<g>
																<path class="stci5" d="M460.07,352.06l-9.61-5.61c0.15-0.33,0.26-0.73,0.45-0.62l9.16,5.32V352.06z" />
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M450.94,293.25l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C450.58,293.49,450.72,293.29,450.94,293.25z"/>
															</g>
															<g>
																<path class="stci5" d="M450.77,293.32c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C450.56,293.7,450.6,293.45,450.77,293.32z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,286.13c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C450.76,286.56,450.69,286.32,450.8,286.13z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,301.16c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C450.76,301.59,450.69,301.35,450.8,301.16z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M450.94,308.28l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C450.58,308.53,450.72,308.32,450.94,308.28z"/>
															</g>
															<g>
																<path class="stci5" d="M450.77,308.35c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C450.56,308.73,450.6,308.48,450.77,308.35z"/>
															</g>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M450.94,263.19l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C450.58,263.43,450.72,263.23,450.94,263.19z"/>
															</g>
															<g>
																<path class="stci5" d="M450.77,263.26c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C450.56,263.63,450.6,263.39,450.77,263.26z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,256.07c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C450.76,256.5,450.69,256.26,450.8,256.07z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,271.1c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C450.76,271.53,450.69,271.29,450.8,271.1z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M450.94,278.22l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C450.58,278.46,450.72,278.26,450.94,278.22z"/>
															</g>
															<g>
																<path class="stci5" d="M450.77,278.29c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C450.56,278.67,450.6,278.42,450.77,278.29z"/>
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M450.94,233.13l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C450.58,233.37,450.72,233.16,450.94,233.13z"/>
															</g>
															<g>
																<path class="stci5" d="M450.77,233.2c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C450.56,233.57,450.6,233.33,450.77,233.2z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,226c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C450.76,226.43,450.69,226.19,450.8,226z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,241.04c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C450.76,241.47,450.69,241.23,450.8,241.04z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M471.05,218.15l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C471.41,218.39,471.27,218.18,471.05,218.15z"/>
															</g>
															<g>
																<path class="stci5" d="M471.22,218.22c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C471.43,218.59,471.39,218.34,471.22,218.22z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,211.02c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C471.23,211.45,471.3,211.21,471.19,211.02z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,226.06c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C471.23,226.49,471.3,226.25,471.19,226.06z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M450.94,218.15l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C450.58,218.39,450.72,218.18,450.94,218.15z"/>
															</g>
															<g>
																<path class="stci5" d="M450.77,218.22c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C450.56,218.59,450.6,218.34,450.77,218.22z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,211.02c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C450.76,211.45,450.69,211.21,450.8,211.02z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,226.06c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C450.76,226.49,450.69,226.25,450.8,226.06z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M471.05,203.11l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C471.41,203.35,471.27,203.15,471.05,203.11z"/>
															</g>
															<g>
																<path class="stci5" d="M471.22,203.18c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C471.43,203.56,471.39,203.31,471.22,203.18z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,195.99c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C471.23,196.42,471.3,196.18,471.19,195.99z"/>
															</g>
															<g>
																<path class="stci5" d="M471.19,211.02c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C471.23,211.45,471.3,211.21,471.19,211.02z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M450.94,203.11l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C450.58,203.35,450.72,203.15,450.94,203.11z"/>
															</g>
															<g>
																<path class="stci5" d="M450.77,203.18c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C450.56,203.56,450.6,203.31,450.77,203.18z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,195.99c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C450.76,196.42,450.69,196.18,450.8,195.99z"/>
															</g>
															<g>
																<path class="stci5" d="M450.8,211.02c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C450.76,211.45,450.69,211.21,450.8,211.02z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M450.94,248.16l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C450.58,248.4,450.72,248.2,450.94,248.16z"/>
															</g>
															<g>
																<path class="stci5" d="M450.77,248.23c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C450.56,248.6,450.6,248.36,450.77,248.23z"/>
															</g>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<polygon class="stci5" points="462.68,352.42 461.2,353.28 461.2,199.09 462.68,198.23 						" />
													<polygon class="stci5" points="461.2,351.57 459.71,352.43 459.71,232.62 461.2,231.77 						" />
													<polygon class="stci6" points="459.71,352.42 461.2,353.28 461.2,199.09 459.71,198.23 						" />
												</g>
											</g>
											<g>
												<polygon class="stci3" points="461.07,183.33 475.92,191.69 461.22,199.96 446.37,191.61 					" />
												<polygon class="stci2" points="461.22,199.96 461.22,200.85 475.92,192.57 475.92,191.69 					" />
												<polygon class="stci4" points="446.37,191.61 446.37,192.49 461.22,200.85 461.22,199.96 					" />
											</g>
										</g>
									</g>
									<g>
										<polygon class="stci3" points="399.43,348.93 447.63,321.1 447.63,222.61 399.43,250.44 			" />
										<polygon class="stci7" points="405.59,352.62 453.79,324.79 453.79,226.3 405.59,254.13 			" />
										<path class="stci8" d="M417.66,287.26c4.48-0.31,8.93-0.91,13.35-1.69c2.6-0.46,5.18-1.25,7.7-2.03c2.63-0.82,5.22-1.74,7.77-2.78
				c2.37-0.97,4.83-1.97,6.99-3.38c0.11-0.07,0.22-0.15,0.33-0.22v28.54c-3.79-0.75-7.64-1.18-11.49-1.45
				c-5.09-0.36-10.2-0.4-15.3-0.42c-3.76-0.01-7.48,0.06-11.23,0.41c-3.41,0.32-6.81,0.8-10.19,1.4v-18.42
				C409.6,287.58,413.64,287.54,417.66,287.26z"/>
										<path class="stci8" d="M443.94,254.23c-4.5,2.64-9.31,4.76-14.18,6.6c-5.07,1.92-10.25,3.53-15.48,4.95
				c-2.6,0.7-5.21,1.38-7.86,1.89c-0.28,0.05-0.55,0.1-0.83,0.15v-13.7l48.2-27.83v21.65C450.63,250.23,447.3,252.26,443.94,254.23z
				"/>
										<polygon class="stci2" points="399.43,348.93 405.59,352.62 405.59,254.13 399.43,250.44 			" />
										<polygon class="stci4" points="447.63,222.61 453.79,226.3 405.59,254.13 399.43,250.44 			" />
									</g>
									<g>
										<g>
											<g>
												<polygon class="stci2" points="397.97,375.54 412.81,383.9 398.11,392.17 383.27,383.82 					" />
												<polygon class="stci3" points="397.97,374.66 412.81,383.01 398.11,391.29 383.27,382.93 					" />
												<polygon class="stci2" points="398.11,391.29 398.11,392.17 412.81,383.9 412.81,383.01 					" />
												<polygon class="stci4" points="383.27,382.93 383.27,383.82 398.11,392.17 398.11,391.29 					" />
											</g>
											<g>
												<g>
													<polygon class="stci5" points="388.5,383 387.02,383.86 387.02,231.16 388.5,230.3 						" />
													<polygon class="stci6" points="385.53,383 387.02,383.86 387.02,230.39 385.53,229.53 						" />
												</g>
											</g>
											<g>
												<g>
													<polygon class="stci5" points="407.67,383 409.16,383.86 409.16,230.6 407.67,229.74 						" />
													<polygon class="stci6" points="410.64,383 409.16,383.86 409.16,230.6 410.64,229.74 						" />
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M407.95,360.36l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C408.3,360.6,408.16,360.39,407.95,360.36z"/>
														</g>
														<g>
															<path class="stci5" d="M408.11,360.43c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C408.32,360.8,408.29,360.55,408.11,360.43z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,353.23c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,353.66,408.19,353.42,408.08,353.23z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,368.27c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,368.7,408.19,368.46,408.08,368.27z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M407.95,375.39l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C408.3,375.63,408.16,375.43,407.95,375.39z"/>
														</g>
														<g>
															<path class="stci5" d="M408.11,375.46c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C408.32,375.83,408.29,375.59,408.11,375.46z"/>
														</g>
														<g>
															<path class="stci5" d="M398.81,389.1l9.61-5.61c-0.15-0.33-0.26-0.73-0.45-0.62l-9.16,5.32V389.1z" />
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M407.95,330.29l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C408.3,330.53,408.16,330.33,407.95,330.29z"/>
														</g>
														<g>
															<path class="stci5" d="M408.11,330.36c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C408.32,330.74,408.29,330.49,408.11,330.36z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,323.17c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,323.6,408.19,323.36,408.08,323.17z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,338.2c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,338.63,408.19,338.39,408.08,338.2z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M407.95,345.33l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C408.3,345.57,408.16,345.36,407.95,345.33z"/>
														</g>
														<g>
															<path class="stci5" d="M408.11,345.4c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C408.32,345.77,408.29,345.53,408.11,345.4z"/>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M407.95,300.23l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C408.3,300.47,408.16,300.27,407.95,300.23z"/>
														</g>
														<g>
															<path class="stci5" d="M408.11,300.3c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C408.32,300.68,408.29,300.43,408.11,300.3z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,293.11c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,293.54,408.19,293.3,408.08,293.11z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,308.14c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,308.57,408.19,308.33,408.08,308.14z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M407.95,315.26l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C408.3,315.51,408.16,315.3,407.95,315.26z"/>
														</g>
														<g>
															<path class="stci5" d="M408.11,315.33c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C408.32,315.71,408.29,315.46,408.11,315.33z"/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M407.95,270.17l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C408.3,270.41,408.16,270.21,407.95,270.17z"/>
														</g>
														<g>
															<path class="stci5" d="M408.11,270.24c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C408.32,270.61,408.29,270.37,408.11,270.24z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,263.05c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,263.48,408.19,263.23,408.08,263.05z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,278.08c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,278.51,408.19,278.27,408.08,278.08z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M407.95,285.2l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C408.3,285.44,408.16,285.24,407.95,285.2z"/>
														</g>
														<g>
															<path class="stci5" d="M408.11,285.27c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C408.32,285.65,408.29,285.4,408.11,285.27z"/>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M387.83,360.36l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C387.47,360.6,387.62,360.39,387.83,360.36z"/>
														</g>
														<g>
															<path class="stci5" d="M387.67,360.43c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C387.45,360.8,387.49,360.55,387.67,360.43z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,353.23c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,353.66,387.59,353.42,387.69,353.23z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,368.27c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,368.7,387.59,368.46,387.69,368.27z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M387.83,375.39l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C387.47,375.63,387.62,375.43,387.83,375.39z"/>
														</g>
														<g>
															<path class="stci5" d="M387.67,375.46c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C387.45,375.83,387.49,375.59,387.67,375.46z"/>
														</g>
														<g>
															<path class="stci5" d="M396.97,389.1l-9.61-5.61c0.15-0.33,0.26-0.73,0.45-0.62l9.16,5.32V389.1z" />
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M387.83,330.29l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C387.47,330.53,387.62,330.33,387.83,330.29z"/>
														</g>
														<g>
															<path class="stci5" d="M387.67,330.36c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C387.45,330.74,387.49,330.49,387.67,330.36z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,323.17c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,323.6,387.59,323.36,387.69,323.17z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,338.2c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,338.63,387.59,338.39,387.69,338.2z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M387.83,345.33l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C387.47,345.57,387.62,345.36,387.83,345.33z"/>
														</g>
														<g>
															<path class="stci5" d="M387.67,345.4c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C387.45,345.77,387.49,345.53,387.67,345.4z"/>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M387.83,300.23l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C387.47,300.47,387.62,300.27,387.83,300.23z"/>
														</g>
														<g>
															<path class="stci5" d="M387.67,300.3c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C387.45,300.68,387.49,300.43,387.67,300.3z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,293.11c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,293.54,387.59,293.3,387.69,293.11z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,308.14c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,308.57,387.59,308.33,387.69,308.14z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M387.83,315.26l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C387.47,315.51,387.62,315.3,387.83,315.26z"/>
														</g>
														<g>
															<path class="stci5" d="M387.67,315.33c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C387.45,315.71,387.49,315.46,387.67,315.33z"/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M387.83,270.17l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C387.47,270.41,387.62,270.21,387.83,270.17z"/>
														</g>
														<g>
															<path class="stci5" d="M387.67,270.24c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C387.45,270.61,387.49,270.37,387.67,270.24z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,263.05c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,263.48,387.59,263.23,387.69,263.05z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,278.08c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,278.51,387.59,278.27,387.69,278.08z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M407.95,255.19l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C408.3,255.43,408.16,255.22,407.95,255.19z"/>
														</g>
														<g>
															<path class="stci5" d="M408.11,255.26c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C408.32,255.63,408.29,255.39,408.11,255.26z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,248.07c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,248.5,408.19,248.25,408.08,248.07z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,263.1c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,263.53,408.19,263.29,408.08,263.1z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M387.83,255.19l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C387.47,255.43,387.62,255.22,387.83,255.19z"/>
														</g>
														<g>
															<path class="stci5" d="M387.67,255.26c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C387.45,255.63,387.49,255.39,387.67,255.26z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,248.07c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,248.5,387.59,248.25,387.69,248.07z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,263.1c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,263.53,387.59,263.29,387.69,263.1z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M407.95,240.15l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C408.3,240.4,408.16,240.19,407.95,240.15z"/>
														</g>
														<g>
															<path class="stci5" d="M408.11,240.23c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C408.32,240.6,408.29,240.35,408.11,240.23z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,233.03c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,233.46,408.19,233.22,408.08,233.03z"/>
														</g>
														<g>
															<path class="stci5" d="M408.08,248.07c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C408.13,248.5,408.19,248.25,408.08,248.07z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M387.83,240.15l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C387.47,240.4,387.62,240.19,387.83,240.15z"/>
														</g>
														<g>
															<path class="stci5" d="M387.67,240.23c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C387.45,240.6,387.49,240.35,387.67,240.23z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,233.03c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,233.46,387.59,233.22,387.69,233.03z"/>
														</g>
														<g>
															<path class="stci5" d="M387.69,248.07c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C387.65,248.5,387.59,248.25,387.69,248.07z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M387.83,285.2l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C387.47,285.44,387.62,285.24,387.83,285.2z"/>
														</g>
														<g>
															<path class="stci5" d="M387.67,285.27c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C387.45,285.65,387.49,285.4,387.67,285.27z"/>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<polygon class="stci5" points="399.57,389.46 398.09,390.32 398.09,236.13 399.57,235.27 					" />
												<polygon class="stci5" points="398.09,388.61 396.6,389.47 396.6,269.67 398.09,268.81 					" />
												<polygon class="stci6" points="396.6,389.46 398.09,390.32 398.09,236.13 396.6,235.27 					" />
											</g>
										</g>
										<g>
											<polygon class="stci3" points="397.97,220.38 412.81,228.73 398.11,237 383.27,228.65 				" />
											<polygon class="stci2" points="398.11,237 398.11,237.89 412.81,229.61 412.81,228.73 				" />
											<polygon class="stci4" points="383.27,228.65 383.27,229.54 398.11,237.89 398.11,237 				" />
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<polygon class="stci2" points="354.5,356.56 369.34,364.91 354.64,373.19 339.8,364.83 						" />
													<polygon class="stci3" points="354.5,355.67 369.34,364.03 354.64,372.3 339.8,363.95 						" />
													<polygon class="stci2" points="354.64,372.3 354.64,373.19 369.34,364.91 369.34,364.03 						" />
													<polygon class="stci4" points="339.8,363.95 339.8,364.83 354.64,373.19 354.64,372.3 						" />
												</g>
												<g>
													<g>
														<polygon class="stci5" points="345.03,364.02 343.55,364.87 343.55,212.17 345.03,211.32 							" />
														<polygon class="stci6" points="342.06,364.02 343.55,364.87 343.55,211.4 342.06,210.54 							" />
													</g>
												</g>
												<g>
													<g>
														<polygon class="stci5" points="364.2,364.02 365.69,364.87 365.69,211.61 364.2,210.76 							" />
														<polygon class="stci6" points="367.18,364.02 365.69,364.87 365.69,211.61 367.18,210.76 							" />
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M364.48,341.37l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C364.83,341.61,364.69,341.41,364.48,341.37z"/>
															</g>
															<g>
																<path class="stci5" d="M364.64,341.44c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C364.85,341.82,364.82,341.57,364.64,341.44z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,334.25c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,334.68,364.72,334.44,364.61,334.25z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,349.28c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,349.71,364.72,349.47,364.61,349.28z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M364.48,356.4l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C364.83,356.64,364.69,356.44,364.48,356.4z"/>
															</g>
															<g>
																<path class="stci5" d="M364.64,356.47c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C364.85,356.85,364.82,356.6,364.64,356.47z"/>
															</g>
															<g>
																<path class="stci5" d="M355.34,370.12l9.61-5.61c-0.15-0.33-0.26-0.73-0.45-0.62l-9.16,5.32V370.12z" />
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M364.48,311.31l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C364.83,311.55,364.69,311.34,364.48,311.31z"/>
															</g>
															<g>
																<path class="stci5" d="M364.64,311.38c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C364.85,311.75,364.82,311.51,364.64,311.38z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,304.19c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,304.62,364.72,304.37,364.61,304.19z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,319.22c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,319.65,364.72,319.41,364.61,319.22z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M364.48,326.34l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C364.83,326.58,364.69,326.38,364.48,326.34z"/>
															</g>
															<g>
																<path class="stci5" d="M364.64,326.41c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C364.85,326.79,364.82,326.54,364.64,326.41z"/>
															</g>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M364.48,281.24l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C364.83,281.49,364.69,281.28,364.48,281.24z"/>
															</g>
															<g>
																<path class="stci5" d="M364.64,281.32c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C364.85,281.69,364.82,281.44,364.64,281.32z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,274.12c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,274.55,364.72,274.31,364.61,274.12z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,289.16c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,289.59,364.72,289.34,364.61,289.16z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M364.48,296.28l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C364.83,296.52,364.69,296.31,364.48,296.28z"/>
															</g>
															<g>
																<path class="stci5" d="M364.64,296.35c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C364.85,296.72,364.82,296.48,364.64,296.35z"/>
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M364.48,251.18l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C364.83,251.42,364.69,251.22,364.48,251.18z"/>
															</g>
															<g>
																<path class="stci5" d="M364.64,251.25c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C364.85,251.63,364.82,251.38,364.64,251.25z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,244.06c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,244.49,364.72,244.25,364.61,244.06z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,259.09c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,259.52,364.72,259.28,364.61,259.09z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M364.48,266.22l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C364.83,266.46,364.69,266.25,364.48,266.22z"/>
															</g>
															<g>
																<path class="stci5" d="M364.64,266.29c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C364.85,266.66,364.82,266.41,364.64,266.29z"/>
															</g>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M344.36,341.37l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C344,341.61,344.15,341.41,344.36,341.37z"/>
															</g>
															<g>
																<path class="stci5" d="M344.2,341.44c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C343.98,341.82,344.02,341.57,344.2,341.44z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,334.25c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,334.68,344.12,334.44,344.23,334.25z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,349.28c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,349.71,344.12,349.47,344.23,349.28z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M344.36,356.4l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C344,356.64,344.15,356.44,344.36,356.4z"/>
															</g>
															<g>
																<path class="stci5" d="M344.2,356.47c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C343.98,356.85,344.02,356.6,344.2,356.47z"/>
															</g>
															<g>
																<path class="stci5" d="M353.5,370.12l-9.61-5.61c0.15-0.33,0.26-0.73,0.45-0.62l9.16,5.32V370.12z" />
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M344.36,311.31l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C344,311.55,344.15,311.34,344.36,311.31z"/>
															</g>
															<g>
																<path class="stci5" d="M344.2,311.38c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C343.98,311.75,344.02,311.51,344.2,311.38z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,304.19c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,304.62,344.12,304.37,344.23,304.19z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,319.22c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,319.65,344.12,319.41,344.23,319.22z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M344.36,326.34l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C344,326.58,344.15,326.38,344.36,326.34z"/>
															</g>
															<g>
																<path class="stci5" d="M344.2,326.41c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C343.98,326.79,344.02,326.54,344.2,326.41z"/>
															</g>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M344.36,281.24l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C344,281.49,344.15,281.28,344.36,281.24z"/>
															</g>
															<g>
																<path class="stci5" d="M344.2,281.32c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C343.98,281.69,344.02,281.44,344.2,281.32z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,274.12c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,274.55,344.12,274.31,344.23,274.12z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,289.16c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,289.59,344.12,289.34,344.23,289.16z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M344.36,296.28l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C344,296.52,344.15,296.31,344.36,296.28z"/>
															</g>
															<g>
																<path class="stci5" d="M344.2,296.35c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C343.98,296.72,344.02,296.48,344.2,296.35z"/>
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M344.36,251.18l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C344,251.42,344.15,251.22,344.36,251.18z"/>
															</g>
															<g>
																<path class="stci5" d="M344.2,251.25c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C343.98,251.63,344.02,251.38,344.2,251.25z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,244.06c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,244.49,344.12,244.25,344.23,244.06z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,259.09c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,259.52,344.12,259.28,344.23,259.09z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M364.48,236.2l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C364.83,236.44,364.69,236.24,364.48,236.2z"/>
															</g>
															<g>
																<path class="stci5" d="M364.64,236.27c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C364.85,236.65,364.82,236.4,364.64,236.27z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,229.08c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,229.51,364.72,229.27,364.61,229.08z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,244.11c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,244.54,364.72,244.3,364.61,244.11z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M344.36,236.2l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C344,236.44,344.15,236.24,344.36,236.2z"/>
															</g>
															<g>
																<path class="stci5" d="M344.2,236.27c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C343.98,236.65,344.02,236.4,344.2,236.27z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,229.08c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,229.51,344.12,229.27,344.23,229.08z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,244.11c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,244.54,344.12,244.3,344.23,244.11z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M364.48,221.17l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C364.83,221.41,364.69,221.21,364.48,221.17z"/>
															</g>
															<g>
																<path class="stci5" d="M364.64,221.24c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C364.85,221.61,364.82,221.37,364.64,221.24z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,214.05c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,214.48,364.72,214.24,364.61,214.05z"/>
															</g>
															<g>
																<path class="stci5" d="M364.61,229.08c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C364.66,229.51,364.72,229.27,364.61,229.08z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M344.36,221.17l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C344,221.41,344.15,221.21,344.36,221.17z"/>
															</g>
															<g>
																<path class="stci5" d="M344.2,221.24c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C343.98,221.61,344.02,221.37,344.2,221.24z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,214.05c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,214.48,344.12,214.24,344.23,214.05z"/>
															</g>
															<g>
																<path class="stci5" d="M344.23,229.08c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C344.18,229.51,344.12,229.27,344.23,229.08z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M344.36,266.22l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C344,266.46,344.15,266.25,344.36,266.22z"/>
															</g>
															<g>
																<path class="stci5" d="M344.2,266.29c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C343.98,266.66,344.02,266.41,344.2,266.29z"/>
															</g>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<polygon class="stci5" points="356.11,370.47 354.62,371.33 354.62,217.15 356.11,216.29 						" />
													<polygon class="stci5" points="354.62,369.63 353.13,370.49 353.13,250.68 354.62,249.82 						" />
													<polygon class="stci6" points="353.13,370.47 354.62,371.33 354.62,217.15 353.13,216.29 						" />
												</g>
											</g>
											<g>
												<polygon class="stci3" points="354.5,201.39 369.34,209.74 354.64,218.02 339.8,209.66 					" />
												<polygon class="stci2" points="354.64,218.02 354.64,218.9 369.34,210.63 369.34,209.74 					" />
												<polygon class="stci4" points="339.8,209.66 339.8,210.55 354.64,218.9 354.64,218.02 					" />
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<polygon class="stci9" points="368.37,231.68 368,232.02 368.9,232.72 369.24,232.26 					" />
												<polygon class="stci9" points="374.11,224.06 375.03,224.76 374.74,224.99 373.87,224.41 					" />
												<g>
													<path class="stci9" d="M374.17,230.43c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C375.89,225.42,375.86,228.24,374.17,230.43z"/>
													<path class="stci5" d="M374.97,231.05c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C376.69,226.04,376.66,228.85,374.97,231.05z"/>
													<g>
														<path class="stci2" d="M379.35,229.12l-4.82-3.71c-0.56-0.43-1.26-0.58-1.99-0.49l6.3,4.85L379.35,229.12z" />
														<path class="stci2" d="M368.58,228.57l7.03,5.41l0.99-1.28l-7.31-5.62C368.96,227.55,368.73,228.06,368.58,228.57z" />
														<path class="stci2" d="M369.43,232.04l4.82,3.71l0.74-0.96l-6.57-5.06C368.41,230.67,368.75,231.51,369.43,232.04z" />
														<path class="stci2" d="M371.32,225.28c-0.49,0.23-0.96,0.57-1.38,1l7.28,5.6l0.99-1.29L371.32,225.28z" />
														<path class="stci2" d="M368.41,229.73l6.57,5.06l0.63-0.81l-7.03-5.41C368.47,228.96,368.41,229.35,368.41,229.73z" />
														<path class="stci4" d="M368.41,229.73l6.57,5.06l0.63-0.81l-7.03-5.41C368.47,228.96,368.41,229.35,368.41,229.73z" />
														<path class="stci2" d="M378.85,229.77l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L378.85,229.77z" />
														<path class="stci4" d="M378.85,229.77l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L378.85,229.77z" />
														<path class="stci2" d="M369.48,226.8c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C369.78,226.44,369.63,226.61,369.48,226.8z"/>
														<path class="stci4" d="M369.48,226.8c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C369.78,226.44,369.63,226.61,369.48,226.8z"/>
													</g>
													<path class="stci3" d="M379.3,234.35c-1.41,1.83-3.67,2.45-5.05,1.39c-1.38-1.06-1.36-3.41,0.05-5.24
							c1.41-1.83,3.67-2.45,5.05-1.39C380.73,230.18,380.71,232.52,379.3,234.35z"/>
													<g>
														<path class="stci2" d="M378.82,229.77c-0.13-0.1-0.27-0.18-0.41-0.24c0.75,0.94,0.63,2.56-0.37,3.85
								c-0.99,1.29-2.52,1.83-3.62,1.35c0.1,0.12,0.2,0.23,0.32,0.33c1.1,0.85,2.91,0.35,4.03-1.11
								C379.9,232.49,379.92,230.62,378.82,229.77z"/>
														<path class="stci10" d="M378.04,233.39c1-1.3,1.12-2.91,0.37-3.85c-1.1-0.48-2.62,0.06-3.62,1.35c-1,1.3-1.12,2.91-0.37,3.85
								C375.53,235.22,377.05,234.68,378.04,233.39z"/>
													</g>
												</g>
											</g>
										</g>
										<g>
											<path class="stci5" d="M371.15,225.92c0,0,1.12-0.66,1.5-0.87c0.79-0.43,0.97,0.48,0.97,0.48l-2.48,1.43
					c-0.69,0.4-1.26,1.48-1.26,2.4v4.1c0,0.92,0.56,1.35,1.26,0.95l0.74-0.43l0.77,0.6l-1.52,0.88c-1.12,0.65-2.04-0.04-2.04-1.54
					v-4.1C369.11,228.32,370.02,226.57,371.15,225.92z"/>
										</g>
										<g>
											<polygon class="stci4" points="366.45,232.04 369.62,230.3 369.23,229.48 366.45,231 				" />
											<path class="stci4" d="M369.72,230.06c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
					C369.59,229.59,369.72,229.84,369.72,230.06z"/>
										</g>
										<g>
											<polygon class="stci4" points="366.45,236.42 369.62,234.68 369.23,233.86 366.45,235.38 				" />
											<path class="stci4" d="M369.72,234.44c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
					C369.59,233.97,369.72,234.22,369.72,234.44z"/>
										</g>
									</g>
									<g>
										<g>
											<g>
												<polygon class="stci9" points="368.37,245.1 368,245.45 368.9,246.14 369.24,245.68 					" />
												<polygon class="stci9" points="374.11,237.48 375.03,238.18 374.74,238.41 373.87,237.83 					" />
												<g>
													<path class="stci9" d="M374.17,243.86c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C375.89,238.84,375.86,241.66,374.17,243.86z"/>
													<path class="stci5" d="M374.97,244.47c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C376.69,239.46,376.66,242.27,374.97,244.47z"/>
													<g>
														<path class="stci2" d="M379.35,242.54l-4.82-3.71c-0.56-0.43-1.26-0.58-1.99-0.49l6.3,4.85L379.35,242.54z" />
														<path class="stci2" d="M368.58,241.99l7.03,5.41l0.99-1.28l-7.31-5.62C368.96,240.97,368.73,241.48,368.58,241.99z" />
														<path class="stci2" d="M369.43,245.46l4.82,3.71l0.74-0.96l-6.57-5.06C368.41,244.09,368.75,244.93,369.43,245.46z" />
														<path class="stci2" d="M371.32,238.7c-0.49,0.23-0.96,0.57-1.38,1l7.28,5.6l0.99-1.29L371.32,238.7z" />
														<path class="stci2" d="M368.41,243.15l6.57,5.06l0.63-0.81l-7.03-5.41C368.47,242.38,368.41,242.78,368.41,243.15z" />
														<path class="stci4" d="M368.41,243.15l6.57,5.06l0.63-0.81l-7.03-5.41C368.47,242.38,368.41,242.78,368.41,243.15z" />
														<path class="stci2" d="M378.85,243.19l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L378.85,243.19z" />
														<path class="stci4" d="M378.85,243.19l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L378.85,243.19z" />
														<path class="stci2" d="M369.48,240.22c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C369.78,239.86,369.63,240.03,369.48,240.22z"/>
														<path class="stci4" d="M369.48,240.22c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C369.78,239.86,369.63,240.03,369.48,240.22z"/>
													</g>
													<path class="stci3" d="M379.3,247.78c-1.41,1.83-3.67,2.45-5.05,1.39c-1.38-1.06-1.36-3.41,0.05-5.24
							c1.41-1.83,3.67-2.45,5.05-1.39C380.73,243.6,380.71,245.95,379.3,247.78z"/>
													<g>
														<path class="stci2" d="M378.82,243.2c-0.13-0.1-0.27-0.18-0.41-0.24c0.75,0.94,0.63,2.56-0.37,3.85
								c-0.99,1.29-2.52,1.83-3.62,1.35c0.1,0.12,0.2,0.23,0.32,0.33c1.1,0.85,2.91,0.35,4.03-1.11
								C379.9,245.92,379.92,244.04,378.82,243.2z"/>
														<path class="stci10" d="M378.04,246.81c1-1.3,1.12-2.91,0.37-3.85c-1.1-0.48-2.62,0.06-3.62,1.35c-1,1.3-1.12,2.91-0.37,3.85
								C375.53,248.64,377.05,248.1,378.04,246.81z"/>
													</g>
												</g>
											</g>
										</g>
										<g>
											<path class="stci5" d="M371.15,239.35c0,0,1.12-0.66,1.5-0.87c0.79-0.43,0.97,0.48,0.97,0.48l-2.48,1.43
					c-0.69,0.4-1.26,1.48-1.26,2.4v4.1c0,0.92,0.56,1.35,1.26,0.95l0.74-0.43l0.77,0.6l-1.52,0.88c-1.12,0.65-2.04-0.04-2.04-1.54
					v-4.1C369.11,241.74,370.02,240,371.15,239.35z"/>
										</g>
										<g>
											<polygon class="stci4" points="366.45,245.47 369.62,243.72 369.23,242.9 366.45,244.42 				" />
											<path class="stci4" d="M369.72,243.49c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
					C369.59,243.01,369.72,243.27,369.72,243.49z"/>
										</g>
										<g>
											<polygon class="stci4" points="366.45,249.84 369.62,248.1 369.23,247.28 366.45,248.8 				" />
											<path class="stci4" d="M369.72,247.86c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
					C369.59,247.39,369.72,247.64,369.72,247.86z"/>
										</g>
									</g>
									<g>
										<g>
											<g>
												<polygon class="stci9" points="368.37,258.38 368,258.73 368.9,259.43 369.24,258.97 					" />
												<polygon class="stci9" points="374.11,250.76 375.03,251.47 374.74,251.7 373.87,251.11 					" />
												<g>
													<path class="stci9" d="M374.17,257.14c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C375.89,252.12,375.86,254.94,374.17,257.14z"/>
													<path class="stci5" d="M374.97,257.76c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C376.69,252.74,376.66,255.56,374.97,257.76z"/>
													<g>
														<path class="stci2" d="M379.35,255.82l-4.82-3.71c-0.56-0.43-1.26-0.58-1.99-0.49l6.3,4.85L379.35,255.82z" />
														<path class="stci2" d="M368.58,255.27l7.03,5.41l0.99-1.28l-7.31-5.62C368.96,254.26,368.73,254.76,368.58,255.27z" />
														<path class="stci2" d="M369.43,258.74l4.82,3.71l0.74-0.96l-6.57-5.06C368.41,257.37,368.75,258.22,369.43,258.74z" />
														<path class="stci2" d="M371.32,251.99c-0.49,0.23-0.96,0.57-1.38,1l7.28,5.6l0.99-1.29L371.32,251.99z" />
														<path class="stci2" d="M368.41,256.44l6.57,5.06l0.63-0.81l-7.03-5.41C368.47,255.67,368.41,256.06,368.41,256.44z" />
														<path class="stci4" d="M368.41,256.44l6.57,5.06l0.63-0.81l-7.03-5.41C368.47,255.67,368.41,256.06,368.41,256.44z" />
														<path class="stci2" d="M378.85,256.48l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L378.85,256.48z" />
														<path class="stci4" d="M378.85,256.48l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L378.85,256.48z" />
														<path class="stci2" d="M369.48,253.51c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C369.78,253.14,369.63,253.32,369.48,253.51z"/>
														<path class="stci4" d="M369.48,253.51c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C369.78,253.14,369.63,253.32,369.48,253.51z"/>
													</g>
													<path class="stci3" d="M379.3,261.06c-1.41,1.83-3.67,2.45-5.05,1.39c-1.38-1.06-1.36-3.41,0.05-5.24
							c1.41-1.83,3.67-2.45,5.05-1.39C380.73,256.88,380.71,259.23,379.3,261.06z"/>
													<g>
														<path class="stci2" d="M378.82,256.48c-0.13-0.1-0.27-0.18-0.41-0.24c0.75,0.94,0.63,2.56-0.37,3.85
								c-0.99,1.29-2.52,1.83-3.62,1.35c0.1,0.12,0.2,0.23,0.32,0.33c1.1,0.85,2.91,0.35,4.03-1.11
								C379.9,259.2,379.92,257.33,378.82,256.48z"/>
														<path class="stci10" d="M378.04,260.09c1-1.3,1.12-2.91,0.37-3.85c-1.1-0.48-2.62,0.06-3.62,1.35c-1,1.3-1.12,2.91-0.37,3.85
								C375.53,261.92,377.05,261.38,378.04,260.09z"/>
													</g>
												</g>
											</g>
										</g>
										<g>
											<path class="stci5" d="M371.15,252.63c0,0,1.12-0.66,1.5-0.87c0.79-0.43,0.97,0.48,0.97,0.48l-2.48,1.43
					c-0.69,0.4-1.26,1.48-1.26,2.4v4.1c0,0.92,0.56,1.35,1.26,0.95l0.74-0.43l0.77,0.6l-1.52,0.88c-1.12,0.65-2.04-0.04-2.04-1.54
					v-4.1C369.11,255.03,370.02,253.28,371.15,252.63z"/>
										</g>
										<g>
											<polygon class="stci4" points="366.45,258.75 369.62,257.01 369.23,256.19 366.45,257.7 				" />
											<path class="stci4" d="M369.72,256.77c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
					C369.59,256.3,369.72,256.55,369.72,256.77z"/>
										</g>
										<g>
											<polygon class="stci4" points="366.45,263.13 369.62,261.39 369.23,260.57 366.45,262.08 				" />
											<path class="stci4" d="M369.72,261.15c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
					C369.59,260.67,369.72,260.93,369.72,261.15z"/>
										</g>
									</g>
									<g>
										<g>
											<g>
												<polygon class="stci9" points="368.37,271.8 368,272.15 368.9,272.85 369.24,272.39 					" />
												<polygon class="stci9" points="374.11,264.18 375.03,264.89 374.74,265.12 373.87,264.54 					" />
												<g>
													<path class="stci9" d="M374.17,270.56c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C375.89,265.55,375.86,268.36,374.17,270.56z"/>
													<path class="stci5" d="M374.97,271.18c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C376.69,266.16,376.66,268.98,374.97,271.18z"/>
													<g>
														<path class="stci2" d="M379.35,269.24l-4.82-3.71c-0.56-0.43-1.26-0.58-1.99-0.49l6.3,4.85L379.35,269.24z" />
														<path class="stci2" d="M368.58,268.69l7.03,5.41l0.99-1.28l-7.31-5.62C368.96,267.68,368.73,268.19,368.58,268.69z" />
														<path class="stci2" d="M369.43,272.17l4.82,3.71l0.74-0.96l-6.57-5.06C368.41,270.8,368.75,271.64,369.43,272.17z" />
														<path class="stci2" d="M371.32,265.41c-0.49,0.23-0.96,0.57-1.38,1l7.28,5.6l0.99-1.29L371.32,265.41z" />
														<path class="stci2" d="M368.41,269.86l6.57,5.06l0.63-0.81l-7.03-5.41C368.47,269.09,368.41,269.48,368.41,269.86z" />
														<path class="stci4" d="M368.41,269.86l6.57,5.06l0.63-0.81l-7.03-5.41C368.47,269.09,368.41,269.48,368.41,269.86z" />
														<path class="stci2" d="M378.85,269.9l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L378.85,269.9z" />
														<path class="stci4" d="M378.85,269.9l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L378.85,269.9z" />
														<path class="stci2" d="M369.48,266.93c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C369.78,266.57,369.63,266.74,369.48,266.93z"/>
														<path class="stci4" d="M369.48,266.93c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C369.78,266.57,369.63,266.74,369.48,266.93z"/>
													</g>
													<path class="stci3" d="M379.3,274.48c-1.41,1.83-3.67,2.45-5.05,1.39c-1.38-1.06-1.36-3.41,0.05-5.24
							c1.41-1.83,3.67-2.45,5.05-1.39C380.73,270.31,380.71,272.65,379.3,274.48z"/>
													<g>
														<path class="stci2" d="M378.82,269.9c-0.13-0.1-0.27-0.18-0.41-0.24c0.75,0.94,0.63,2.56-0.37,3.85
								c-0.99,1.29-2.52,1.83-3.62,1.35c0.1,0.12,0.2,0.23,0.32,0.33c1.1,0.85,2.91,0.35,4.03-1.11
								C379.9,272.62,379.92,270.75,378.82,269.9z"/>
														<path class="stci10" d="M378.04,273.52c1-1.3,1.12-2.91,0.37-3.85c-1.1-0.48-2.62,0.06-3.62,1.35c-1,1.3-1.12,2.91-0.37,3.85
								C375.53,275.34,377.05,274.81,378.04,273.52z"/>
													</g>
												</g>
											</g>
										</g>
										<g>
											<path class="stci5" d="M371.15,266.05c0,0,1.12-0.66,1.5-0.87c0.79-0.43,0.97,0.48,0.97,0.48l-2.48,1.43
					c-0.69,0.4-1.26,1.48-1.26,2.4v4.1c0,0.92,0.56,1.35,1.26,0.95l0.74-0.43l0.77,0.6l-1.52,0.88c-1.12,0.65-2.04-0.04-2.04-1.54
					v-4.1C369.11,268.45,370.02,266.7,371.15,266.05z"/>
										</g>
										<g>
											<polygon class="stci4" points="366.45,272.17 369.62,270.43 369.23,269.61 366.45,271.13 				" />
											<path class="stci4" d="M369.72,270.19c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57s0.13-0.32,0.3-0.22
					C369.59,269.72,369.72,269.97,369.72,270.19z"/>
										</g>
										<g>
											<polygon class="stci4" points="366.45,276.55 369.62,274.81 369.23,273.99 366.45,275.5 				" />
											<path class="stci4" d="M369.72,274.57c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
					C369.59,274.1,369.72,274.35,369.72,274.57z"/>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<polygon class="stci5" points="434.37,352.06 449.15,343.54 449.15,385.55 434.37,394.08 				" />
											<polygon class="stci4" points="448.68,344.44 434.84,352.42 434.92,393.23 448.68,385.3 				" />
											<polygon class="stci5" points="434.37,372.15 449.15,363.62 449.15,364.23 434.37,372.76 				" />
											<polygon class="stci2" points="434.37,352.06 423.31,345.84 423.31,387.85 434.37,394.08 				" />
											<polygon class="stci11" points="426.89,348.75 424.02,347.14 424.02,353.17 426.89,354.78 				" />
											<polygon class="stci11" points="438.09,337.31 449.15,343.54 434.37,352.06 423.31,345.84 				" />
										</g>
										<g>
											<path class="stci5" d="M447.27,354.69c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
					C444.76,348.65,447.27,350.56,447.27,354.69z"/>
											<path class="stci6" d="M446.91,354.83c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
					C444.57,349.22,446.91,350.99,446.91,354.83z"/>
											<path class="stci2" d="M444.49,355.73c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
					C442.99,352.11,444.49,353.25,444.49,355.73z"/>
											<path class="stci2" d="M444.22,355.76c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
					C442.82,352.41,444.22,353.46,444.22,355.76z"/>
											<path class="stci6" d="M442.73,356.62c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
					C442,354.87,442.73,355.42,442.73,356.62z"/>
										</g>
										<g>
											<path class="stci5" d="M447.27,375.54c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
					C444.76,369.5,447.27,371.4,447.27,375.54z"/>
											<path class="stci6" d="M446.91,375.68c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
					C444.57,370.07,446.91,371.83,446.91,375.68z"/>
											<path class="stci2" d="M444.49,376.57c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
					C442.99,372.96,444.49,374.1,444.49,376.57z"/>
											<path class="stci2" d="M444.22,376.61c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
					C442.82,373.25,444.22,374.31,444.22,376.61z"/>
											<path class="stci6" d="M442.73,377.47c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
					C442,375.72,442.73,376.27,442.73,377.47z"/>
										</g>
									</g>
									<g>
										<g>
											<polygon class="stci5" points="418.44,361.71 433.22,353.18 433.22,395.2 418.44,403.72 				" />
											<polygon class="stci4" points="432.75,354.09 418.9,362.07 418.99,402.88 432.75,394.94 				" />
											<polygon class="stci5" points="418.44,381.79 433.22,373.27 433.22,373.88 418.44,382.41 				" />
											<polygon class="stci2" points="418.44,361.71 407.37,355.48 407.37,397.5 418.44,403.72 				" />
											<polygon class="stci11" points="410.95,358.4 408.08,356.78 408.08,362.81 410.95,364.43 				" />
											<polygon class="stci11" points="422.15,346.96 433.22,353.18 418.44,361.71 407.37,355.48 				" />
										</g>
										<g>
											<path class="stci5" d="M431.33,364.34c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
					C428.82,358.3,431.33,360.2,431.33,364.34z"/>
											<path class="stci6" d="M430.97,364.48c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
					C428.63,358.87,430.97,360.63,430.97,364.48z"/>
											<path class="stci2" d="M428.56,365.37c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
					C427.05,361.76,428.56,362.9,428.56,365.37z"/>
											<path class="stci2" d="M428.28,365.41c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
					C426.89,362.05,428.28,363.11,428.28,365.41z"/>
											<path class="stci6" d="M426.79,366.27c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
					C426.06,364.52,426.79,365.07,426.79,366.27z"/>
										</g>
										<g>
											<path class="stci5" d="M431.33,385.18c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
					C428.82,379.15,431.33,381.05,431.33,385.18z"/>
											<path class="stci6" d="M430.97,385.32c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
					C428.63,379.71,430.97,381.48,430.97,385.32z"/>
											<path class="stci2" d="M428.56,386.22c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
					C427.05,382.6,428.56,383.74,428.56,386.22z"/>
											<path class="stci2" d="M428.28,386.26c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
					C426.89,382.9,428.28,383.96,428.28,386.26z"/>
											<path class="stci6" d="M426.79,387.12c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
					C426.06,385.36,426.79,385.92,426.79,387.12z"/>
										</g>
									</g>
									<g>
										<g>
											<polygon class="stci5" points="256.09,414.69 254.2,415.79 254.2,384.46 256.09,383.37 				" />
											<polygon class="stci6" points="252.31,414.69 254.2,415.79 254.2,384.46 252.31,383.37 				" />
										</g>
										<g>
											<polygon class="stci12" points="227.7,418.64 252.31,404.4 254.2,405.49 227.7,420.79 				" />
											<polygon class="stci5" points="227.7,420.79 254.2,405.49 254.2,407.68 227.7,422.98 				" />
										</g>
										<g>
											<polygon class="stci12" points="410.4,395.17 385.79,380.93 383.9,382.02 410.4,397.32 				" />
											<polygon class="stci5" points="410.4,397.32 383.9,382.02 383.9,384.21 410.4,399.51 				" />
										</g>
										<g>
											<polygon class="stci12" points="227.7,398.22 252.31,383.99 254.2,385.08 227.7,400.38 				" />
											<polygon class="stci5" points="227.7,400.38 254.2,385.08 254.2,387.26 227.7,402.56 				" />
										</g>
										<g>
											<polygon class="stci5" points="412.29,406.76 410.4,407.86 410.4,376.53 412.29,375.44 				" />
											<polygon class="stci6" points="408.51,406.76 410.4,407.86 410.4,376.53 408.51,375.44 				" />
										</g>
										<g>
											<g>
												<polygon class="stci6" points="225.81,430.5 227.7,431.59 227.7,400.26 225.81,399.17 					" />
												<polygon class="stci5" points="229.59,430.5 227.7,431.59 227.7,400.26 229.59,399.17 					" />
											</g>
											<g>
												<polygon class="stci12" points="260.75,440.33 227.4,421.03 229.29,419.94 260.75,438.17 					" />
												<polygon class="stci6" points="260.75,440.33 260.75,442.51 227.4,423.21 227.4,421.03 					" />
											</g>
											<g>
												<polygon class="stci12" points="260.75,419.84 227.4,400.54 229.29,399.45 260.75,417.68 					" />
												<polygon class="stci6" points="260.75,419.84 260.75,422.03 227.4,402.72 227.4,400.54 					" />
											</g>
										</g>
										<g>
											<g>
												<polygon class="stci12" points="380.12,412.99 408.51,396.56 410.4,397.65 380.12,415.14 					" />
												<polygon class="stci5" points="380.12,415.14 410.4,397.65 410.4,399.84 380.12,417.32 					" />
											</g>
											<g>
												<polygon class="stci12" points="380.12,392.53 408.51,376.11 410.4,377.2 380.12,394.69 					" />
												<polygon class="stci5" points="380.12,394.69 410.4,377.2 410.4,379.39 380.12,396.87 					" />
											</g>
										</g>
										<g>
											<polygon class="stci5" points="381.37,424.64 379.48,425.73 379.48,394.4 381.37,393.31 				" />
											<polygon class="stci6" points="377.59,424.64 379.48,425.73 379.48,394.4 377.59,393.31 				" />
										</g>
										<g>
											<polygon class="stci12" points="352.91,428.62 377.59,414.34 379.48,415.43 352.91,430.78 				" />
											<polygon class="stci5" points="352.91,430.78 379.48,415.43 379.48,417.62 352.91,432.96 				" />
										</g>
										<g>
											<polygon class="stci12" points="352.91,408.21 377.59,393.93 379.48,395.02 352.91,410.36 				" />
											<polygon class="stci5" points="352.91,410.36 379.48,395.02 379.48,397.2 352.91,412.55 				" />
										</g>
										<g>
											<polygon class="stci5" points="354.5,440.17 352.61,441.26 352.61,409.94 354.5,408.85 				" />
											<polygon class="stci6" points="350.72,440.17 352.61,441.26 352.61,409.94 350.72,408.85 				" />
										</g>
										<g>
											<polygon class="stci12" points="324.88,444.82 350.72,429.88 352.61,430.97 324.88,446.98 				" />
											<polygon class="stci5" points="324.88,446.98 352.61,430.97 352.61,433.15 324.88,449.16 				" />
										</g>
										<g>
											<polygon class="stci12" points="324.88,424.41 350.72,409.46 352.61,410.56 324.88,426.56 				" />
											<polygon class="stci5" points="324.88,426.56 352.61,410.56 352.61,412.74 324.88,428.75 				" />
										</g>
										<g>
											<polygon class="stci5" points="326.67,456.38 324.78,457.47 324.78,426.15 326.67,425.06 				" />
											<polygon class="stci6" points="322.89,456.38 324.78,457.47 324.78,426.15 322.89,425.06 				" />
										</g>
										<g>
											<polygon class="stci12" points="298.27,460.32 322.89,446.09 324.78,447.18 298.27,462.48 				" />
											<polygon class="stci5" points="298.27,462.48 324.78,447.18 324.78,449.36 298.27,464.66 				" />
										</g>
										<g>
											<polygon class="stci12" points="298.27,439.91 322.89,425.67 324.78,426.76 298.27,442.06 				" />
											<polygon class="stci5" points="298.27,442.06 324.78,426.76 324.78,428.95 298.27,444.25 				" />
										</g>
										<g>
											<g>
												<polygon class="stci6" points="259.22,449.73 261.11,450.82 261.11,419.49 259.22,418.4 					" />
												<polygon class="stci5" points="263,449.73 261.11,450.82 261.11,419.49 263,418.4 					" />
											</g>
											<g>
												<polygon class="stci12" points="298.27,459.79 262.7,439.17 260.81,440.26 298.27,461.94 					" />
												<polygon class="stci6" points="298.27,461.94 260.81,440.26 260.81,442.45 298.27,464.13 					" />
											</g>
											<g>
												<polygon class="stci12" points="298.27,439.3 262.7,418.68 260.81,419.78 298.27,441.45 					" />
												<polygon class="stci6" points="298.27,441.45 260.81,419.78 260.81,421.96 298.27,443.64 					" />
											</g>
										</g>
										<g>
											<polygon class="stci5" points="300.48,471.35 298.59,472.44 298.59,439.05 300.48,437.96 				" />
											<polygon class="stci6" points="296.7,471.35 298.59,472.44 298.59,439.05 296.7,437.96 				" />
										</g>
										<g>
											<polygon class="stci4" points="298.59,441.85 412.29,376.21 339.51,334.19 225.81,399.83 				" />
											<polygon class="stci3" points="298.59,431.34 412.29,365.7 339.51,323.67 225.81,389.32 				" />
											<polygon class="stci2" points="225.81,389.28 225.81,399.83 298.59,441.85 298.59,431.31 				" />
											<polygon class="stci4" points="412.29,365.66 412.29,376.21 298.59,441.85 298.59,431.31 				" />
										</g>
									</g>
									<g>
										<g>
											<polygon class="stci5" points="337.97,301.97 352.75,293.44 352.75,335.45 337.97,343.98 				" />
											<polygon class="stci4" points="352.28,294.34 338.44,302.33 338.52,343.14 352.28,335.2 				" />
											<polygon class="stci5" points="337.97,322.05 352.75,313.52 352.75,314.14 337.97,322.66 				" />
											<polygon class="stci2" points="337.97,301.97 326.91,295.74 326.91,337.76 337.97,343.98 				" />
											<polygon class="stci2" points="330.49,298.65 327.62,297.04 327.62,303.07 330.49,304.69 				" />
											<polygon class="stci11" points="341.69,287.22 352.75,293.44 337.97,301.97 326.91,295.74 				" />
										</g>
										<g>
											<path class="stci5" d="M350.87,304.6c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
					C348.36,298.56,350.87,300.46,350.87,304.6z"/>
											<path class="stci6" d="M350.5,304.73c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
					C348.17,299.13,350.5,300.89,350.5,304.73z"/>
											<path class="stci2" d="M348.09,305.63c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
					C346.59,302.01,348.09,303.15,348.09,305.63z"/>
											<path class="stci2" d="M347.82,305.67c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
					C346.42,302.31,347.82,303.37,347.82,305.67z"/>
											<path class="stci6" d="M346.33,306.53c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
					C345.6,304.77,346.33,305.33,346.33,306.53z"/>
										</g>
										<g>
											<path class="stci5" d="M350.87,325.44c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
					C348.36,319.4,350.87,321.31,350.87,325.44z"/>
											<path class="stci6" d="M350.5,325.58c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
					C348.17,319.97,350.5,321.74,350.5,325.58z"/>
											<path class="stci2" d="M348.09,326.48c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
					C346.59,322.86,348.09,324,348.09,326.48z"/>
											<path class="stci2" d="M347.82,326.51c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
					C346.42,323.16,347.82,324.21,347.82,326.51z"/>
											<path class="stci6" d="M346.33,327.38c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
					C345.6,325.62,346.33,326.17,346.33,327.38z"/>
										</g>
									</g>
									<g>
										<g>
											<polygon class="stci5" points="244.33,354.76 259.11,346.23 259.11,388.25 244.33,396.77 				" />
											<polygon class="stci4" points="258.64,347.14 244.8,355.12 244.88,395.93 258.64,387.99 				" />
											<polygon class="stci5" points="244.33,374.84 259.11,366.32 259.11,366.93 244.33,375.46 				" />
											<polygon class="stci2" points="244.33,354.76 233.27,348.53 233.27,390.55 244.33,396.77 				" />
											<polygon class="stci2" points="236.85,351.45 233.98,349.83 233.98,355.86 236.85,357.48 				" />
											<polygon class="stci11" points="248.05,340.01 259.11,346.23 244.33,354.76 233.27,348.53 				" />
										</g>
										<g>
											<path class="stci5" d="M257.23,357.39c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
					C254.72,351.35,257.23,353.25,257.23,357.39z"/>
											<path class="stci6" d="M256.87,357.53c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
					C254.53,351.92,256.87,353.68,256.87,357.53z"/>
											<path class="stci2" d="M254.45,358.42c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
					C252.95,354.81,254.45,355.95,254.45,358.42z"/>
											<path class="stci2" d="M254.18,358.46c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
					C252.78,355.1,254.18,356.16,254.18,358.46z"/>
											<path class="stci6" d="M252.69,359.32c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
					C251.96,357.57,252.69,358.12,252.69,359.32z"/>
										</g>
										<g>
											<path class="stci5" d="M257.23,378.24c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
					C254.72,372.2,257.23,374.1,257.23,378.24z"/>
											<path class="stci6" d="M256.87,378.37c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
					C254.53,372.76,256.87,374.53,256.87,378.37z"/>
											<path class="stci2" d="M254.45,379.27c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
					C252.95,375.65,254.45,376.79,254.45,379.27z"/>
											<path class="stci2" d="M254.18,379.31c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
					C252.78,375.95,254.18,377.01,254.18,379.31z"/>
											<path class="stci6" d="M252.69,380.17c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
					C251.96,378.41,252.69,378.97,252.69,380.17z"/>
										</g>
									</g>
									<g>
										<polygon class="stci11" points="368.52,370.11 377.16,375.1 387.14,369.34 378.49,364.35 			" />
										<polygon class="stci4" points="387.14,369.34 387.14,377.49 377.16,383.25 377.16,375.1 			" />
										<polygon class="stci5" points="386.56,370.34 386.56,377.04 377.83,382.11 377.83,375.4 			" />
										<polygon class="stci6" points="387.08,370.8 387.08,377.5 378.35,382.57 378.35,375.86 			" />
										<polygon class="stci2" points="368.52,370.11 368.52,378.26 377.16,383.25 377.16,375.1 			" />
										<polygon class="stci12" points="386.56,370.34 387.08,370.8 378.35,375.86 377.83,375.4 			" />
										<polygon class="stci5" points="377.83,382.11 378.35,382.57 378.35,375.86 377.83,375.4 			" />
										<polygon class="stci5" points="385.98,377.7 385.52,377.96 385.52,372.16 385.98,371.89 			" />
										<polygon class="stci5" points="384.76,378.42 384.31,378.69 384.31,372.89 384.76,372.62 			" />
										<polygon class="stci5" points="383.55,379.15 383.09,379.41 383.09,373.61 383.55,373.35 			" />
										<polygon class="stci5" points="382.33,379.88 381.88,380.14 381.88,374.34 382.33,374.08 			" />
										<polygon class="stci5" points="381.12,380.48 380.66,380.74 380.66,374.94 381.12,374.68 			" />
										<polygon class="stci5" points="379.9,381.21 379.44,381.47 379.44,375.67 379.9,375.4 			" />
									</g>
									<g>
										<polygon class="stci11" points="307.07,405.77 315.71,410.76 325.69,405 317.05,400.01 			" />
										<polygon class="stci4" points="325.69,405 325.69,413.15 315.71,418.92 315.71,410.76 			" />
										<polygon class="stci5" points="325.11,406 325.11,412.71 316.38,417.77 316.38,411.07 			" />
										<polygon class="stci6" points="325.63,406.46 325.63,413.16 316.9,418.23 316.9,411.52 			" />
										<polygon class="stci2" points="307.07,405.77 307.07,413.93 315.71,418.92 315.71,410.76 			" />
										<polygon class="stci12" points="325.11,406 325.63,406.46 316.9,411.52 316.38,411.07 			" />
										<polygon class="stci5" points="316.38,417.77 316.9,418.23 316.9,411.52 316.38,411.07 			" />
										<polygon class="stci5" points="324.53,413.36 324.08,413.62 324.08,407.82 324.53,407.55 			" />
										<polygon class="stci5" points="323.32,414.09 322.86,414.35 322.86,408.55 323.32,408.28 			" />
										<polygon class="stci5" points="322.1,414.81 321.65,415.08 321.65,409.27 322.1,409.01 			" />
										<polygon class="stci5" points="320.89,415.54 320.43,415.81 320.43,410 320.89,409.74 			" />
										<polygon class="stci5" points="319.67,416.14 319.21,416.41 319.21,410.6 319.67,410.34 			" />
										<polygon class="stci5" points="318.46,416.87 318,417.14 318,411.33 318.46,411.07 			" />
									</g>
								</g>
								<g>
									<g>
										<g>
											<polygon class="stci6" points="334.67,452.35 336.56,453.45 336.56,422.12 334.67,421.03 				" />
											<polygon class="stci5" points="338.45,452.35 336.56,453.45 336.56,422.12 338.45,421.03 				" />
										</g>
										<g>
											<polygon class="stci12" points="369.97,461.63 336.62,442.32 338.51,441.23 369.97,459.47 				" />
											<polygon class="stci6" points="369.97,461.63 369.97,463.81 336.62,444.51 336.62,442.32 				" />
										</g>
										<g>
											<polygon class="stci12" points="369.61,441.7 336.26,422.4 338.15,421.31 369.61,439.54 				" />
											<polygon class="stci6" points="369.61,440.94 369.61,443.12 336.26,423.82 336.26,421.64 				" />
										</g>
									</g>
									<g>
										<g>
											<polygon class="stci6" points="368.08,471.59 369.97,472.68 369.97,441.35 368.08,440.26 				" />
											<polygon class="stci5" points="371.86,471.59 369.97,472.68 369.97,441.35 371.86,440.26 				" />
										</g>
										<g>
											<polygon class="stci12" points="407.43,481.24 371.86,460.63 369.97,461.72 407.43,483.4 				" />
											<polygon class="stci6" points="407.43,483.4 369.97,461.72 369.97,463.9 407.43,485.58 				" />
										</g>
										<g>
											<polygon class="stci12" points="407.13,461.16 371.56,440.54 369.67,441.63 407.13,463.31 				" />
											<polygon class="stci6" points="407.13,462.71 369.67,441.03 369.67,443.22 407.13,464.9 				" />
										</g>
									</g>
									<g>
										<g>
											<polygon class="stci6" points="447.58,470.74 445.69,471.83 445.69,440.51 447.58,439.41 				" />
											<polygon class="stci5" points="443.8,470.74 445.69,471.83 445.69,440.51 443.8,439.41 				" />
										</g>
										<g>
											<polygon class="stci12" points="409.02,482.22 445.99,461.27 444.1,460.18 409.02,480.06 				" />
											<polygon class="stci6" points="409.02,482.22 409.02,484.4 445.99,463.46 445.99,461.27 				" />
										</g>
										<g>
											<polygon class="stci12" points="407.13,462.96 445.99,440.79 444.1,439.7 407.13,460.8 				" />
											<polygon class="stci6" points="407.13,462.96 407.13,465.15 445.99,442.97 445.99,440.79 				" />
										</g>
									</g>
									<g>
										<g>
											<polygon class="stci6" points="409.02,493.27 407.13,494.36 407.13,463.03 409.02,461.94 				" />
											<polygon class="stci5" points="405.24,493.27 407.13,494.36 407.13,463.03 405.24,461.94 				" />
										</g>
									</g>
									<g>
										<polygon class="stci3" points="407.29,452.52 447.5,429.06 374.72,387.04 334.51,410.5 			" />
										<polygon class="stci2" points="334.51,410.5 334.51,421.05 407.29,463.07 407.29,452.52 			" />
										<polygon class="stci4" points="447.5,429.06 447.5,439.61 407.29,463.07 407.29,452.52 			" />
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<path class="stci9" d="M250.43,325.12c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C252.46,319.21,252.43,322.53,250.43,325.12z"/>
												<path class="stci5" d="M251.38,325.85c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C253.41,319.94,253.37,323.26,251.38,325.85z"/>
												<g>
													<path class="stci2" d="M256.54,323.57l-5.68-4.37c-0.66-0.51-1.49-0.68-2.35-0.58l7.43,5.72L256.54,323.57z" />
													<path class="stci2" d="M243.85,322.92l8.28,6.37l1.16-1.51l-8.61-6.63C244.3,321.72,244.02,322.32,243.85,322.92z" />
													<path class="stci2" d="M244.85,327.01l5.68,4.37l0.87-1.13l-7.75-5.96C243.65,325.4,244.04,326.39,244.85,327.01z" />
													<path class="stci2" d="M247.08,319.05c-0.57,0.27-1.13,0.67-1.63,1.17l8.58,6.6l1.17-1.52L247.08,319.05z" />
													<path class="stci2" d="M243.65,324.29l7.75,5.96l0.74-0.96l-8.28-6.37C243.72,323.38,243.65,323.85,243.65,324.29z" />
													<path class="stci4" d="M243.65,324.29l7.75,5.96l0.74-0.96l-8.28-6.37C243.72,323.38,243.65,323.85,243.65,324.29z" />
													<path class="stci2" d="M255.95,324.34l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L255.95,324.34z" />
													<path class="stci4" d="M255.95,324.34l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L255.95,324.34z" />
													<path class="stci2" d="M244.91,320.84c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C245.27,320.41,245.08,320.61,244.91,320.84z"/>
													<path class="stci4" d="M244.91,320.84c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C245.27,320.41,245.08,320.61,244.91,320.84z"/>
												</g>
												<path class="stci3" d="M256.48,329.74c-1.66,2.16-4.32,2.89-5.95,1.64c-1.63-1.25-1.6-4.02,0.06-6.17
						c1.66-2.16,4.32-2.89,5.95-1.64C258.17,324.82,258.14,327.58,256.48,329.74z"/>
												<g>
													<path class="stci2" d="M255.92,324.34c-0.15-0.12-0.31-0.21-0.49-0.28c0.89,1.11,0.75,3.01-0.43,4.54
							c-1.17,1.52-2.97,2.15-4.26,1.59c0.11,0.14,0.24,0.27,0.38,0.38c1.3,1,3.42,0.41,4.75-1.31
							C257.19,327.55,257.22,325.34,255.92,324.34z"/>
													<path class="stci10" d="M255,328.6c1.18-1.53,1.32-3.43,0.43-4.54c-1.3-0.56-3.09,0.07-4.26,1.59
							c-1.18,1.53-1.32,3.43-0.43,4.54C252.04,330.75,253.83,330.12,255,328.6z"/>
												</g>
											</g>
											<path class="stci9" d="M253.51,316.26c-0.8-0.47-1.92-0.19-4,0.99c-2.59,1.48-3.58,2.24-3.58,4.35v4.79c0,0.33,0.14,0.51,0.26,0.6
					c0.14,0.11,0.31,0.14,0.44,0.14c0.11,0,0.2-0.02,0.24-0.03l0.24-0.07v-5.43c0-1.4,0.42-1.87,2.98-3.34
					c1.99-1.14,2.62-1.11,2.83-1c0.09,0.05,0.29,0.26,0.29,1.13v2.61l1.17,0.9v-3.5C254.38,317.31,254.1,316.61,253.51,316.26z"/>
											<g>
												<path class="stci4" d="M247.7,312.93v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H247.7z" />
												<path class="stci2" d="M248.23,312.64c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						C248.76,312.77,248.52,312.64,248.23,312.64z"/>
											</g>
											<g>
												<path class="stci4" d="M251.07,310.99v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H251.07z" />
												<path class="stci2" d="M251.6,310.7c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						C252.13,310.83,251.9,310.7,251.6,310.7z"/>
											</g>
										</g>
										<g>
											<g>
												<path class="stci9" d="M263.49,317.65c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C265.52,311.74,265.48,315.06,263.49,317.65z"/>
												<path class="stci5" d="M264.43,318.38c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C266.46,312.47,266.43,315.78,264.43,318.38z"/>
												<g>
													<path class="stci2" d="M269.6,316.09l-5.68-4.37c-0.66-0.51-1.49-0.68-2.35-0.58l7.43,5.72L269.6,316.09z" />
													<path class="stci2" d="M256.91,315.45l8.28,6.37l1.16-1.51l-8.61-6.63C257.36,314.25,257.08,314.85,256.91,315.45z" />
													<path class="stci2" d="M257.91,319.54l5.68,4.37l0.87-1.13l-7.75-5.96C256.7,317.93,257.1,318.92,257.91,319.54z" />
													<path class="stci2" d="M260.14,311.58c-0.57,0.27-1.13,0.67-1.63,1.17l8.58,6.6l1.17-1.52L260.14,311.58z" />
													<path class="stci2" d="M256.71,316.82l7.75,5.96l0.74-0.96l-8.28-6.37C256.78,315.91,256.71,316.37,256.71,316.82z" />
													<path class="stci4" d="M256.71,316.82l7.75,5.96l0.74-0.96l-8.28-6.37C256.78,315.91,256.71,316.37,256.71,316.82z" />
													<path class="stci2" d="M269,316.87l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L269,316.87z" />
													<path class="stci4" d="M269,316.87l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L269,316.87z" />
													<path class="stci2" d="M257.97,313.37c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C258.32,312.94,258.14,313.14,257.97,313.37z"/>
													<path class="stci4" d="M257.97,313.37c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C258.32,312.94,258.14,313.14,257.97,313.37z"/>
												</g>
												<path class="stci3" d="M269.54,322.27c-1.66,2.16-4.32,2.89-5.95,1.64c-1.63-1.25-1.6-4.02,0.06-6.17
						c1.66-2.16,4.32-2.89,5.95-1.64C271.22,317.35,271.2,320.11,269.54,322.27z"/>
												<g>
													<path class="stci2" d="M268.97,316.87c-0.15-0.12-0.31-0.21-0.49-0.28c0.89,1.11,0.75,3.01-0.43,4.54
							c-1.17,1.52-2.97,2.15-4.26,1.59c0.11,0.14,0.24,0.27,0.38,0.38c1.3,1,3.42,0.41,4.75-1.31
							C270.25,320.08,270.27,317.87,268.97,316.87z"/>
													<path class="stci10" d="M268.06,321.13c1.18-1.53,1.32-3.43,0.43-4.54c-1.3-0.56-3.09,0.07-4.26,1.59
							c-1.18,1.53-1.32,3.43-0.43,4.54C265.09,323.28,266.89,322.65,268.06,321.13z"/>
												</g>
											</g>
											<path class="stci9" d="M266.57,308.79c-0.8-0.47-1.92-0.19-4,0.99c-2.59,1.48-3.57,2.24-3.57,4.35v4.79c0,0.33,0.14,0.51,0.26,0.6
					c0.14,0.11,0.31,0.14,0.44,0.14c0.11,0,0.2-0.02,0.24-0.03l0.24-0.07v-5.43c0-1.4,0.42-1.87,2.98-3.34
					c1.99-1.14,2.62-1.11,2.83-1c0.09,0.05,0.29,0.26,0.29,1.13v2.61l1.17,0.9v-3.5C267.44,309.84,267.15,309.14,266.57,308.79z"/>
											<g>
												<path class="stci4" d="M260.76,305.45v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H260.76z" />
												<path class="stci2" d="M261.29,305.17c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						C261.82,305.3,261.58,305.17,261.29,305.17z"/>
											</g>
											<g>
												<path class="stci4" d="M264.13,303.52v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H264.13z" />
												<path class="stci2" d="M264.66,303.23c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						C265.19,303.36,264.95,303.23,264.66,303.23z"/>
											</g>
										</g>
										<g>
											<g>
												<path class="stci9" d="M276.6,310.21c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C278.63,304.3,278.59,307.61,276.6,310.21z"/>
												<path class="stci5" d="M277.55,310.93c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C279.57,305.02,279.54,308.34,277.55,310.93z"/>
												<g>
													<path class="stci2" d="M282.71,308.65l-5.68-4.37c-0.66-0.51-1.49-0.68-2.35-0.58l7.43,5.72L282.71,308.65z" />
													<path class="stci2" d="M270.02,308l8.28,6.37l1.16-1.51l-8.61-6.63C270.47,306.81,270.19,307.41,270.02,308z" />
													<path class="stci2" d="M271.02,312.1l5.68,4.37l0.87-1.13l-7.74-5.96C269.81,310.48,270.21,311.48,271.02,312.1z" />
													<path class="stci2" d="M273.25,304.13c-0.57,0.27-1.13,0.67-1.63,1.17l8.58,6.6l1.17-1.52L273.25,304.13z" />
													<path class="stci2" d="M269.82,309.38l7.74,5.96l0.74-0.96l-8.28-6.37C269.89,308.47,269.82,308.93,269.82,309.38z" />
													<path class="stci4" d="M269.82,309.38l7.74,5.96l0.74-0.96l-8.28-6.37C269.89,308.47,269.82,308.93,269.82,309.38z" />
													<path class="stci2" d="M282.11,309.43l-7.43-5.72c-0.48,0.06-0.96,0.2-1.43,0.43l8.12,6.25L282.11,309.43z" />
													<path class="stci4" d="M282.11,309.43l-7.43-5.72c-0.48,0.06-0.96,0.2-1.43,0.43l8.12,6.25L282.11,309.43z" />
													<path class="stci2" d="M271.08,305.92c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C271.43,305.5,271.25,305.7,271.08,305.92z"/>
													<path class="stci4" d="M271.08,305.92c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C271.43,305.5,271.25,305.7,271.08,305.92z"/>
												</g>
												<path class="stci3" d="M282.65,314.83c-1.66,2.16-4.32,2.89-5.95,1.64c-1.63-1.25-1.6-4.02,0.06-6.17
						c1.66-2.16,4.32-2.89,5.95-1.64C284.33,309.9,284.31,312.67,282.65,314.83z"/>
												<g>
													<path class="stci2" d="M282.08,309.43c-0.15-0.12-0.31-0.21-0.49-0.28c0.89,1.11,0.75,3.01-0.43,4.54
							c-1.17,1.52-2.97,2.15-4.26,1.59c0.11,0.14,0.24,0.27,0.38,0.38c1.3,1,3.42,0.41,4.75-1.31
							C283.36,312.63,283.38,310.43,282.08,309.43z"/>
													<path class="stci10" d="M281.17,313.69c1.18-1.53,1.32-3.43,0.43-4.54c-1.3-0.56-3.09,0.07-4.26,1.59
							c-1.18,1.53-1.32,3.43-0.43,4.54C278.2,315.84,280,315.21,281.17,313.69z"/>
												</g>
											</g>
											<path class="stci9" d="M279.68,301.35c-0.8-0.47-1.92-0.19-4,0.99c-2.59,1.48-3.57,2.24-3.57,4.35v4.79c0,0.33,0.14,0.51,0.26,0.6
					c0.14,0.11,0.31,0.14,0.44,0.14c0.11,0,0.2-0.02,0.24-0.03l0.24-0.07v-5.43c0-1.4,0.42-1.87,2.98-3.34
					c1.99-1.14,2.62-1.11,2.83-1c0.09,0.05,0.29,0.26,0.29,1.13v2.61l1.17,0.9v-3.5C280.55,302.39,280.27,301.69,279.68,301.35z"/>
											<g>
												<path class="stci4" d="M273.87,298.01v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H273.87z" />
												<path class="stci2" d="M274.4,297.73c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						C274.93,297.86,274.69,297.73,274.4,297.73z"/>
											</g>
											<g>
												<path class="stci4" d="M277.24,296.07v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H277.24z" />
												<path class="stci2" d="M277.77,295.79c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						C278.3,295.92,278.06,295.79,277.77,295.79z"/>
											</g>
										</g>
										<g>
											<g>
												<path class="stci9" d="M289.66,302.73c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C291.68,296.82,291.65,300.14,289.66,302.73z"/>
												<path class="stci5" d="M290.6,303.46c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C292.63,297.55,292.6,300.87,290.6,303.46z"/>
												<g>
													<path class="stci2" d="M295.76,301.18l-5.68-4.37c-0.66-0.51-1.49-0.68-2.35-0.58l7.43,5.72L295.76,301.18z" />
													<path class="stci2" d="M283.08,300.53l8.28,6.37l1.16-1.51l-8.61-6.63C283.52,299.34,283.25,299.94,283.08,300.53z" />
													<path class="stci2" d="M284.07,304.62l5.68,4.37l0.87-1.13l-7.75-5.96C282.87,303.01,283.27,304,284.07,304.62z" />
													<path class="stci2" d="M286.31,296.66c-0.57,0.27-1.13,0.67-1.63,1.17l8.58,6.6l1.17-1.52L286.31,296.66z" />
													<path class="stci2" d="M282.87,301.91l7.75,5.96l0.74-0.96l-8.28-6.37C282.94,301,282.88,301.46,282.87,301.91z" />
													<path class="stci4" d="M282.87,301.91l7.75,5.96l0.74-0.96l-8.28-6.37C282.94,301,282.88,301.46,282.87,301.91z" />
													<path class="stci2" d="M295.17,301.95l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L295.17,301.95z" />
													<path class="stci4" d="M295.17,301.95l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L295.17,301.95z" />
													<path class="stci2" d="M284.14,298.45c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C284.49,298.03,284.31,298.23,284.14,298.45z"/>
													<path class="stci4" d="M284.14,298.45c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C284.49,298.03,284.31,298.23,284.14,298.45z"/>
												</g>
												<path class="stci3" d="M295.7,307.35c-1.66,2.16-4.32,2.89-5.95,1.64c-1.63-1.25-1.6-4.02,0.06-6.17
						c1.66-2.16,4.32-2.89,5.95-1.64C297.39,302.43,297.36,305.2,295.7,307.35z"/>
												<g>
													<path class="stci2" d="M295.14,301.96c-0.15-0.12-0.31-0.21-0.49-0.28c0.89,1.11,0.75,3.01-0.43,4.54
							c-1.17,1.52-2.97,2.15-4.26,1.59c0.11,0.14,0.24,0.27,0.38,0.38c1.3,1,3.42,0.41,4.75-1.31
							C296.42,305.16,296.44,302.96,295.14,301.96z"/>
													<path class="stci10" d="M294.22,306.22c1.18-1.53,1.32-3.43,0.43-4.54c-1.3-0.56-3.09,0.07-4.26,1.59
							c-1.18,1.53-1.32,3.43-0.43,4.54C291.26,308.37,293.05,307.74,294.22,306.22z"/>
												</g>
											</g>
											<path class="stci9" d="M292.73,293.88c-0.8-0.47-1.92-0.19-4,0.99c-2.59,1.48-3.57,2.24-3.57,4.35v4.79c0,0.33,0.14,0.51,0.26,0.6
					c0.14,0.11,0.31,0.14,0.44,0.14c0.11,0,0.2-0.02,0.24-0.03l0.24-0.07v-5.43c0-1.4,0.42-1.87,2.98-3.34
					c1.99-1.14,2.62-1.11,2.83-1c0.09,0.05,0.29,0.26,0.29,1.13v2.61l1.17,0.9v-3.5C293.61,294.92,293.32,294.22,292.73,293.88z"/>
											<g>
												<path class="stci4" d="M286.92,290.54v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H286.92z" />
												<path class="stci2" d="M287.45,290.26c-0.29,0-0.53,0.13-0.53,0.28s0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						S287.75,290.26,287.45,290.26z"/>
											</g>
											<g>
												<path class="stci4" d="M290.3,288.6v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H290.3z" />
												<path class="stci2" d="M290.83,288.32c-0.29,0-0.53,0.13-0.53,0.28s0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						S291.12,288.32,290.83,288.32z"/>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<path class="stci9" d="M303.02,294.82c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C305.05,288.91,305.02,292.23,303.02,294.82z"/>
												<path class="stci5" d="M303.97,295.55c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97S305.96,292.96,303.97,295.55z"/>
												<g>
													<path class="stci2" d="M309.13,293.27l-5.68-4.37c-0.66-0.51-1.49-0.68-2.35-0.58l7.43,5.72L309.13,293.27z" />
													<path class="stci2" d="M296.44,292.62l8.28,6.37l1.16-1.51l-8.61-6.63C296.89,291.42,296.61,292.02,296.44,292.62z" />
													<path class="stci2" d="M297.44,296.71l5.68,4.37l0.87-1.13l-7.75-5.96C296.23,295.1,296.63,296.09,297.44,296.71z" />
													<path class="stci2" d="M299.67,288.75c-0.57,0.27-1.13,0.67-1.63,1.17l8.58,6.6l1.17-1.52L299.67,288.75z" />
													<path class="stci2" d="M296.24,293.99l7.75,5.96l0.74-0.96l-8.28-6.37C296.31,293.08,296.24,293.55,296.24,293.99z" />
													<path class="stci4" d="M296.24,293.99l7.75,5.96l0.74-0.96l-8.28-6.37C296.31,293.08,296.24,293.55,296.24,293.99z" />
													<path class="stci2" d="M308.53,294.04l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L308.53,294.04z" />
													<path class="stci4" d="M308.53,294.04l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L308.53,294.04z" />
													<path class="stci2" d="M297.5,290.54c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C297.86,290.11,297.67,290.32,297.5,290.54z"/>
													<path class="stci4" d="M297.5,290.54c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C297.86,290.11,297.67,290.32,297.5,290.54z"/>
												</g>
												<path class="stci3" d="M309.07,299.44c-1.66,2.16-4.32,2.89-5.95,1.64c-1.63-1.25-1.6-4.02,0.06-6.17
						c1.66-2.16,4.32-2.89,5.95-1.64C310.76,294.52,310.73,297.28,309.07,299.44z"/>
												<g>
													<path class="stci2" d="M308.51,294.04c-0.15-0.12-0.31-0.21-0.49-0.28c0.89,1.11,0.75,3.01-0.43,4.54
							c-1.17,1.52-2.97,2.15-4.26,1.59c0.11,0.14,0.24,0.27,0.38,0.38c1.3,1,3.42,0.41,4.75-1.31
							C309.78,297.25,309.8,295.04,308.51,294.04z"/>
													<path class="stci10" d="M307.59,298.3c1.18-1.53,1.32-3.43,0.43-4.54c-1.3-0.56-3.09,0.07-4.26,1.59
							c-1.18,1.53-1.32,3.43-0.43,4.54C304.62,300.46,306.42,299.82,307.59,298.3z"/>
												</g>
											</g>
											<path class="stci9" d="M306.1,285.97c-0.8-0.47-1.92-0.19-4,0.99c-2.59,1.48-3.57,2.24-3.57,4.35v4.79c0,0.33,0.14,0.51,0.26,0.6
					c0.14,0.11,0.31,0.14,0.44,0.14c0.11,0,0.2-0.02,0.24-0.03l0.24-0.07v-5.43c0-1.4,0.42-1.87,2.98-3.34
					c1.99-1.14,2.62-1.11,2.83-1c0.09,0.05,0.29,0.26,0.29,1.13v2.61l1.17,0.9v-3.5C306.97,287.01,306.69,286.31,306.1,285.97z"/>
											<g>
												<path class="stci4" d="M300.29,282.63v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H300.29z" />
												<path class="stci2" d="M300.82,282.35c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						C301.35,282.47,301.11,282.35,300.82,282.35z"/>
											</g>
											<g>
												<path class="stci4" d="M303.66,280.69v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H303.66z" />
												<path class="stci2" d="M304.19,280.41c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						C304.72,280.53,304.49,280.41,304.19,280.41z"/>
											</g>
										</g>
										<g>
											<g>
												<path class="stci9" d="M316.08,287.35c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C318.11,281.44,318.07,284.76,316.08,287.35z"/>
												<path class="stci5" d="M317.02,288.08c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C319.05,282.17,319.02,285.49,317.02,288.08z"/>
												<g>
													<path class="stci2" d="M322.19,285.8l-5.68-4.37c-0.66-0.51-1.49-0.68-2.35-0.58l7.43,5.72L322.19,285.8z" />
													<path class="stci2" d="M309.5,285.15l8.28,6.37l1.16-1.51l-8.61-6.63C309.95,283.95,309.67,284.55,309.5,285.15z" />
													<path class="stci2" d="M310.5,289.24l5.68,4.37l0.87-1.13l-7.75-5.96C309.29,287.63,309.69,288.62,310.5,289.24z" />
													<path class="stci2" d="M312.73,281.28c-0.57,0.27-1.13,0.67-1.63,1.17l8.58,6.6l1.17-1.52L312.73,281.28z" />
													<path class="stci2" d="M309.3,286.52l7.75,5.96l0.74-0.96l-8.28-6.37C309.37,285.61,309.3,286.08,309.3,286.52z" />
													<path class="stci4" d="M309.3,286.52l7.75,5.96l0.74-0.96l-8.28-6.37C309.37,285.61,309.3,286.08,309.3,286.52z" />
													<path class="stci2" d="M321.59,286.57l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L321.59,286.57z" />
													<path class="stci4" d="M321.59,286.57l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L321.59,286.57z" />
													<path class="stci2" d="M310.56,283.07c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C310.91,282.64,310.73,282.85,310.56,283.07z"/>
													<path class="stci4" d="M310.56,283.07c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C310.91,282.64,310.73,282.85,310.56,283.07z"/>
												</g>
												<path class="stci3" d="M322.12,291.97c-1.66,2.16-4.32,2.89-5.95,1.64c-1.63-1.25-1.6-4.02,0.06-6.17
						c1.66-2.16,4.32-2.89,5.95-1.64C323.81,287.05,323.78,289.81,322.12,291.97z"/>
												<g>
													<path class="stci2" d="M321.56,286.57c-0.15-0.12-0.31-0.21-0.49-0.28c0.89,1.11,0.75,3.01-0.43,4.54
							c-1.17,1.52-2.97,2.15-4.26,1.59c0.11,0.14,0.24,0.27,0.38,0.38c1.3,1,3.42,0.41,4.75-1.31
							C322.84,289.78,322.86,287.57,321.56,286.57z"/>
													<path class="stci10" d="M320.65,290.83c1.18-1.53,1.32-3.43,0.43-4.54c-1.3-0.56-3.09,0.07-4.26,1.59
							c-1.18,1.53-1.32,3.43-0.43,4.54C317.68,292.99,319.48,292.35,320.65,290.83z"/>
												</g>
											</g>
											<path class="stci9" d="M319.16,278.5c-0.8-0.47-1.92-0.19-4,0.99c-2.59,1.48-3.57,2.24-3.57,4.35v4.79c0,0.33,0.14,0.51,0.26,0.6
					c0.14,0.11,0.31,0.14,0.44,0.14c0.11,0,0.2-0.02,0.23-0.03l0.24-0.07v-5.43c0-1.4,0.42-1.87,2.98-3.34
					c1.99-1.14,2.62-1.11,2.83-1c0.09,0.05,0.29,0.26,0.29,1.13v2.61l1.17,0.9v-3.5C320.03,279.54,319.74,278.84,319.16,278.5z"/>
											<g>
												<path class="stci4" d="M313.34,275.16v5.5c0,0.16,0.24,0.28,0.53,0.28s0.53-0.13,0.53-0.28v-5.5H313.34z" />
												<path class="stci2" d="M313.88,274.87c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28s0.53-0.13,0.53-0.28
						C314.41,275,314.17,274.87,313.88,274.87z"/>
											</g>
											<g>
												<path class="stci4" d="M316.72,273.22v5.5c0,0.16,0.24,0.28,0.53,0.28s0.53-0.13,0.53-0.28v-5.5H316.72z" />
												<path class="stci2" d="M317.25,272.93c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28s0.53-0.13,0.53-0.28
						C317.78,273.06,317.54,272.93,317.25,272.93z"/>
											</g>
										</g>
										<g>
											<g>
												<path class="stci9" d="M329.19,279.91c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97S331.18,277.32,329.19,279.91z"/>
												<path class="stci5" d="M330.13,280.63c-1.99,2.59-5.19,3.47-7.15,1.97s-1.92-4.82,0.07-7.41c1.99-2.59,5.19-3.47,7.15-1.97
						C332.16,274.72,332.13,278.04,330.13,280.63z"/>
												<g>
													<path class="stci2" d="M335.3,278.35l-5.68-4.37c-0.66-0.51-1.49-0.68-2.35-0.58l7.43,5.72L335.3,278.35z" />
													<path class="stci2" d="M322.61,277.7l8.28,6.37l1.16-1.51l-8.61-6.63C323.06,276.51,322.78,277.11,322.61,277.7z" />
													<path class="stci2" d="M323.61,281.8l5.68,4.37l0.87-1.13l-7.75-5.96C322.4,280.18,322.8,281.18,323.61,281.8z" />
													<path class="stci2" d="M325.84,273.83c-0.57,0.27-1.13,0.67-1.63,1.17l8.58,6.6l1.17-1.52L325.84,273.83z" />
													<path class="stci2" d="M322.41,279.08l7.75,5.96l0.74-0.96l-8.28-6.37C322.48,278.17,322.41,278.63,322.41,279.08z" />
													<path class="stci4" d="M322.41,279.08l7.75,5.96l0.74-0.96l-8.28-6.37C322.48,278.17,322.41,278.63,322.41,279.08z" />
													<path class="stci2" d="M334.7,279.13l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L334.7,279.13z" />
													<path class="stci4" d="M334.7,279.13l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L334.7,279.13z" />
													<path class="stci2" d="M323.67,275.62c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C324.02,275.2,323.84,275.4,323.67,275.62z"/>
													<path class="stci4" d="M323.67,275.62c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C324.02,275.2,323.84,275.4,323.67,275.62z"/>
												</g>
												<path class="stci3" d="M335.23,284.53c-1.66,2.16-4.32,2.89-5.95,1.64c-1.63-1.25-1.6-4.02,0.06-6.17
						c1.66-2.16,4.32-2.89,5.95-1.64C336.92,279.61,336.9,282.37,335.23,284.53z"/>
												<g>
													<path class="stci2" d="M334.67,279.13c-0.15-0.12-0.31-0.21-0.49-0.28c0.89,1.11,0.75,3.01-0.43,4.54
							c-1.17,1.52-2.97,2.15-4.26,1.59c0.11,0.14,0.24,0.27,0.38,0.38c1.3,1,3.42,0.41,4.75-1.31
							C335.95,282.33,335.97,280.13,334.67,279.13z"/>
													<path class="stci10" d="M333.76,283.39c1.18-1.53,1.32-3.43,0.43-4.54c-1.3-0.56-3.09,0.07-4.26,1.59
							c-1.18,1.53-1.32,3.43-0.43,4.54C330.79,285.54,332.59,284.91,333.76,283.39z"/>
												</g>
											</g>
											<path class="stci9" d="M332.27,271.05c-0.8-0.47-1.92-0.19-4,0.99c-2.59,1.48-3.57,2.24-3.57,4.35v4.79c0,0.33,0.14,0.51,0.26,0.6
					c0.14,0.11,0.31,0.14,0.44,0.14c0.11,0,0.2-0.02,0.24-0.03l0.24-0.07v-5.43c0-1.4,0.42-1.87,2.98-3.34
					c1.99-1.14,2.62-1.11,2.83-1c0.09,0.05,0.29,0.26,0.29,1.13v2.61l1.17,0.9v-3.5C333.14,272.09,332.85,271.39,332.27,271.05z"/>
											<g>
												<path class="stci4" d="M326.46,267.71v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H326.46z" />
												<path class="stci2" d="M326.99,267.43c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						C327.52,267.56,327.28,267.43,326.99,267.43z"/>
											</g>
											<g>
												<path class="stci4" d="M329.83,265.77v5.5c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28v-5.5H329.83z" />
												<path class="stci2" d="M330.36,265.49c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28c0.29,0,0.53-0.13,0.53-0.28
						C330.89,265.62,330.65,265.49,330.36,265.49z"/>
											</g>
										</g>
										<g>
											<g>
												<path class="stci9" d="M342.25,272.44c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C344.27,266.53,344.24,269.84,342.25,272.44z"/>
												<path class="stci5" d="M343.19,273.16c-1.99,2.59-5.19,3.47-7.15,1.97c-1.95-1.5-1.92-4.82,0.07-7.41
						c1.99-2.59,5.19-3.47,7.15-1.97C345.22,267.25,345.18,270.57,343.19,273.16z"/>
												<g>
													<path class="stci2" d="M348.35,270.88l-5.68-4.37c-0.66-0.51-1.49-0.68-2.35-0.58l7.43,5.72L348.35,270.88z" />
													<path class="stci2" d="M335.66,270.23l8.28,6.37l1.16-1.51l-8.61-6.63C336.11,269.04,335.83,269.64,335.66,270.23z" />
													<path class="stci2" d="M336.66,274.33l5.68,4.37l0.87-1.13l-7.75-5.96C335.46,272.71,335.86,273.71,336.66,274.33z" />
													<path class="stci2" d="M338.9,266.36c-0.57,0.27-1.13,0.67-1.63,1.17l8.58,6.6l1.17-1.52L338.9,266.36z" />
													<path class="stci2" d="M335.46,271.61l7.75,5.96l0.74-0.96l-8.28-6.37C335.53,270.7,335.46,271.16,335.46,271.61z" />
													<path class="stci4" d="M335.46,271.61l7.75,5.96l0.74-0.96l-8.28-6.37C335.53,270.7,335.46,271.16,335.46,271.61z" />
													<path class="stci2" d="M347.76,271.66l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L347.76,271.66z" />
													<path class="stci4" d="M347.76,271.66l-7.43-5.72c-0.47,0.06-0.96,0.2-1.43,0.43l8.12,6.25L347.76,271.66z" />
													<path class="stci2" d="M336.72,268.15c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C337.08,267.73,336.9,267.93,336.72,268.15z"/>
													<path class="stci4" d="M336.72,268.15c-0.08,0.1-0.15,0.21-0.23,0.32l8.61,6.63l0.74-0.96l-8.58-6.6
							C337.08,267.73,336.9,267.93,336.72,268.15z"/>
												</g>
												<path class="stci3" d="M348.29,277.06c-1.66,2.16-4.32,2.89-5.95,1.64c-1.63-1.25-1.6-4.02,0.06-6.17
						c1.66-2.16,4.32-2.89,5.95-1.64C349.98,272.13,349.95,274.9,348.29,277.06z"/>
												<g>
													<path class="stci2" d="M347.73,271.66c-0.15-0.12-0.31-0.21-0.49-0.28c0.89,1.11,0.75,3.01-0.43,4.54
							c-1.17,1.52-2.97,2.15-4.26,1.59c0.11,0.14,0.24,0.27,0.38,0.38c1.3,1,3.42,0.41,4.75-1.31
							C349,274.86,349.03,272.66,347.73,271.66z"/>
													<path class="stci10" d="M346.81,275.92c1.18-1.53,1.32-3.43,0.43-4.54c-1.3-0.56-3.09,0.07-4.26,1.59
							c-1.18,1.53-1.32,3.43-0.43,4.54C343.85,278.07,345.64,277.44,346.81,275.92z"/>
												</g>
											</g>
											<path class="stci9" d="M345.32,263.58c-0.8-0.47-1.92-0.19-4,0.99c-2.59,1.48-3.57,2.24-3.57,4.35v4.79c0,0.33,0.14,0.51,0.26,0.6
					c0.14,0.11,0.31,0.14,0.44,0.14c0.11,0,0.2-0.02,0.24-0.03l0.24-0.07v-5.43c0-1.4,0.42-1.87,2.98-3.34
					c1.99-1.14,2.62-1.11,2.83-1c0.09,0.05,0.29,0.26,0.29,1.13v2.61l1.17,0.9v-3.5C346.2,264.62,345.91,263.92,345.32,263.58z"/>
											<g>
												<path class="stci4" d="M339.51,260.24v5.5c0,0.16,0.24,0.28,0.53,0.28s0.53-0.13,0.53-0.28v-5.5H339.51z" />
												<path class="stci2" d="M340.04,259.96c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28s0.53-0.13,0.53-0.28
						C340.57,260.09,340.34,259.96,340.04,259.96z"/>
											</g>
											<g>
												<path class="stci4" d="M342.89,258.3v5.5c0,0.16,0.24,0.28,0.53,0.28s0.53-0.13,0.53-0.28v-5.5H342.89z" />
												<path class="stci2" d="M343.42,258.02c-0.29,0-0.53,0.13-0.53,0.28c0,0.16,0.24,0.28,0.53,0.28s0.53-0.13,0.53-0.28
						C343.95,258.15,343.71,258.02,343.42,258.02z"/>
											</g>
										</g>
									</g>
									<g>
										<g>
											<g>
												<polygon class="stci2" points="340.41,245.11 340.23,228.59 354.31,236.93 354.49,253.45 					" />
												<polygon class="stci3" points="339.66,245.54 339.48,229.02 353.56,237.35 353.75,253.88 					" />
												<polygon class="stci2" points="353.56,237.35 354.31,236.93 340.23,228.59 339.48,229.02 					" />
												<polygon class="stci4" points="353.75,253.88 354.49,253.45 354.31,236.93 353.56,237.35 					" />
											</g>
											<g>
												<g>
													<polygon class="stci5" points="341.08,246.52 341.08,244.59 224.39,311.96 224.39,313.89 						" />
													<polygon class="stci5" points="339.42,245.55 339.42,243.62 222.73,310.99 222.73,312.92 						" />
													<polygon class="stci6" points="339.41,243.63 341.08,244.59 224.39,311.96 222.72,311 						" />
												</g>
												<polygon class="stci12" points="224.39,313.89 222.73,312.92 222.72,311 224.39,311.96 					" />
											</g>
											<g>
												<g>
													<polygon class="stci5" points="351.83,250.84 353.5,251.8 236.81,319.17 235.14,318.21 						" />
													<polygon class="stci5" points="351.84,252.76 353.51,253.72 236.82,321.1 235.15,320.13 						" />
													<polygon class="stci6" points="353.5,253.73 353.5,251.8 236.81,319.17 236.81,321.1 						" />
												</g>
												<polygon class="stci12" points="235.14,318.21 235.15,320.13 236.81,321.1 236.81,319.17 					" />
											</g>
											<g>
												<g>
													<polygon class="stci5" points="341.05,232.17 341.05,230.24 224.36,297.61 224.36,299.54 						" />
													<polygon class="stci5" points="339.39,231.19 339.39,229.27 222.7,296.64 222.7,298.57 						" />
													<polygon class="stci6" points="339.38,229.27 341.05,230.24 224.36,297.61 222.69,296.64 						" />
												</g>
												<polygon class="stci12" points="224.36,299.54 222.7,298.57 222.69,296.64 224.36,297.61 					" />
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M318.84,244.63l3.57,9.8l0.78-0.45l-3.51-9.66c-0.01-0.03-0.02-0.05-0.03-0.07
									c-0.11-0.18-0.33-0.27-0.54-0.2C318.88,244.15,318.76,244.4,318.84,244.63z"/>
														</g>
														<g>
															<path class="stci5" d="M318.82,244.43c-0.03,0.24,0.15,0.46,0.39,0.49l16.48,1.85l1.29-0.75l-17.68-1.98
									C319.06,244.02,318.84,244.19,318.82,244.43z"/>
														</g>
														<g>
															<path class="stci5" d="M311.83,248.51c-0.12,0.22-0.05,0.49,0.16,0.61l9.82,5.67l0.89-0.51l-10.27-5.93
									C312.22,248.22,311.95,248.29,311.83,248.51z"/>
														</g>
														<g>
															<path class="stci5" d="M326.47,240.05c-0.12,0.22-0.05,0.49,0.16,0.61l9.82,5.67l0.89-0.51l-10.27-5.93
									C326.86,239.77,326.59,239.84,326.47,240.05z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M333.48,236.18l3.57,9.81l0.78-0.45l-3.51-9.66c-0.01-0.03-0.02-0.05-0.03-0.07
									c-0.11-0.18-0.33-0.27-0.53-0.19C333.52,235.7,333.4,235.95,333.48,236.18z"/>
														</g>
														<g>
															<path class="stci5" d="M333.46,235.98c-0.03,0.24,0.15,0.46,0.39,0.49l16.48,1.85l1.29-0.75l-17.68-1.98
									C333.71,235.56,333.49,235.74,333.46,235.98z"/>
														</g>
														<g>
															<path class="stci5" d="M351.98,237.37l-10.86-6.2c-0.24,0.33-0.56,0.66-0.35,0.78l10.33,5.93L351.98,237.37z" />
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M289.56,261.54l3.57,9.81l0.78-0.45l-3.51-9.66c-0.01-0.03-0.02-0.05-0.03-0.07
									c-0.11-0.18-0.33-0.27-0.53-0.19C289.6,261.06,289.48,261.31,289.56,261.54z"/>
														</g>
														<g>
															<path class="stci5" d="M289.54,261.34c-0.03,0.24,0.15,0.46,0.39,0.49l16.48,1.85l1.29-0.75l-17.68-1.98
									C289.78,260.92,289.56,261.1,289.54,261.34z"/>
														</g>
														<g>
															<path class="stci5" d="M282.55,265.41c-0.12,0.22-0.05,0.49,0.16,0.61l9.82,5.67l0.89-0.51l-10.27-5.93
									C282.94,265.13,282.67,265.2,282.55,265.41z"/>
														</g>
														<g>
															<path class="stci5" d="M297.19,256.96c-0.12,0.22-0.05,0.49,0.16,0.61l9.82,5.67l0.89-0.51l-10.27-5.93
									C297.58,256.67,297.31,256.75,297.19,256.96z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M304.2,253.09l3.57,9.81l0.78-0.45l-3.51-9.66c-0.01-0.03-0.02-0.05-0.03-0.07
									c-0.11-0.18-0.33-0.27-0.53-0.19C304.24,252.6,304.12,252.86,304.2,253.09z"/>
														</g>
														<g>
															<path class="stci5" d="M304.18,252.89c-0.03,0.24,0.15,0.46,0.39,0.49l16.48,1.85l1.29-0.75l-17.68-1.98
									C304.43,252.47,304.21,252.64,304.18,252.89z"/>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M260.28,278.45l3.57,9.81l0.78-0.45l-3.51-9.66c-0.01-0.03-0.02-0.05-0.03-0.07
									c-0.11-0.18-0.33-0.27-0.54-0.19C260.32,277.96,260.2,278.22,260.28,278.45z"/>
														</g>
														<g>
															<path class="stci5" d="M260.26,278.24c-0.03,0.24,0.15,0.46,0.39,0.49l16.48,1.85l1.29-0.75l-17.68-1.98
									C260.5,277.83,260.28,278,260.26,278.24z"/>
														</g>
														<g>
															<path class="stci5" d="M253.27,282.32c-0.12,0.22-0.05,0.49,0.16,0.61l9.82,5.67l0.89-0.51l-10.27-5.93
									C253.66,282.03,253.39,282.1,253.27,282.32z"/>
														</g>
														<g>
															<path class="stci5" d="M267.91,273.86c-0.12,0.22-0.05,0.49,0.16,0.61l9.82,5.67l0.89-0.51l-10.27-5.93
									C268.3,273.58,268.03,273.65,267.91,273.86z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M274.92,269.99l3.57,9.81l0.78-0.45l-3.51-9.66c-0.01-0.03-0.02-0.05-0.03-0.07
									c-0.11-0.18-0.33-0.27-0.53-0.19C274.96,269.51,274.84,269.76,274.92,269.99z"/>
														</g>
														<g>
															<path class="stci5" d="M274.9,269.79c-0.03,0.24,0.15,0.46,0.39,0.49l16.48,1.85l1.29-0.75l-17.68-1.98
									C275.14,269.37,274.93,269.55,274.9,269.79z"/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M231,295.35l3.57,9.81l0.78-0.45l-3.51-9.66c-0.01-0.03-0.02-0.05-0.03-0.07
									c-0.11-0.18-0.33-0.27-0.53-0.19C231.03,294.87,230.92,295.12,231,295.35z"/>
														</g>
														<g>
															<path class="stci5" d="M230.98,295.15c-0.03,0.24,0.15,0.46,0.39,0.49l16.48,1.85l1.29-0.75l-17.68-1.98
									C231.22,294.73,231,294.91,230.98,295.15z"/>
														</g>
														<g>
															<path class="stci5" d="M223.99,299.22c-0.12,0.22-0.05,0.49,0.16,0.61l9.82,5.67l0.89-0.51l-10.27-5.93
									C224.38,298.94,224.11,299.01,223.99,299.22z"/>
														</g>
														<g>
															<path class="stci5" d="M238.63,290.77c-0.12,0.22-0.05,0.49,0.16,0.61l9.82,5.67l0.89-0.51l-10.27-5.93
									C239.02,290.48,238.75,290.56,238.63,290.77z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M245.64,286.9l3.57,9.81l0.78-0.45l-3.51-9.66c-0.01-0.03-0.02-0.05-0.03-0.07
									c-0.11-0.18-0.33-0.27-0.53-0.19C245.68,286.41,245.56,286.67,245.64,286.9z"/>
														</g>
														<g>
															<path class="stci5" d="M245.62,286.7c-0.03,0.24,0.15,0.46,0.39,0.49l16.48,1.85l1.29-0.75l-17.68-1.98
									C245.86,286.28,245.64,286.45,245.62,286.7z"/>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M330.15,264.23l-6.71-7.99l0.78-0.45l6.61,7.87c0.02,0.02,0.03,0.04,0.04,0.06
									c0.11,0.18,0.07,0.42-0.1,0.56C330.59,264.44,330.31,264.41,330.15,264.23z"/>
														</g>
														<g>
															<path class="stci5" d="M330.31,264.35c-0.23-0.1-0.33-0.36-0.23-0.58l6.64-15.2l1.29-0.75l-7.13,16.3
									C330.8,264.34,330.54,264.45,330.31,264.35z"/>
														</g>
														<g>
															<path class="stci5" d="M323.29,268.36c-0.25,0-0.45-0.2-0.44-0.44l0-11.34l0.89-0.51l0,11.85
									C323.74,268.17,323.54,268.36,323.29,268.36z"/>
														</g>
														<g>
															<path class="stci5" d="M337.94,259.91c-0.25,0-0.45-0.2-0.44-0.44l0-11.34l0.89-0.51l0,11.85
									C338.38,259.71,338.18,259.91,337.94,259.91z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M344.79,255.77l-6.71-7.99l0.78-0.45l6.61,7.87c0.02,0.02,0.03,0.04,0.04,0.06
									c0.11,0.18,0.07,0.42-0.1,0.56C345.23,255.98,344.95,255.96,344.79,255.77z"/>
														</g>
														<g>
															<path class="stci5" d="M344.96,255.89c-0.22-0.1-0.33-0.36-0.23-0.58l6.64-15.2l1.29-0.75l-7.13,16.3
									C345.44,255.89,345.18,255.99,344.96,255.89z"/>
														</g>
														<g>
															<path class="stci5" d="M353.02,239.16l-0.06,12.51c-0.41,0.05-0.85,0.15-0.85-0.09l0.03-11.91L353.02,239.16z" />
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M300.87,281.13l-6.71-7.99l0.78-0.45l6.61,7.87c0.02,0.02,0.03,0.04,0.04,0.06
									c0.11,0.18,0.07,0.42-0.1,0.56C301.31,281.34,301.03,281.32,300.87,281.13z"/>
														</g>
														<g>
															<path class="stci5" d="M301.03,281.25c-0.23-0.1-0.33-0.36-0.23-0.58l6.64-15.2l1.29-0.75l-7.13,16.3
									C301.52,281.25,301.26,281.35,301.03,281.25z"/>
														</g>
														<g>
															<path class="stci5" d="M294.01,285.27c-0.25,0-0.45-0.2-0.44-0.44l0-11.34l0.89-0.51l0,11.85
									C294.46,285.07,294.26,285.27,294.01,285.27z"/>
														</g>
														<g>
															<path class="stci5" d="M308.65,276.82c-0.25,0-0.45-0.2-0.44-0.44v-11.34l0.89-0.51v11.85
									C309.1,276.62,308.9,276.82,308.65,276.82z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M315.51,272.68l-6.71-7.99l0.78-0.45l6.61,7.87c0.02,0.02,0.03,0.04,0.04,0.06
									c0.11,0.18,0.07,0.42-0.1,0.56C315.95,272.89,315.67,272.87,315.51,272.68z"/>
														</g>
														<g>
															<path class="stci5" d="M315.68,272.8c-0.22-0.1-0.33-0.36-0.23-0.58l6.64-15.2l1.29-0.75l-7.13,16.3
									C316.16,272.79,315.9,272.9,315.68,272.8z"/>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M271.59,298.04l-6.71-7.99l0.78-0.45l6.61,7.87c0.02,0.02,0.03,0.04,0.04,0.06
									c0.11,0.18,0.07,0.42-0.1,0.56C272.03,298.25,271.75,298.22,271.59,298.04z"/>
														</g>
														<g>
															<path class="stci5" d="M271.75,298.16c-0.23-0.1-0.33-0.36-0.23-0.58l6.64-15.2l1.29-0.75l-7.13,16.3
									C272.24,298.15,271.98,298.26,271.75,298.16z"/>
														</g>
														<g>
															<path class="stci5" d="M264.73,302.17c-0.25,0-0.45-0.2-0.44-0.44l0-11.34l0.89-0.51l0,11.85
									C265.17,301.98,264.98,302.17,264.73,302.17z"/>
														</g>
														<g>
															<path class="stci5" d="M279.37,293.72c-0.25,0-0.45-0.2-0.44-0.44l0-11.34l0.89-0.51l0,11.85
									C279.82,293.52,279.62,293.72,279.37,293.72z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M286.23,289.58l-6.71-7.99l0.78-0.45l6.61,7.87c0.02,0.02,0.03,0.04,0.04,0.06
									c0.11,0.18,0.07,0.42-0.1,0.56C286.67,289.79,286.39,289.77,286.23,289.58z"/>
														</g>
														<g>
															<path class="stci5" d="M286.4,289.7c-0.22-0.1-0.33-0.36-0.23-0.58l6.64-15.2l1.29-0.75l-7.13,16.3
									C286.88,289.7,286.62,289.8,286.4,289.7z"/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M242.31,314.94l-6.71-7.99l0.78-0.45l6.61,7.87c0.02,0.02,0.03,0.04,0.04,0.06
									c0.11,0.18,0.07,0.42-0.1,0.56C242.75,315.15,242.47,315.13,242.31,314.94z"/>
														</g>
														<g>
															<path class="stci5" d="M242.47,315.06c-0.23-0.1-0.33-0.36-0.23-0.58l6.64-15.2l1.29-0.75l-7.12,16.3
									C242.96,315.06,242.7,315.16,242.47,315.06z"/>
														</g>
														<g>
															<path class="stci5" d="M235.45,319.08c-0.25,0-0.45-0.2-0.44-0.44v-11.34l0.89-0.51l0,11.85
									C235.89,318.88,235.7,319.08,235.45,319.08z"/>
														</g>
														<g>
															<path class="stci5" d="M250.09,310.63c-0.25,0-0.45-0.2-0.44-0.44v-11.34l0.89-0.51l0,11.85
									C250.54,310.43,250.34,310.63,250.09,310.63z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M256.95,306.49l-6.71-7.99l0.78-0.45l6.61,7.87c0.02,0.02,0.03,0.04,0.04,0.06
									c0.11,0.18,0.07,0.42-0.1,0.56C257.39,306.7,257.11,306.68,256.95,306.49z"/>
														</g>
														<g>
															<path class="stci5" d="M257.11,306.61c-0.22-0.1-0.33-0.36-0.23-0.58l6.64-15.2l1.29-0.75l-7.13,16.3
									C257.6,306.6,257.34,306.71,257.11,306.61z"/>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<polygon class="stci5" points="351.9,236.42 353.57,237.39 236.88,304.76 235.21,303.79 					" />
												<polygon class="stci5" points="351.91,238.34 353.58,239.31 236.89,306.68 235.22,305.72 					" />
												<polygon class="stci6" points="353.57,239.32 353.57,237.39 236.88,304.76 236.88,306.69 					" />
											</g>
											<polygon class="stci12" points="235.21,303.79 235.22,305.72 236.88,306.69 236.88,304.76 				" />
										</g>
										<g>
											<polygon class="stci2" points="223.1,312.85 222.92,296.33 237,304.67 237.18,321.19 				" />
											<polygon class="stci3" points="222.36,313.28 222.17,296.76 236.26,305.1 236.44,321.62 				" />
											<polygon class="stci2" points="236.26,305.1 237,304.67 222.92,296.33 222.17,296.76 				" />
											<polygon class="stci4" points="236.44,321.62 237.18,321.19 237,304.67 236.26,305.1 				" />
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<polygon class="stci2" points="223.57,433.56 238.41,441.91 223.71,450.18 208.87,441.83 					" />
												<polygon class="stci3" points="223.57,432.67 238.41,441.02 223.71,449.3 208.87,440.95 					" />
												<polygon class="stci2" points="223.71,449.3 223.71,450.18 238.41,441.91 238.41,441.02 					" />
												<polygon class="stci4" points="208.87,440.95 208.87,441.83 223.71,450.18 223.71,449.3 					" />
											</g>
											<g>
												<g>
													<polygon class="stci5" points="214.11,441.01 212.62,441.87 212.62,289.17 214.11,288.31 						" />
													<polygon class="stci6" points="211.13,441.01 212.62,441.87 212.62,288.4 211.13,287.54 						" />
												</g>
											</g>
											<g>
												<g>
													<polygon class="stci5" points="233.27,441.01 234.76,441.87 234.76,288.61 233.27,287.75 						" />
													<polygon class="stci6" points="236.25,441.01 234.76,441.87 234.76,288.61 236.25,287.75 						" />
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M233.55,418.37l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C233.9,418.61,233.76,418.4,233.55,418.37z"/>
														</g>
														<g>
															<path class="stci5" d="M233.71,418.44c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C233.92,418.81,233.89,418.57,233.71,418.44z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,411.25c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,411.67,233.79,411.43,233.68,411.25z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,426.28c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,426.71,233.79,426.47,233.68,426.28z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M233.55,433.4l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C233.9,433.64,233.76,433.44,233.55,433.4z"/>
														</g>
														<g>
															<path class="stci5" d="M233.71,433.47c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C233.92,433.85,233.89,433.6,233.71,433.47z"/>
														</g>
														<g>
															<path class="stci5" d="M224.41,447.12l9.61-5.61c-0.15-0.33-0.26-0.73-0.45-0.62l-9.16,5.32V447.12z" />
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M233.55,388.3l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C233.9,388.55,233.76,388.34,233.55,388.3z"/>
														</g>
														<g>
															<path class="stci5" d="M233.71,388.38c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C233.92,388.75,233.89,388.5,233.71,388.38z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,381.18c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,381.61,233.79,381.37,233.68,381.18z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,396.22c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,396.65,233.79,396.4,233.68,396.22z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M233.55,403.34l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C233.9,403.58,233.76,403.37,233.55,403.34z"/>
														</g>
														<g>
															<path class="stci5" d="M233.71,403.41c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C233.92,403.78,233.89,403.54,233.71,403.41z"/>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M233.55,358.24l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C233.9,358.48,233.76,358.28,233.55,358.24z"/>
														</g>
														<g>
															<path class="stci5" d="M233.71,358.31c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C233.92,358.69,233.89,358.44,233.71,358.31z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,351.12c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,351.55,233.79,351.31,233.68,351.12z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,366.15c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,366.58,233.79,366.34,233.68,366.15z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M233.55,373.27l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C233.9,373.52,233.76,373.31,233.55,373.27z"/>
														</g>
														<g>
															<path class="stci5" d="M233.71,373.35c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C233.92,373.72,233.89,373.47,233.71,373.35z"/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M233.55,328.18l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C233.9,328.42,233.76,328.22,233.55,328.18z"/>
														</g>
														<g>
															<path class="stci5" d="M233.71,328.25c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C233.92,328.63,233.89,328.38,233.71,328.25z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,321.06c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,321.49,233.79,321.25,233.68,321.06z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,336.09c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,336.52,233.79,336.28,233.68,336.09z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M233.55,343.21l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C233.9,343.45,233.76,343.25,233.55,343.21z"/>
														</g>
														<g>
															<path class="stci5" d="M233.71,343.28c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C233.92,343.66,233.89,343.41,233.71,343.28z"/>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M213.43,418.37l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C213.08,418.61,213.22,418.4,213.43,418.37z"/>
														</g>
														<g>
															<path class="stci5" d="M213.27,418.44c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C213.06,418.81,213.09,418.57,213.27,418.44z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,411.25c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,411.67,213.19,411.43,213.3,411.25z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,426.28c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,426.71,213.19,426.47,213.3,426.28z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M213.43,433.4l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C213.08,433.64,213.22,433.44,213.43,433.4z"/>
														</g>
														<g>
															<path class="stci5" d="M213.27,433.47c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C213.06,433.85,213.09,433.6,213.27,433.47z"/>
														</g>
														<g>
															<path class="stci5" d="M222.57,447.12l-9.61-5.61c0.15-0.33,0.26-0.73,0.45-0.62l9.16,5.32V447.12z" />
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M213.43,388.3l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C213.08,388.55,213.22,388.34,213.43,388.3z"/>
														</g>
														<g>
															<path class="stci5" d="M213.27,388.38c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C213.06,388.75,213.09,388.5,213.27,388.38z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,381.18c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,381.61,213.19,381.37,213.3,381.18z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,396.22c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,396.65,213.19,396.4,213.3,396.22z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M213.43,403.34l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C213.08,403.58,213.22,403.37,213.43,403.34z"/>
														</g>
														<g>
															<path class="stci5" d="M213.27,403.41c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C213.06,403.78,213.09,403.54,213.27,403.41z"/>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M213.43,358.24l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C213.08,358.48,213.22,358.28,213.43,358.24z"/>
														</g>
														<g>
															<path class="stci5" d="M213.27,358.31c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C213.06,358.69,213.09,358.44,213.27,358.31z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,351.12c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,351.55,213.19,351.31,213.3,351.12z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,366.15c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,366.58,213.19,366.34,213.3,366.15z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M213.43,373.27l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C213.08,373.52,213.22,373.31,213.43,373.27z"/>
														</g>
														<g>
															<path class="stci5" d="M213.27,373.35c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C213.06,373.72,213.09,373.47,213.27,373.35z"/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M213.43,328.18l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C213.08,328.42,213.22,328.22,213.43,328.18z"/>
														</g>
														<g>
															<path class="stci5" d="M213.27,328.25c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C213.06,328.63,213.09,328.38,213.27,328.25z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,321.06c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,321.49,213.19,321.25,213.3,321.06z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,336.09c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,336.52,213.19,336.28,213.3,336.09z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M233.55,313.2l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C233.9,313.44,233.76,313.24,233.55,313.2z"/>
														</g>
														<g>
															<path class="stci5" d="M233.71,313.27c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C233.92,313.64,233.89,313.4,233.71,313.27z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,306.08c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,306.51,233.79,306.27,233.68,306.08z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,321.11c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,321.54,233.79,321.3,233.68,321.11z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M213.43,313.2l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C213.08,313.44,213.22,313.24,213.43,313.2z"/>
														</g>
														<g>
															<path class="stci5" d="M213.27,313.27c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C213.06,313.64,213.09,313.4,213.27,313.27z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,306.08c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,306.51,213.19,306.27,213.3,306.08z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,321.11c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,321.54,213.19,321.3,213.3,321.11z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M233.55,298.17l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C233.9,298.41,233.76,298.2,233.55,298.17z"/>
														</g>
														<g>
															<path class="stci5" d="M233.71,298.24c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C233.92,298.61,233.89,298.37,233.71,298.24z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,291.04c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,291.47,233.79,291.23,233.68,291.04z"/>
														</g>
														<g>
															<path class="stci5" d="M233.68,306.08c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C233.73,306.51,233.79,306.27,233.68,306.08z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M213.43,298.17l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C213.08,298.41,213.22,298.2,213.43,298.17z"/>
														</g>
														<g>
															<path class="stci5" d="M213.27,298.24c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C213.06,298.61,213.09,298.37,213.27,298.24z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,291.04c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,291.47,213.19,291.23,213.3,291.04z"/>
														</g>
														<g>
															<path class="stci5" d="M213.3,306.08c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C213.25,306.51,213.19,306.27,213.3,306.08z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M213.43,343.21l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C213.08,343.45,213.22,343.25,213.43,343.21z"/>
														</g>
														<g>
															<path class="stci5" d="M213.27,343.28c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C213.06,343.66,213.09,343.41,213.27,343.28z"/>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<polygon class="stci5" points="225.18,447.47 223.69,448.33 223.69,294.14 225.18,293.29 					" />
												<polygon class="stci5" points="223.69,446.63 222.2,447.48 222.2,327.68 223.69,326.82 					" />
												<polygon class="stci6" points="222.2,447.47 223.69,448.33 223.69,294.14 222.2,293.29 					" />
											</g>
										</g>
										<g>
											<polygon class="stci3" points="223.57,278.39 238.41,286.74 223.71,295.02 208.87,286.66 				" />
											<polygon class="stci2" points="223.71,295.02 223.71,295.9 238.41,287.63 238.41,286.74 				" />
											<polygon class="stci4" points="208.87,286.66 208.87,287.55 223.71,295.9 223.71,295.02 				" />
										</g>
									</g>
									<g>
										<g>
											<g>
												<polygon class="stci4" points="208.7,328 211.86,326.26 211.47,325.44 208.7,326.95 					" />
												<path class="stci4" d="M211.96,326.02c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
						C211.83,325.54,211.96,325.8,211.96,326.02z"/>
											</g>
											<g>
												<polygon class="stci4" points="208.7,332.37 211.86,330.63 211.47,329.81 208.7,331.33 					" />
												<path class="stci4" d="M211.96,330.39c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
						C211.83,329.92,211.96,330.18,211.96,330.39z"/>
											</g>
											<g>
												<polygon class="stci9" points="199.56,333.6 199.19,333.95 200.09,334.65 200.43,334.19 					" />
												<polygon class="stci9" points="205.3,325.98 206.22,326.69 205.92,326.92 205.05,326.33 					" />
												<g>
													<path class="stci9" d="M205.35,332.36c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C207.07,327.34,207.05,330.16,205.35,332.36z"/>
													<path class="stci5" d="M206.16,332.98c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C207.88,327.96,207.85,330.78,206.16,332.98z"/>
													<g>
														<path class="stci2" d="M210.54,331.04l-4.82-3.71c-0.56-0.43-1.26-0.58-1.99-0.49l6.3,4.85L210.54,331.04z" />
														<path class="stci2" d="M199.77,330.49l7.03,5.41l0.99-1.28l-7.31-5.62C200.15,329.48,199.91,329.99,199.77,330.49z" />
														<path class="stci2" d="M200.62,333.96l4.82,3.71l0.74-0.96l-6.57-5.06C199.6,332.6,199.93,333.44,200.62,333.96z" />
														<path class="stci2" d="M202.51,327.21c-0.49,0.23-0.96,0.57-1.38,1l7.28,5.6l0.99-1.29L202.51,327.21z" />
														<path class="stci2" d="M199.6,331.66l6.57,5.06l0.63-0.81l-7.03-5.41C199.66,330.89,199.6,331.28,199.6,331.66z" />
														<path class="stci4" d="M199.6,331.66l6.57,5.06l0.63-0.81l-7.03-5.41C199.66,330.89,199.6,331.28,199.6,331.66z" />
														<path class="stci2" d="M210.03,331.7l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L210.03,331.7z" />
														<path class="stci4" d="M210.03,331.7l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L210.03,331.7z" />
														<path class="stci2" d="M200.67,328.73c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C200.97,328.37,200.82,328.54,200.67,328.73z"/>
														<path class="stci4" d="M200.67,328.73c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C200.97,328.37,200.82,328.54,200.67,328.73z"/>
													</g>
													<path class="stci3" d="M210.48,336.28c-1.41,1.83-3.67,2.45-5.05,1.39c-1.38-1.06-1.36-3.41,0.05-5.24
							c1.41-1.83,3.67-2.45,5.05-1.39C211.92,332.1,211.89,334.45,210.48,336.28z"/>
													<g>
														<path class="stci2" d="M210.01,331.7c-0.13-0.1-0.27-0.18-0.41-0.24c0.75,0.94,0.63,2.56-0.37,3.85
								c-0.99,1.29-2.52,1.83-3.62,1.35c0.1,0.12,0.2,0.23,0.32,0.33c1.1,0.85,2.91,0.35,4.03-1.11
								C211.09,334.42,211.11,332.55,210.01,331.7z"/>
														<path class="stci10" d="M209.23,335.31c1-1.3,1.12-2.91,0.37-3.85c-1.1-0.48-2.62,0.06-3.62,1.35c-1,1.3-1.12,2.91-0.37,3.85
								C206.71,337.14,208.24,336.61,209.23,335.31z"/>
													</g>
												</g>
											</g>
											<g>
												<path class="stci9" d="M208.35,328.23v1.15c0.01,0,0.5,0.39,0.71,0.55v-2.11c0-1.82-1.11-2.66-2.47-1.87c0,0-1.66,0.96-2.22,1.28
						c-0.16,0.09-0.64,0.41-0.64,0.93c0,0.09,0,0.38,0,0.38l2.86-1.64C207.56,326.34,208.35,326.93,208.35,328.23z"/>
											</g>
										</g>
										<g>
											<g>
												<polygon class="stci4" points="208.7,341.25 211.86,339.51 211.47,338.69 208.7,340.2 					" />
												<path class="stci4" d="M211.96,339.27c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
						C211.83,338.79,211.96,339.05,211.96,339.27z"/>
											</g>
											<g>
												<polygon class="stci4" points="208.7,345.62 211.86,343.88 211.47,343.06 208.7,344.58 					" />
												<path class="stci4" d="M211.96,343.64c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
						C211.83,343.17,211.96,343.43,211.96,343.64z"/>
											</g>
											<g>
												<polygon class="stci9" points="199.56,346.85 199.19,347.2 200.09,347.9 200.43,347.44 					" />
												<polygon class="stci9" points="205.3,339.23 206.22,339.94 205.92,340.17 205.05,339.59 					" />
												<g>
													<path class="stci9" d="M205.35,345.61c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C207.07,340.6,207.05,343.41,205.35,345.61z"/>
													<path class="stci5" d="M206.16,346.23c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C207.88,341.21,207.85,344.03,206.16,346.23z"/>
													<g>
														<path class="stci2" d="M210.54,344.29l-4.82-3.71c-0.56-0.43-1.26-0.58-1.99-0.49l6.3,4.85L210.54,344.29z" />
														<path class="stci2" d="M199.77,343.74l7.03,5.41l0.99-1.28l-7.31-5.62C200.15,342.73,199.91,343.24,199.77,343.74z" />
														<path class="stci2" d="M200.62,347.21l4.82,3.71l0.74-0.96l-6.57-5.06C199.6,345.85,199.93,346.69,200.62,347.21z" />
														<path class="stci2" d="M202.51,340.46c-0.49,0.23-0.96,0.57-1.38,1l7.28,5.6l0.99-1.29L202.51,340.46z" />
														<path class="stci2" d="M199.6,344.91l6.57,5.06l0.63-0.81l-7.03-5.41C199.66,344.14,199.6,344.53,199.6,344.91z" />
														<path class="stci4" d="M199.6,344.91l6.57,5.06l0.63-0.81l-7.03-5.41C199.66,344.14,199.6,344.53,199.6,344.91z" />
														<path class="stci2" d="M210.03,344.95l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L210.03,344.95z" />
														<path class="stci4" d="M210.03,344.95l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L210.03,344.95z" />
														<path class="stci2" d="M200.67,341.98c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C200.97,341.62,200.82,341.79,200.67,341.98z"/>
														<path class="stci4" d="M200.67,341.98c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C200.97,341.62,200.82,341.79,200.67,341.98z"/>
													</g>
													<path class="stci3" d="M210.48,349.53c-1.41,1.83-3.67,2.45-5.05,1.39c-1.38-1.06-1.36-3.41,0.05-5.24
							c1.41-1.83,3.67-2.45,5.05-1.39C211.92,345.35,211.89,347.7,210.48,349.53z"/>
													<g>
														<path class="stci2" d="M210.01,344.95c-0.13-0.1-0.27-0.18-0.41-0.24c0.75,0.94,0.63,2.56-0.37,3.85
								c-0.99,1.29-2.52,1.83-3.62,1.35c0.1,0.12,0.2,0.23,0.32,0.33c1.1,0.85,2.91,0.35,4.03-1.11
								C211.09,347.67,211.11,345.8,210.01,344.95z"/>
														<path class="stci10" d="M209.23,348.56c1-1.3,1.12-2.91,0.37-3.85c-1.1-0.48-2.62,0.06-3.62,1.35c-1,1.3-1.12,2.91-0.37,3.85
								C206.71,350.39,208.24,349.86,209.23,348.56z"/>
													</g>
												</g>
											</g>
											<g>
												<path class="stci9" d="M208.35,341.48v1.15c0.01,0,0.5,0.39,0.71,0.55v-2.11c0-1.82-1.11-2.66-2.47-1.87c0,0-1.66,0.96-2.22,1.28
						c-0.16,0.09-0.64,0.41-0.64,0.93c0,0.09,0,0.38,0,0.38l2.86-1.64C207.56,339.59,208.35,340.18,208.35,341.48z"/>
											</g>
										</g>
										<g>
											<g>
												<polygon class="stci4" points="208.7,354.5 211.86,352.76 211.47,351.94 208.7,353.45 					" />
												<path class="stci4" d="M211.96,352.52c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57s0.13-0.32,0.3-0.22
						C211.83,352.04,211.96,352.3,211.96,352.52z"/>
											</g>
											<g>
												<polygon class="stci4" points="208.7,358.87 211.86,357.13 211.47,356.31 208.7,357.83 					" />
												<path class="stci4" d="M211.96,356.9c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
						C211.83,356.42,211.96,356.68,211.96,356.9z"/>
											</g>
											<g>
												<polygon class="stci9" points="199.56,360.1 199.19,360.45 200.09,361.15 200.43,360.69 					" />
												<polygon class="stci9" points="205.3,352.48 206.22,353.19 205.92,353.42 205.05,352.84 					" />
												<g>
													<path class="stci9" d="M205.35,358.86c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C207.07,353.85,207.05,356.66,205.35,358.86z"/>
													<path class="stci5" d="M206.16,359.48c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C207.88,354.46,207.85,357.28,206.16,359.48z"/>
													<g>
														<path class="stci2" d="M210.54,357.54l-4.82-3.71c-0.56-0.43-1.26-0.58-1.99-0.49l6.3,4.85L210.54,357.54z" />
														<path class="stci2" d="M199.77,356.99l7.03,5.41l0.99-1.28l-7.31-5.62C200.15,355.98,199.91,356.49,199.77,356.99z" />
														<path class="stci2" d="M200.62,360.46l4.82,3.71l0.74-0.96l-6.57-5.06C199.6,359.1,199.93,359.94,200.62,360.46z" />
														<path class="stci2" d="M202.51,353.71c-0.49,0.23-0.96,0.57-1.38,1l7.28,5.6l0.99-1.29L202.51,353.71z" />
														<path class="stci2" d="M199.6,358.16l6.57,5.06l0.63-0.81l-7.03-5.41C199.66,357.39,199.6,357.78,199.6,358.16z" />
														<path class="stci4" d="M199.6,358.16l6.57,5.06l0.63-0.81l-7.03-5.41C199.66,357.39,199.6,357.78,199.6,358.16z" />
														<path class="stci2" d="M210.03,358.2l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.3L210.03,358.2z" />
														<path class="stci4" d="M210.03,358.2l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.3L210.03,358.2z" />
														<path class="stci2" d="M200.67,355.23c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C200.97,354.87,200.82,355.04,200.67,355.23z"/>
														<path class="stci4" d="M200.67,355.23c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C200.97,354.87,200.82,355.04,200.67,355.23z"/>
													</g>
													<path class="stci3" d="M210.48,362.78c-1.41,1.83-3.67,2.45-5.05,1.39c-1.38-1.06-1.36-3.41,0.05-5.24
							c1.41-1.83,3.67-2.45,5.05-1.39C211.92,358.6,211.89,360.95,210.48,362.78z"/>
													<g>
														<path class="stci2" d="M210.01,358.2c-0.13-0.1-0.27-0.18-0.41-0.24c0.75,0.94,0.63,2.56-0.37,3.85
								c-0.99,1.29-2.52,1.83-3.62,1.35c0.1,0.12,0.2,0.23,0.32,0.33c1.1,0.85,2.91,0.35,4.03-1.11
								C211.09,360.92,211.11,359.05,210.01,358.2z"/>
														<path class="stci10" d="M209.23,361.82c1-1.3,1.12-2.91,0.37-3.85c-1.1-0.48-2.62,0.06-3.62,1.35c-1,1.3-1.12,2.91-0.37,3.85
								C206.71,363.64,208.24,363.11,209.23,361.82z"/>
													</g>
												</g>
											</g>
											<g>
												<path class="stci9" d="M208.35,354.73v1.15c0.01,0,0.5,0.39,0.71,0.55v-2.11c0-1.82-1.11-2.66-2.47-1.87c0,0-1.66,0.96-2.22,1.28
						c-0.16,0.09-0.64,0.41-0.64,0.93c0,0.09,0,0.38,0,0.38l2.86-1.64C207.56,352.84,208.35,353.43,208.35,354.73z"/>
											</g>
										</g>
										<g>
											<g>
												<polygon class="stci4" points="208.7,367.75 211.86,366.01 211.47,365.19 208.7,366.7 					" />
												<path class="stci4" d="M211.96,365.77c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
						C211.83,365.3,211.96,365.55,211.96,365.77z"/>
											</g>
											<g>
												<polygon class="stci4" points="208.7,372.13 211.86,370.38 211.47,369.56 208.7,371.08 					" />
												<path class="stci4" d="M211.96,370.15c0,0.22-0.13,0.32-0.3,0.22c-0.16-0.09-0.3-0.35-0.3-0.57c0-0.22,0.13-0.32,0.3-0.22
						C211.83,369.67,211.96,369.93,211.96,370.15z"/>
											</g>
											<g>
												<polygon class="stci9" points="199.56,373.35 199.19,373.7 200.09,374.4 200.43,373.94 					" />
												<polygon class="stci9" points="205.3,365.73 206.22,366.44 205.92,366.67 205.05,366.09 					" />
												<g>
													<path class="stci9" d="M205.35,372.11c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C207.07,367.1,207.05,369.91,205.35,372.11z"/>
													<path class="stci5" d="M206.16,372.73c-1.69,2.2-4.41,2.95-6.06,1.67c-1.66-1.28-1.63-4.09,0.06-6.29
							c1.69-2.2,4.41-2.95,6.06-1.67C207.88,367.71,207.85,370.53,206.16,372.73z"/>
													<g>
														<path class="stci2" d="M210.54,370.79l-4.82-3.71c-0.56-0.43-1.26-0.58-1.99-0.49l6.3,4.85L210.54,370.79z" />
														<path class="stci2" d="M199.77,370.24l7.03,5.41l0.99-1.28l-7.31-5.62C200.15,369.23,199.91,369.74,199.77,370.24z" />
														<path class="stci2" d="M200.62,373.71l4.82,3.71l0.74-0.96l-6.57-5.06C199.6,372.35,199.93,373.19,200.62,373.71z" />
														<path class="stci2" d="M202.51,366.96c-0.49,0.23-0.96,0.57-1.38,1l7.28,5.6l0.99-1.29L202.51,366.96z" />
														<path class="stci2" d="M199.6,371.41l6.57,5.06l0.63-0.81l-7.03-5.41C199.66,370.64,199.6,371.03,199.6,371.41z" />
														<path class="stci4" d="M199.6,371.41l6.57,5.06l0.63-0.81l-7.03-5.41C199.66,370.64,199.6,371.03,199.6,371.41z" />
														<path class="stci2" d="M210.03,371.45l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L210.03,371.45z" />
														<path class="stci4" d="M210.03,371.45l-6.3-4.85c-0.4,0.05-0.81,0.17-1.22,0.36l6.89,5.31L210.03,371.45z" />
														<path class="stci2" d="M200.67,368.48c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C200.97,368.12,200.82,368.29,200.67,368.48z"/>
														<path class="stci4" d="M200.67,368.48c-0.07,0.09-0.13,0.18-0.19,0.27l7.31,5.62l0.63-0.81l-7.28-5.6
								C200.97,368.12,200.82,368.29,200.67,368.48z"/>
													</g>
													<path class="stci3" d="M210.48,376.03c-1.41,1.83-3.67,2.45-5.05,1.39c-1.38-1.06-1.36-3.41,0.05-5.24
							c1.41-1.83,3.67-2.45,5.05-1.39C211.92,371.85,211.89,374.2,210.48,376.03z"/>
													<g>
														<path class="stci2" d="M210.01,371.45c-0.13-0.1-0.27-0.18-0.41-0.24c0.75,0.94,0.63,2.56-0.37,3.85
								c-0.99,1.29-2.52,1.83-3.62,1.35c0.1,0.12,0.2,0.23,0.32,0.33c1.1,0.85,2.91,0.35,4.03-1.11
								C211.09,374.17,211.11,372.3,210.01,371.45z"/>
														<path class="stci10" d="M209.23,375.07c1-1.3,1.12-2.91,0.37-3.85c-1.1-0.48-2.62,0.06-3.62,1.35c-1,1.3-1.12,2.91-0.37,3.85
								C206.71,376.89,208.24,376.36,209.23,375.07z"/>
													</g>
												</g>
											</g>
											<g>
												<path class="stci9" d="M208.35,367.98v1.15c0.01,0,0.5,0.39,0.71,0.55v-2.11c0-1.82-1.11-2.66-2.47-1.87c0,0-1.66,0.96-2.22,1.28
						c-0.16,0.09-0.64,0.41-0.64,0.93c0,0.09,0,0.38,0,0.38l2.86-1.64C207.56,366.09,208.35,366.68,208.35,367.98z"/>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<polygon class="stci2" points="243.69,464.19 258.54,472.54 243.84,480.82 228.99,472.46 						" />
													<polygon class="stci3" points="243.69,463.3 258.54,471.66 243.84,479.93 228.99,471.58 						" />
													<polygon class="stci2" points="243.84,479.93 243.84,480.82 258.54,472.54 258.54,471.66 						" />
													<polygon class="stci4" points="228.99,471.58 228.99,472.46 243.84,480.82 243.84,479.93 						" />
												</g>
												<g>
													<g>
														<polygon class="stci5" points="234.23,471.64 232.74,472.5 232.74,319.8 234.23,318.95 							" />
														<polygon class="stci6" points="231.26,471.64 232.74,472.5 232.74,319.03 231.26,318.17 							" />
													</g>
												</g>
												<g>
													<g>
														<polygon class="stci5" points="253.4,471.64 254.88,472.5 254.88,319.24 253.4,318.38 							" />
														<polygon class="stci6" points="256.37,471.64 254.88,472.5 254.88,319.24 256.37,318.38 							" />
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M253.67,449l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C254.03,449.24,253.88,449.04,253.67,449z"/>
															</g>
															<g>
																<path class="stci5" d="M253.84,449.07c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C254.05,449.44,254.01,449.2,253.84,449.07z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,441.88c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,442.31,253.92,442.07,253.81,441.88z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,456.91c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,457.34,253.92,457.1,253.81,456.91z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M253.67,464.03l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C254.03,464.27,253.88,464.07,253.67,464.03z"/>
															</g>
															<g>
																<path class="stci5" d="M253.84,464.1c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C254.05,464.48,254.01,464.23,253.84,464.1z"/>
															</g>
															<g>
																<path class="stci5" d="M244.53,477.75l9.61-5.61c-0.15-0.33-0.26-0.73-0.45-0.62l-9.16,5.32V477.75z" />
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M253.67,418.94l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C254.03,419.18,253.88,418.97,253.67,418.94z"/>
															</g>
															<g>
																<path class="stci5" d="M253.84,419.01c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C254.05,419.38,254.01,419.14,253.84,419.01z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,411.81c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,412.24,253.92,412,253.81,411.81z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,426.85c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,427.28,253.92,427.04,253.81,426.85z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M253.67,433.97l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C254.03,434.21,253.88,434.01,253.67,433.97z"/>
															</g>
															<g>
																<path class="stci5" d="M253.84,434.04c-0.18-0.13-0.42-0.09-0.55,0.08L244.53,446v1.33l9.38-12.73
										C254.05,434.42,254.01,434.17,253.84,434.04z"/>
															</g>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M253.67,388.87l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C254.03,389.12,253.88,388.91,253.67,388.87z"/>
															</g>
															<g>
																<path class="stci5" d="M253.84,388.94c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C254.05,389.32,254.01,389.07,253.84,388.94z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,381.75c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,382.18,253.92,381.94,253.81,381.75z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,396.79c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,397.21,253.92,396.97,253.81,396.79z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M253.67,403.91l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C254.03,404.15,253.88,403.94,253.67,403.91z"/>
															</g>
															<g>
																<path class="stci5" d="M253.84,403.98c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C254.05,404.35,254.01,404.11,253.84,403.98z"/>
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M253.67,358.81l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C254.03,359.05,253.88,358.85,253.67,358.81z"/>
															</g>
															<g>
																<path class="stci5" d="M253.84,358.88c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C254.05,359.26,254.01,359.01,253.84,358.88z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,351.69c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,352.12,253.92,351.88,253.81,351.69z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,366.72c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,367.15,253.92,366.91,253.81,366.72z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M253.67,373.84l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C254.03,374.09,253.88,373.88,253.67,373.84z"/>
															</g>
															<g>
																<path class="stci5" d="M253.84,373.91c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C254.05,374.29,254.01,374.04,253.84,373.91z"/>
															</g>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M233.56,449l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C233.2,449.24,233.34,449.04,233.56,449z"/>
															</g>
															<g>
																<path class="stci5" d="M233.39,449.07c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C233.18,449.44,233.22,449.2,233.39,449.07z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,441.88c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,442.31,233.31,442.07,233.42,441.88z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,456.91c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,457.34,233.31,457.1,233.42,456.91z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M233.56,464.03l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C233.2,464.27,233.34,464.07,233.56,464.03z"/>
															</g>
															<g>
																<path class="stci5" d="M233.39,464.1c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C233.18,464.48,233.22,464.23,233.39,464.1z"/>
															</g>
															<g>
																<path class="stci5" d="M242.69,477.75l-9.61-5.61c0.15-0.33,0.26-0.73,0.45-0.62l9.16,5.32V477.75z" />
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M233.56,418.94l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C233.2,419.18,233.34,418.97,233.56,418.94z"/>
															</g>
															<g>
																<path class="stci5" d="M233.39,419.01c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C233.18,419.38,233.22,419.14,233.39,419.01z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,411.81c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,412.24,233.31,412,233.42,411.81z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,426.85c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,427.28,233.31,427.04,233.42,426.85z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M233.56,433.97l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C233.2,434.21,233.34,434.01,233.56,433.97z"/>
															</g>
															<g>
																<path class="stci5" d="M233.39,434.04c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C233.18,434.42,233.22,434.17,233.39,434.04z"/>
															</g>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M233.56,388.87l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C233.2,389.12,233.34,388.91,233.56,388.87z"/>
															</g>
															<g>
																<path class="stci5" d="M233.39,388.94c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C233.18,389.32,233.22,389.07,233.39,388.94z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,381.75c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,382.18,233.31,381.94,233.42,381.75z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,396.79c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,397.21,233.31,396.97,233.42,396.79z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M233.56,403.91l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C233.2,404.15,233.34,403.94,233.56,403.91z"/>
															</g>
															<g>
																<path class="stci5" d="M233.39,403.98c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C233.18,404.35,233.22,404.11,233.39,403.98z"/>
															</g>
														</g>
													</g>
													<g>
														<g>
															<g>
																<path class="stci5" d="M233.56,358.81l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C233.2,359.05,233.34,358.85,233.56,358.81z"/>
															</g>
															<g>
																<path class="stci5" d="M233.39,358.88c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C233.18,359.26,233.22,359.01,233.39,358.88z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,351.69c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,352.12,233.31,351.88,233.42,351.69z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,366.72c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,367.15,233.31,366.91,233.42,366.72z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M253.67,343.83l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C254.03,344.07,253.88,343.87,253.67,343.83z"/>
															</g>
															<g>
																<path class="stci5" d="M253.84,343.9c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C254.05,344.28,254.01,344.03,253.84,343.9z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,336.71c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,337.14,253.92,336.9,253.81,336.71z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,351.74c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,352.17,253.92,351.93,253.81,351.74z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M233.56,343.83l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C233.2,344.07,233.34,343.87,233.56,343.83z"/>
															</g>
															<g>
																<path class="stci5" d="M233.39,343.9c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C233.18,344.28,233.22,344.03,233.39,343.9z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,336.71c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,337.14,233.31,336.9,233.42,336.71z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,351.74c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,352.17,233.31,351.93,233.42,351.74z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M253.67,328.8l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
										C254.03,329.04,253.88,328.83,253.67,328.8z"/>
															</g>
															<g>
																<path class="stci5" d="M253.84,328.87c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
										C254.05,329.24,254.01,329,253.84,328.87z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,321.68c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,322.11,253.92,321.86,253.81,321.68z"/>
															</g>
															<g>
																<path class="stci5" d="M253.81,336.71c-0.11-0.19-0.35-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
										C253.85,337.14,253.92,336.9,253.81,336.71z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M233.56,328.8l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C233.2,329.04,233.34,328.83,233.56,328.8z"/>
															</g>
															<g>
																<path class="stci5" d="M233.39,328.87c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C233.18,329.24,233.22,329,233.39,328.87z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,321.68c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,322.11,233.31,321.86,233.42,321.68z"/>
															</g>
															<g>
																<path class="stci5" d="M233.42,336.71c0.11-0.19,0.35-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
										C233.38,337.14,233.31,336.9,233.42,336.71z"/>
															</g>
														</g>
														<g>
															<g>
																<path class="stci5" d="M233.56,373.84l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
										C233.2,374.09,233.34,373.88,233.56,373.84z"/>
															</g>
															<g>
																<path class="stci5" d="M233.39,373.91c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
										C233.18,374.29,233.22,374.04,233.39,373.91z"/>
															</g>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<polygon class="stci5" points="245.3,478.1 243.81,478.96 243.81,324.78 245.3,323.92 						" />
													<polygon class="stci5" points="243.81,477.26 242.33,478.12 242.33,358.31 243.81,357.45 						" />
													<polygon class="stci6" points="242.33,478.1 243.81,478.96 243.81,324.78 242.33,323.92 						" />
												</g>
											</g>
											<g>
												<polygon class="stci3" points="243.69,309.02 258.54,317.37 243.84,325.65 228.99,317.29 					" />
												<polygon class="stci2" points="243.84,325.65 243.84,326.53 258.54,318.26 258.54,317.37 					" />
												<polygon class="stci4" points="228.99,317.29 228.99,318.18 243.84,326.53 243.84,325.65 					" />
											</g>
										</g>
									</g>
									<g>
										<polygon class="stci3" points="182.04,474.62 230.25,446.79 230.25,348.29 182.04,376.12 			" />
										<polygon class="stci7" points="188.21,478.31 236.41,450.48 236.41,351.98 188.21,379.81 			" />
										<path class="stci8" d="M200.28,412.95c4.48-0.31,8.93-0.91,13.35-1.69c2.6-0.46,5.18-1.25,7.7-2.03c2.63-0.82,5.22-1.74,7.77-2.78
				c2.37-0.97,4.83-1.98,6.99-3.38c0.11-0.07,0.22-0.15,0.33-0.22v28.54c-3.79-0.75-7.64-1.18-11.49-1.45
				c-5.09-0.36-10.2-0.4-15.3-0.42c-3.76-0.01-7.48,0.06-11.23,0.41c-3.41,0.32-6.81,0.8-10.19,1.4v-18.42
				C192.22,413.26,196.26,413.22,200.28,412.95z"/>
										<path class="stci8" d="M226.56,379.91c-4.5,2.64-9.31,4.76-14.18,6.6c-5.07,1.92-10.25,3.53-15.48,4.95
				c-2.6,0.7-5.21,1.38-7.86,1.89c-0.28,0.05-0.55,0.1-0.83,0.15v-13.7l48.2-27.83v21.65C233.25,375.92,229.92,377.95,226.56,379.91
				z"/>
										<polygon class="stci2" points="182.04,474.62 188.21,478.31 188.21,379.81 182.04,376.12 			" />
										<polygon class="stci4" points="230.25,348.29 236.41,351.98 188.21,379.81 182.04,376.12 			" />
									</g>
									<g>
										<g>
											<g>
												<polygon class="stci2" points="180.59,501.23 195.43,509.58 180.73,517.86 165.88,509.5 					" />
												<polygon class="stci3" points="180.59,500.34 195.43,508.7 180.73,516.97 165.88,508.62 					" />
												<polygon class="stci2" points="180.73,516.97 180.73,517.86 195.43,509.58 195.43,508.7 					" />
												<polygon class="stci4" points="165.88,508.62 165.88,509.5 180.73,517.86 180.73,516.97 					" />
											</g>
											<g>
												<g>
													<polygon class="stci5" points="171.12,508.69 169.64,509.54 169.64,356.85 171.12,355.99 						" />
													<polygon class="stci6" points="168.15,508.69 169.64,509.54 169.64,356.07 168.15,355.21 						" />
												</g>
											</g>
											<g>
												<g>
													<polygon class="stci5" points="190.29,508.69 191.78,509.54 191.78,356.28 190.29,355.43 						" />
													<polygon class="stci6" points="193.26,508.69 191.78,509.54 191.78,356.28 193.26,355.43 						" />
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M190.56,486.04l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C190.92,486.28,190.78,486.08,190.56,486.04z"/>
														</g>
														<g>
															<path class="stci5" d="M190.73,486.11c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C190.94,486.49,190.9,486.24,190.73,486.11z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,478.92c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,479.35,190.81,479.11,190.7,478.92z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,493.95c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,494.38,190.81,494.14,190.7,493.95z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M190.56,501.07l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C190.92,501.32,190.78,501.11,190.56,501.07z"/>
														</g>
														<g>
															<path class="stci5" d="M190.73,501.14c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C190.94,501.52,190.9,501.27,190.73,501.14z"/>
														</g>
														<g>
															<path class="stci5" d="M181.43,514.79l9.61-5.61c-0.15-0.33-0.26-0.73-0.45-0.62l-9.16,5.32V514.79z" />
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M190.56,455.98l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C190.92,456.22,190.78,456.02,190.56,455.98z"/>
														</g>
														<g>
															<path class="stci5" d="M190.73,456.05c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C190.94,456.42,190.9,456.18,190.73,456.05z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,448.86c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,449.29,190.81,449.05,190.7,448.86z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,463.89c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,464.32,190.81,464.08,190.7,463.89z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M190.56,471.01l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C190.92,471.25,190.78,471.05,190.56,471.01z"/>
														</g>
														<g>
															<path class="stci5" d="M190.73,471.08c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C190.94,471.46,190.9,471.21,190.73,471.08z"/>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M190.56,425.92l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C190.92,426.16,190.78,425.95,190.56,425.92z"/>
														</g>
														<g>
															<path class="stci5" d="M190.73,425.99c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C190.94,426.36,190.9,426.12,190.73,425.99z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,418.79c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,419.22,190.81,418.98,190.7,418.79z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,433.83c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,434.26,190.81,434.02,190.7,433.83z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M190.56,440.95l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C190.92,441.19,190.78,440.99,190.56,440.95z"/>
														</g>
														<g>
															<path class="stci5" d="M190.73,441.02c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C190.94,441.4,190.9,441.15,190.73,441.02z"/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M190.56,395.85l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C190.92,396.1,190.78,395.89,190.56,395.85z"/>
														</g>
														<g>
															<path class="stci5" d="M190.73,395.92c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C190.94,396.3,190.9,396.05,190.73,395.92z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,388.73c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,389.16,190.81,388.92,190.7,388.73z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,403.77c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,404.19,190.81,403.95,190.7,403.77z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M190.56,410.89l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C190.92,411.13,190.78,410.92,190.56,410.89z"/>
														</g>
														<g>
															<path class="stci5" d="M190.73,410.96c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C190.94,411.33,190.9,411.09,190.73,410.96z"/>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M170.45,486.04l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C170.09,486.28,170.24,486.08,170.45,486.04z"/>
														</g>
														<g>
															<path class="stci5" d="M170.28,486.11c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C170.07,486.49,170.11,486.24,170.28,486.11z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,478.92c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,479.35,170.2,479.11,170.31,478.92z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,493.95c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,494.38,170.2,494.14,170.31,493.95z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M170.45,501.07l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C170.09,501.32,170.24,501.11,170.45,501.07z"/>
														</g>
														<g>
															<path class="stci5" d="M170.28,501.14c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C170.07,501.52,170.11,501.27,170.28,501.14z"/>
														</g>
														<g>
															<path class="stci5" d="M179.59,514.79l-9.61-5.61c0.15-0.33,0.26-0.73,0.45-0.62l9.16,5.32V514.79z" />
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M170.45,455.98l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C170.09,456.22,170.24,456.02,170.45,455.98z"/>
														</g>
														<g>
															<path class="stci5" d="M170.28,456.05c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C170.07,456.42,170.11,456.18,170.28,456.05z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,448.86c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,449.29,170.2,449.05,170.31,448.86z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,463.89c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,464.32,170.2,464.08,170.31,463.89z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M170.45,471.01l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C170.09,471.25,170.24,471.05,170.45,471.01z"/>
														</g>
														<g>
															<path class="stci5" d="M170.28,471.08c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C170.07,471.46,170.11,471.21,170.28,471.08z"/>
														</g>
													</g>
												</g>
											</g>
											<g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M170.45,425.92l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C170.09,426.16,170.24,425.95,170.45,425.92z"/>
														</g>
														<g>
															<path class="stci5" d="M170.28,425.99c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C170.07,426.36,170.11,426.12,170.28,425.99z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,418.79c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,419.22,170.2,418.98,170.31,418.79z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,433.83c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,434.26,170.2,434.02,170.31,433.83z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M170.45,440.95l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C170.09,441.19,170.24,440.99,170.45,440.95z"/>
														</g>
														<g>
															<path class="stci5" d="M170.28,441.02c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C170.07,441.4,170.11,441.15,170.28,441.02z"/>
														</g>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path class="stci5" d="M170.45,395.85l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C170.09,396.1,170.24,395.89,170.45,395.85z"/>
														</g>
														<g>
															<path class="stci5" d="M170.28,395.92c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C170.07,396.3,170.11,396.05,170.28,395.92z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,388.73c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,389.16,170.2,388.92,170.31,388.73z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,403.77c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,404.19,170.2,403.95,170.31,403.77z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M190.56,380.87l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C190.92,381.11,190.78,380.91,190.56,380.87z"/>
														</g>
														<g>
															<path class="stci5" d="M190.73,380.94c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C190.94,381.32,190.9,381.07,190.73,380.94z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,373.75c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,374.18,190.81,373.94,190.7,373.75z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,388.78c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,389.21,190.81,388.97,190.7,388.78z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M170.45,380.87l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C170.09,381.11,170.24,380.91,170.45,380.87z"/>
														</g>
														<g>
															<path class="stci5" d="M170.28,380.94c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C170.07,381.32,170.11,381.07,170.28,380.94z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,373.75c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,374.18,170.2,373.94,170.31,373.75z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,388.78c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,389.21,170.2,388.97,170.31,388.78z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M190.56,365.84l-9.14-1.61v0.8l9,1.59c0.02,0,0.05,0.01,0.07,0.01c0.19,0,0.35-0.13,0.39-0.33
									C190.92,366.08,190.78,365.88,190.56,365.84z"/>
														</g>
														<g>
															<path class="stci5" d="M190.73,365.91c-0.18-0.13-0.42-0.09-0.55,0.08l-8.75,11.87v1.33l9.38-12.73
									C190.94,366.29,190.9,366.04,190.73,365.91z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,358.72c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,359.15,190.81,358.91,190.7,358.72z"/>
														</g>
														<g>
															<path class="stci5" d="M190.7,373.75c-0.11-0.19-0.36-0.25-0.54-0.14l-8.73,5.04v0.91l9.13-5.27
									C190.74,374.18,190.81,373.94,190.7,373.75z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M170.45,365.84l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C170.09,366.08,170.24,365.88,170.45,365.84z"/>
														</g>
														<g>
															<path class="stci5" d="M170.28,365.91c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C170.07,366.29,170.11,366.04,170.28,365.91z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,358.72c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,359.15,170.2,358.91,170.31,358.72z"/>
														</g>
														<g>
															<path class="stci5" d="M170.31,373.75c0.11-0.19,0.36-0.25,0.54-0.14l8.73,5.04v0.91l-9.13-5.27
									C170.27,374.18,170.2,373.94,170.31,373.75z"/>
														</g>
													</g>
													<g>
														<g>
															<path class="stci5" d="M170.45,410.89l9.14-1.61v0.8l-9,1.59c-0.02,0-0.05,0.01-0.07,0.01c-0.19,0-0.35-0.13-0.39-0.33
									C170.09,411.13,170.24,410.92,170.45,410.89z"/>
														</g>
														<g>
															<path class="stci5" d="M170.28,410.96c0.18-0.13,0.42-0.09,0.55,0.08l8.75,11.87v1.33l-9.38-12.73
									C170.07,411.33,170.11,411.09,170.28,410.96z"/>
														</g>
													</g>
												</g>
											</g>
										</g>
										<g>
											<g>
												<polygon class="stci5" points="182.19,515.15 180.71,516 180.71,361.82 182.19,360.96 					" />
												<polygon class="stci5" points="180.71,514.3 179.22,515.16 179.22,395.35 180.71,394.49 					" />
												<polygon class="stci6" points="179.22,515.15 180.71,516 180.71,361.82 179.22,360.96 					" />
											</g>
										</g>
										<g>
											<polygon class="stci3" points="180.59,346.06 195.43,354.41 180.73,362.69 165.88,354.34 				" />
											<polygon class="stci2" points="180.73,362.69 180.73,363.57 195.43,355.3 195.43,354.41 				" />
											<polygon class="stci4" points="165.88,354.34 165.88,355.22 180.73,363.57 180.73,362.69 				" />
										</g>
									</g>
								</g>
								<g>
									<g>
										<polygon class="stci5" points="279.82,440.67 294.6,432.15 294.6,474.16 279.82,482.69 			" />
										<polygon class="stci4" points="294.13,433.05 280.29,441.03 280.37,481.84 294.13,473.9 			" />
										<polygon class="stci5" points="279.82,460.75 294.6,452.23 294.6,452.84 279.82,461.37 			" />
										<polygon class="stci2" points="279.82,440.67 268.76,434.45 268.76,476.46 279.82,482.69 			" />
										<polygon class="stci11" points="272.34,437.36 269.47,435.74 269.47,441.78 272.34,443.39 			" />
										<polygon class="stci11" points="283.54,425.92 294.6,432.15 279.82,440.67 268.76,434.45 			" />
									</g>
									<g>
										<path class="stci5" d="M292.72,443.3c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
				C290.2,437.26,292.72,439.16,292.72,443.3z"/>
										<path class="stci6" d="M292.35,443.44c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
				C290.02,437.83,292.35,439.6,292.35,443.44z"/>
										<path class="stci2" d="M289.94,444.34c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
				C288.43,440.72,289.94,441.86,289.94,444.34z"/>
										<path class="stci2" d="M289.67,444.37c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
				C288.27,441.01,289.67,442.07,289.67,444.37z"/>
										<path class="stci6" d="M288.18,445.23c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
				C287.45,443.48,288.18,444.03,288.18,445.23z"/>
									</g>
									<g>
										<path class="stci5" d="M292.72,464.15c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
				C290.2,458.11,292.72,460.01,292.72,464.15z"/>
										<path class="stci6" d="M292.35,464.29c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
				C290.02,458.68,292.35,460.44,292.35,464.29z"/>
										<path class="stci2" d="M289.94,465.18c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
				C288.43,461.57,289.94,462.7,289.94,465.18z"/>
										<path class="stci2" d="M289.67,465.22c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
				C288.27,461.86,289.67,462.92,289.67,465.22z"/>
										<path class="stci6" d="M288.18,466.08c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
				C287.45,464.33,288.18,464.88,288.18,466.08z"/>
									</g>
								</g>
								<g>
									<g>
										<polygon class="stci5" points="263.5,450.18 278.28,441.65 278.28,483.67 263.5,492.19 			" />
										<polygon class="stci4" points="277.81,442.56 263.96,450.54 264.05,491.35 277.81,483.41 			" />
										<polygon class="stci5" points="263.5,470.26 278.28,461.74 278.28,462.35 263.5,470.88 			" />
										<polygon class="stci2" points="263.5,450.18 252.43,443.95 252.43,485.97 263.5,492.19 			" />
										<polygon class="stci11" points="256.01,446.87 253.14,445.25 253.14,451.28 256.01,452.9 			" />
										<polygon class="stci11" points="267.21,435.43 278.28,441.65 263.5,450.18 252.43,443.95 			" />
									</g>
									<g>
										<path class="stci5" d="M276.39,452.81c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
				C273.88,446.77,276.39,448.67,276.39,452.81z"/>
										<path class="stci6" d="M276.03,452.95c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
				C273.69,447.34,276.03,449.1,276.03,452.95z"/>
										<path class="stci2" d="M273.62,453.84c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
				C272.11,450.23,273.62,451.37,273.62,453.84z"/>
										<path class="stci2" d="M273.34,453.88c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
				C271.95,450.52,273.34,451.58,273.34,453.88z"/>
										<path class="stci6" d="M271.85,454.74c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
				C271.12,452.99,271.85,453.54,271.85,454.74z"/>
									</g>
									<g>
										<path class="stci5" d="M276.39,473.65c0,4.14-2.52,8.94-5.62,10.73c-3.1,1.79-5.62-0.11-5.62-4.25c0-4.14,2.52-8.94,5.62-10.73
				C273.88,467.62,276.39,469.52,276.39,473.65z"/>
										<path class="stci6" d="M276.03,473.79c0,3.84-2.34,8.31-5.22,9.97c-2.88,1.66-5.22-0.1-5.22-3.94c0-3.84,2.34-8.31,5.22-9.97
				C273.69,468.18,276.03,469.95,276.03,473.79z"/>
										<path class="stci2" d="M273.62,474.69c0,2.48-1.51,5.36-3.37,6.43c-1.86,1.07-3.37-0.07-3.37-2.54c0-2.48,1.51-5.36,3.37-6.43
				C272.11,471.07,273.62,472.21,273.62,474.69z"/>
										<path class="stci2" d="M273.34,474.73c0,2.3-1.4,4.97-3.12,5.97c-1.73,1-3.12-0.06-3.12-2.36c0-2.3,1.4-4.97,3.12-5.97
				C271.95,471.37,273.34,472.43,273.34,474.73z"/>
										<path class="stci6" d="M271.85,475.59c0,1.2-0.73,2.6-1.63,3.12c-0.9,0.52-1.63-0.03-1.63-1.23c0-1.2,0.73-2.6,1.63-3.12
				C271.12,473.83,271.85,474.38,271.85,475.59z"/>
									</g>
								</g>
							</g>
							<path class="stci13" d="M462.86,659.12c-5.85-0.01-10.97-2.36-15.97-5.02c-3.59-1.91-7.09-3.96-10.66-5.9
	c-5.57-3.05-11.17-6.07-16.75-9.09c-4.43-2.4-8.85-4.79-13.29-7.17c-8.83-4.73-17.68-9.44-26.5-14.19
	c-8.66-4.66-17.3-9.35-25.93-14.05c-4.48-2.44-8.92-4.95-13.41-7.38c-4.64-2.51-9.37-4.85-13.94-7.46c-3.14-1.79-6.45-3.28-9.4-5.44
	c-1.51-1.1-2.69-2.45-3.87-3.83c-0.47-0.55-0.85-1.18-1.56-1.47c0-3.71,0-7.43,0-11.14c0.84-0.72,0.24-1.7,0.45-2.53
	c0.56,0.03,0.64,0.52,0.83,0.89c1.3,2.59,3.17,4.65,5.71,6.08c2.42,1.37,4.85,2.72,7.29,4.05c10.25,5.57,20.51,11.13,30.77,16.69
	c5.29,2.87,10.58,5.72,15.86,8.59c14.2,7.7,28.39,15.4,42.58,23.1c11.29,6.13,22.62,12.19,33.86,18.41
	c7.29,4.03,15.13,4.93,23.17,4.09c5.4-0.57,10.21-3,14.82-5.91c17.64-11.12,35.37-22.1,53.07-33.11c15-9.32,30.02-18.61,45.01-27.93
	c6.39-3.97,12.79-7.94,19.16-11.95c5.7-3.59,11.44-7.12,17.14-10.71c19.63-12.33,39.44-24.37,59.05-36.73
	c18.78-11.83,37.67-23.5,56.51-35.25c2.81-1.75,5.38-3.73,6.82-6.84c0.01,3.03,0.1,6.06,0.01,9.09c-0.14,4.34-2.5,7.26-6.09,9.48
	c-12.45,7.73-24.85,15.53-37.28,23.29c-9.55,5.96-19.11,11.9-28.67,17.84c-10.8,6.7-21.62,13.36-32.4,20.08
	c-8.5,5.29-16.95,10.66-25.44,15.96c-9.16,5.72-18.35,11.38-27.51,17.09c-10.69,6.66-21.36,13.36-32.05,20.03
	c-9.55,5.96-19.11,11.89-28.66,17.84c-9.55,5.94-19.1,11.9-28.67,17.83c-5.23,3.25-10.43,6.56-15.75,9.65
	c-3.67,2.14-7.83,2.73-11.99,3.09C467.08,659.18,464.97,659.15,462.86,659.12z"/>
							<path class="stci14" d="M462.86,659.12c2.11,0.03,4.22,0.06,6.33,0.09c0.11,0.08,0.22,0.17,0.33,0.25c-2.35,0-4.71,0-7.06,0
	C462.59,659.35,462.73,659.23,462.86,659.12z"/>
							<path class="stci6" d="M743.69,477.93c-1.45,3.1-4.01,5.09-6.82,6.84c-18.84,11.75-37.72,23.42-56.51,35.25
	c-19.61,12.36-39.42,24.4-59.05,36.73c-5.7,3.58-11.44,7.11-17.14,10.71c-6.37,4.01-12.76,7.98-19.16,11.95
	c-15,9.32-30.02,18.61-45.01,27.93c-17.71,11.01-35.43,21.99-53.07,33.11c-4.62,2.91-9.43,5.34-14.82,5.91
	c-8.05,0.85-15.88-0.06-23.17-4.09c-11.24-6.21-22.57-12.28-33.86-18.41c-14.19-7.7-28.39-15.4-42.58-23.1
	c-5.29-2.87-10.58-5.72-15.86-8.59c-10.26-5.56-20.52-11.12-30.77-16.69c-2.44-1.33-4.87-2.68-7.29-4.05
	c-2.53-1.43-4.41-3.49-5.71-6.08c-0.19-0.37-0.27-0.85-0.83-0.89c-0.85-5,1.44-8.53,5.44-11.11c5.02-3.24,10.12-6.35,15.2-9.49
	c11.99-7.42,23.99-14.81,35.98-22.23c8.23-5.1,16.44-10.24,24.67-15.35c11.03-6.84,22.05-13.71,33.13-20.47
	c4.66-2.85,9.44-5.55,14.01-8.51c7.79-5.06,15.78-9.8,23.56-14.86c5.4-3.51,11.12-6.52,16.52-10.01
	c10.65-6.88,21.51-13.41,32.28-20.1c11.35-7.05,22.72-14.08,34.08-21.11c8.72-5.4,17.42-10.81,26.16-16.18
	c4.09-2.52,8.62-3.62,13.39-3.7c5.02-0.08,10.08-0.3,14.9,1.5c2.39,0.89,4.8,1.65,6.97,3.13c1.79,1.23,3.87,2.05,5.8,3.1
	c4.71,2.56,9.41,5.15,14.16,7.63c4.42,2.31,8.73,4.84,13.24,7c3.89,1.87,7.51,4.27,11.37,6.21c6.09,3.06,11.87,6.69,18.05,9.6
	c3.29,1.55,6.39,3.52,9.6,5.25c3.22,1.74,6.5,3.38,9.72,5.12c4.45,2.4,8.86,4.87,13.3,7.29c6.08,3.3,12.15,6.6,18.25,9.85
	c4.14,2.21,8.39,4.2,12.44,6.55c2.71,1.57,5.72,2.76,7.58,5.64C743.6,471.96,744.75,474.67,743.69,477.93z"/>
							<path class="stci15" d="M505.24,680.23c0.9-0.87,0.14-1.99,0.45-2.95c0.56,0.06,0.57,0.54,0.76,0.92c1.12,2.18,2.63,4.11,4.71,5.33
	c13.41,7.93,27.32,14.97,40.96,22.5c8.12,4.48,16.33,8.8,24.48,13.22c12.56,6.8,25.1,13.62,37.65,20.44
	c6.73,3.66,13.45,7.36,20.19,11c3.75,2.03,7.46,4.17,11.33,5.94c5.63,2.58,11.67,3.02,17.79,2.7c4.5-0.24,8.84-1.24,12.71-3.57
	c7.29-4.39,14.49-8.95,21.72-13.44c20.45-12.69,40.91-25.38,61.36-38.09c7.06-4.39,14.07-8.85,21.12-13.26
	c9.25-5.78,18.51-11.53,27.77-17.29c8.76-5.45,17.53-10.89,26.29-16.33c13.31-8.26,26.62-16.53,39.93-24.79
	c5.28-3.28,10.56-6.54,15.83-9.83c13.46-8.42,26.9-16.88,40.38-25.25c2.8-1.73,5.19-3.75,6.67-6.73c0.29,3.82,0.06,7.64-0.37,11.41
	c-0.37,3.26-2.78,5.38-5.37,7.03c-7.01,4.46-14.12,8.76-21.17,13.15c-5.5,3.42-10.96,6.9-16.46,10.33
	c-7.54,4.71-15.09,9.41-22.64,14.09c-10.23,6.34-20.49,12.64-30.71,19c-12.07,7.51-24.11,15.08-36.16,22.61
	c-7.94,4.96-15.9,9.9-23.84,14.85c-8.91,5.56-17.8,11.14-26.71,16.7c-9.55,5.96-19.11,11.89-28.66,17.84
	c-9.55,5.94-19.1,11.89-28.65,17.83c-7.16,4.46-14.19,9.14-21.51,13.3c-5.17,2.94-11.07,3.31-16.97,3.25
	c-6.58-0.07-12.47-2.16-18.12-5.38c-6.44-3.67-13.04-7.07-19.56-10.6c-9.04-4.89-18.07-9.78-27.1-14.67
	c-6.94-3.76-13.88-7.54-20.82-11.31c-9.02-4.9-18.05-9.79-27.08-14.68c-7.21-3.91-14.47-7.71-21.61-11.74
	c-4.44-2.51-9.1-4.61-13.28-7.58c-1.68-1.19-2.91-2.74-4.1-4.34c-0.36-0.49-0.62-1-1.21-1.24
	C505.24,687.16,505.24,683.7,505.24,680.23z"/>
							<path class="stci6" d="M937.35,590.76c-1.48,2.98-3.88,4.99-6.67,6.73c-13.49,8.37-26.92,16.83-40.38,25.25
	c-5.27,3.29-10.55,6.55-15.83,9.83c-13.31,8.26-26.62,16.53-39.93,24.79c-8.77,5.44-17.53,10.88-26.29,16.33
	c-9.26,5.76-18.52,11.51-27.77,17.29c-7.05,4.4-14.06,8.87-21.12,13.26c-20.44,12.71-40.9,25.4-61.36,38.09
	c-7.23,4.49-14.43,9.04-21.72,13.44c-3.87,2.33-8.2,3.33-12.71,3.57c-6.11,0.32-12.15-0.12-17.79-2.7
	c-3.87-1.77-7.58-3.91-11.33-5.94c-6.74-3.65-13.46-7.34-20.19-11c-12.55-6.82-25.09-13.64-37.65-20.44
	c-8.15-4.42-16.36-8.74-24.48-13.22c-13.63-7.53-27.54-14.57-40.96-22.5c-2.08-1.23-3.6-3.16-4.71-5.33
	c-0.19-0.38-0.2-0.86-0.76-0.92c-0.83-5.21,1.59-8.86,5.79-11.48c9.02-5.64,18.11-11.15,27.14-16.76
	c7.21-4.47,14.37-9.02,21.58-13.48c8.34-5.17,16.72-10.28,25.08-15.43c6.21-3.84,12.4-7.72,18.62-11.55
	c8.45-5.22,16.92-10.39,25.35-15.62c5.34-3.31,10.56-6.82,15.97-10.01c7.26-4.27,14.25-8.96,21.54-13.18
	c6.26-3.63,12.26-7.72,18.41-11.54c8.34-5.19,16.72-10.31,25.07-15.47c4.27-2.64,8.53-5.28,12.79-7.93
	c8.17-5.07,16.35-10.14,24.51-15.21c6.65-4.13,13.22-8.41,19.98-12.36c3.05-1.78,6.59-2.58,10.12-3.03
	c3.59-0.45,7.21-0.58,10.81-0.06c5.08,0.73,9.8,2.52,14.28,4.95c5.78,3.14,11.56,6.29,17.33,9.45c7.74,4.24,15.55,8.36,23.29,12.6
	c5.63,3.08,11.33,6.08,17.03,9.05c5.46,2.85,10.78,5.97,16.27,8.77c4.48,2.28,8.85,4.76,13.29,7.1c6.08,3.22,12.15,6.45,18.22,9.69
	c7.68,4.09,15.31,8.26,22.95,12.42c3.35,1.83,6.14,4.69,6.66,8.94C937.94,588.4,937.6,589.57,937.35,590.76z"/>
							<path id="2" class="none" d="M937.89,588.03c-1.48,2.98-3.88,4.99-6.67,6.73c-13.49,8.37-26.92,16.83-40.38,25.25
	c-5.27,3.29-10.55,6.55-15.83,9.83c-13.31,8.26-26.62,16.53-39.93,24.79c-8.77,5.44-17.53,10.88-26.29,16.33
	c-9.26,5.76-18.52,11.51-27.77,17.29c-7.05,4.4-14.06,8.87-21.12,13.26c-20.44,12.71-40.9,25.4-61.36,38.09
	c-7.23,4.49-14.43,9.04-21.72,13.44c-3.87,2.33-8.2,3.33-12.71,3.57c-6.11,0.32-12.15-0.12-17.79-2.7
	c-3.87-1.77-7.58-3.91-11.33-5.94c-6.74-3.65-13.46-7.34-20.19-11c-12.55-6.82-25.09-13.64-37.65-20.44
	c-8.15-4.42-16.36-8.74-24.48-13.22c-13.63-7.53-27.54-14.57-40.96-22.5c-2.08-1.23-3.6-3.16-4.71-5.33
	c-0.19-0.38-0.2-0.86-0.76-0.92c-0.83-5.21,1.59-8.86,5.79-11.48c9.02-5.64,18.11-11.15,27.14-16.76
	c7.21-4.47,14.37-9.02,21.58-13.48c8.34-5.17,16.72-10.28,25.08-15.43c6.21-3.84,12.4-7.72,18.62-11.55
	c8.45-5.22,16.92-10.39,25.35-15.62c5.34-3.31,10.56-6.82,15.97-10.01c7.26-4.27,14.25-8.96,21.54-13.18
	c6.26-3.63,12.26-7.72,18.41-11.54c8.34-5.19,16.72-10.31,25.07-15.47c4.27-2.64,8.53-5.28,12.79-7.93
	c8.17-5.07,16.35-10.14,24.51-15.21c6.65-4.13,13.22-8.41,19.98-12.36c3.05-1.78,6.59-2.58,10.12-3.03
	c3.59-0.45,7.21-0.58,10.81-0.06c5.08,0.73,9.8,2.52,14.28,4.95c5.78,3.14,11.56,6.29,17.33,9.45c7.74,4.24,15.55,8.36,23.29,12.6
	c5.63,3.08,11.33,6.08,17.03,9.05c5.46,2.85,10.78,5.97,16.27,8.77c4.48,2.28,8.85,4.76,13.29,7.1c6.08,3.22,12.15,6.45,18.22,9.69
	c7.68,4.09,15.31,8.26,22.95,12.42c3.35,1.83,6.14,4.69,6.66,8.94C938.49,585.66,938.15,586.84,937.89,588.03z"/>
							<path id="2" class="none" d="M743.18,475.73c-1.45,3.1-4.01,5.09-6.82,6.84c-18.84,11.75-37.72,23.42-56.51,35.25
	c-19.61,12.36-39.42,24.4-59.05,36.73c-5.7,3.58-11.44,7.11-17.14,10.71c-6.37,4.01-12.76,7.98-19.16,11.95
	c-15,9.32-30.02,18.61-45.01,27.93c-17.71,11.01-35.43,21.99-53.07,33.11c-4.62,2.91-9.43,5.34-14.82,5.91
	c-8.05,0.85-15.88-0.06-23.17-4.09c-11.24-6.21-22.57-12.28-33.86-18.41c-14.19-7.7-28.39-15.4-42.58-23.1
	c-5.29-2.87-10.58-5.72-15.86-8.59c-10.26-5.56-20.52-11.12-30.77-16.69c-2.44-1.33-4.87-2.68-7.29-4.05
	c-2.53-1.43-4.41-3.49-5.71-6.08c-0.19-0.37-0.27-0.85-0.83-0.89c-0.85-5,1.44-8.53,5.44-11.11c5.02-3.24,10.12-6.35,15.2-9.49
	c11.99-7.42,23.99-14.81,35.98-22.23c8.23-5.1,16.44-10.24,24.67-15.35c11.03-6.84,22.05-13.71,33.13-20.48
	c4.66-2.85,9.44-5.55,14.01-8.51c7.79-5.06,15.78-9.8,23.56-14.86c5.4-3.51,11.12-6.52,16.52-10.01
	c10.65-6.88,21.51-13.41,32.28-20.1c11.35-7.05,22.72-14.08,34.08-21.11c8.72-5.4,17.42-10.81,26.16-16.18
	c4.09-2.52,8.62-3.62,13.39-3.7c5.02-0.08,10.08-0.3,14.9,1.5c2.39,0.89,4.8,1.65,6.97,3.13c1.79,1.23,3.87,2.05,5.8,3.1
	c4.71,2.56,9.41,5.15,14.16,7.63c4.42,2.31,8.73,4.84,13.24,7c3.89,1.87,7.51,4.27,11.37,6.21c6.09,3.06,11.87,6.69,18.05,9.6
	c3.29,1.55,6.39,3.52,9.6,5.25c3.22,1.74,6.5,3.38,9.72,5.12c4.45,2.4,8.86,4.87,13.3,7.29c6.08,3.3,12.15,6.6,18.25,9.85
	c4.14,2.21,8.39,4.2,12.44,6.55c2.71,1.57,5.72,2.76,7.58,5.64C743.09,469.76,744.24,472.47,743.18,475.73z"/>
							<g>
								<g>
									<path class="stci6" d="M460.36,548c-1.02,4.73-4.33,10.6-9.7,14.26c-9.55,6.51-20.93,5.22-28.02-5.19
			c-7.15-10.5-4.19-21.57,5.36-28.07c8.36-5.7,16.19-4.21,21.53,3.21l-6.28,6.51c-2.7-3.87-6.19-5.11-10.32-2.29
			c-4.94,3.37-6.06,9.07-2.02,15.01c3.98,5.84,9.7,6.89,14.93,3.33c3.75-2.56,5.91-6.53,6.77-10.18L460.36,548z"/>
									<path class="stci6" d="M448.96,510.79c2.57-1.75,4.93-1.96,6.74,0.7c1.81,2.66,0.75,4.78-1.82,6.53c-2.61,1.78-5.02,2.03-6.83-0.64
			C445.23,514.72,446.34,512.58,448.96,510.79z M471.26,535.07c0.84,1.24,1.79,2.12,3.07,1.25c1-0.68,1.83-2.01,1.88-2.25l4.64,6.1
			c-0.06,0.32-2.24,3.05-5.23,5.09c-3.95,2.69-7.59,2.24-11.02-2.8l-12.46-18.3l7.99-5.44L471.26,535.07z"/>
									<path class="stci6" d="M479.21,505.08c3.18-2.17,6.2-2.76,8.8-2.3l-1.43-2.09l7.99-5.44l14.6,21.44c5.86,8.61,3.03,16.72-4.33,21.74
			c-5.28,3.59-9.52,4.11-11.75,4.52l-2.67-7.5c2.28-0.23,5.93-0.7,9.59-3.19c2.95-2.01,4.77-4.85,2.31-8.46l-1.39-2.04
			c-0.66,2.61-2.47,5.16-5.47,7.2c-6.8,4.63-15.12,2.43-19.55-4.09C471.41,518.26,472.41,509.71,479.21,505.08z M493.01,520.46
			c1.85-1.26,3.05-3.19,3.54-5.12l-4.24-6.23c-1.69-0.24-4.15,0.04-6.1,1.37c-3.47,2.36-4.25,5.82-2.18,8.86
			C486.08,522.33,489.59,522.79,493.01,520.46z"/>
									<path class="stci6" d="M538.02,489.59c0.84,1.24,1.89,2.06,3.08,1.25c0.86-0.58,1.42-1.38,1.92-2.28l4.59,6.13
			c-0.97,1.43-2.85,3.47-5.23,5.09c-2.85,1.94-5.55,2.18-8.09,0.29c-0.3,3.13-1.88,6.43-5.21,8.7c-6.8,4.63-15.09,2.48-20.17-4.99
			c-5.15-7.56-4.15-16.1,2.65-20.73c3.09-2.1,6.12-2.77,8.82-2.18l-1.49-2.19l7.99-5.44L538.02,489.59z M524.56,487
			c-1.68-0.32-4.13,0.17-5.98,1.43c-3.47,2.36-4.02,6.15-1.53,9.81c2.43,3.57,6.2,4.41,9.62,2.08c1.76-1.2,3.03-3.11,3.52-5.04
			L524.56,487z"/>
									<path class="stci6" d="M537.87,452.72l17.09,25.1c1,1.47,2.08,2.13,3.32,1.29c0.86-0.58,1.52-1.45,2.07-2.38l4.59,6.13
			c-0.85,1.21-2.76,3.41-5.33,5.16c-3.95,2.69-7.71,2.47-11.27-2.76l-18.45-27.1L537.87,452.72z"/>
									<path class="stci6" d="M589.49,454.52c0.84,1.24,1.89,2.06,3.08,1.25c0.86-0.58,1.42-1.38,1.92-2.28l4.59,6.13
			c-0.97,1.43-2.85,3.47-5.23,5.09c-2.85,1.94-5.55,2.18-8.09,0.29c-0.3,3.13-1.88,6.43-5.21,8.7c-6.8,4.63-15.09,2.48-20.17-4.99
			c-5.15-7.56-4.15-16.1,2.65-20.73c3.09-2.1,6.12-2.77,8.82-2.18l-1.49-2.19l7.99-5.44L589.49,454.52z M576.03,451.92
			c-1.68-0.32-4.13,0.17-5.98,1.43c-3.47,2.36-4.02,6.15-1.53,9.81c2.43,3.57,6.2,4.41,9.62,2.08c1.76-1.2,3.03-3.11,3.52-5.04
			L576.03,451.92z"/>
								</g>
								<g>
									<path class="stci6" d="M497.79,547.54l14.22-9.87c7.13-4.95,13.24-5.13,17.33,0.77c2.23,3.21,2.09,6.74,0.06,9.97
			c5.14-2.17,9.61-1.49,12.75,3.05c3.74,5.38,1.88,11.58-5.68,16.82l-16.39,11.38L497.79,547.54z M519.48,551.16
			c2.83-1.97,3.78-4.03,2.28-6.2c-1.57-2.27-3.71-2.11-6.54-0.15l-5.1,3.54l4.36,6.28L519.48,551.16z M530.9,562.77
			c2.97-2.07,3.65-4.22,2.14-6.39c-1.64-2.36-4.09-2.27-7.21-0.1l-6.89,4.79l4.69,6.75L530.9,562.77z"/>
									<path class="stci6" d="M547.74,522.85c7.75-5.38,17.24-3.7,22.46,3.81c5.15,7.42,3.36,16.85-4.39,22.23
			c-7.66,5.31-17.12,3.68-22.26-3.74C538.33,537.64,540.09,528.17,547.74,522.85z M560.82,541.71c3.45-2.39,3.92-6.57,1.72-9.73
			c-2.26-3.26-6.33-4.29-9.78-1.89c-3.36,2.33-3.82,6.5-1.56,9.76C553.4,543.01,557.47,544.04,560.82,541.71z"/>
									<path class="stci6" d="M591.28,494.46l0.43,17.27l15.9,6.26l0.43,0.61l-8.55,5.93l-10.14-4.52l0.73,11.04l-8.6,5.97l-0.43-0.61
			l-0.35-17.05l-15.98-6.48l-0.39-0.57l8.51-5.9l10.28,4.63l-0.69-11.28l8.46-5.87L591.28,494.46z"/>
								</g>
							</g>
							<g>
								<g>
									<path class="stci6" d="M632.32,679.94c-0.98,4.74-4.24,10.63-9.58,14.33c-9.5,6.58-20.89,5.38-28.06-4.97
			c-7.24-10.44-4.36-21.53,5.14-28.11c8.32-5.76,16.16-4.34,21.55,3.04l-6.23,6.55c-2.73-3.84-6.23-5.06-10.34-2.21
			c-4.91,3.41-5.99,9.12-1.9,15.03c4.03,5.81,9.75,6.81,14.95,3.21c3.73-2.59,5.86-6.58,6.69-10.23L632.32,679.94z"/>
									<path class="stci6" d="M634.96,647.53c7.75-5.37,17.25-3.69,22.45,3.83c5.14,7.42,3.35,16.85-4.41,22.22
			c-7.66,5.3-17.12,3.67-22.26-3.76C625.53,662.31,627.3,652.84,634.96,647.53z M648.02,666.4c3.45-2.39,3.92-6.56,1.73-9.73
			c-2.26-3.26-6.33-4.29-9.78-1.9c-3.36,2.32-3.83,6.5-1.57,9.76C640.59,667.69,644.66,668.72,648.02,666.4z"/>
									<path class="stci6" d="M678.74,618.18L690,634.44c0.85,1.23,1.81,2.11,2.99,1.29c0.81-0.56,1.41-1.4,1.95-2.33l4.64,6.09
			c-0.84,1.42-2.83,3.5-5.19,5.13c-2.7,1.87-5.29,2.26-7.76,0.62c-0.4,3.36-2.45,6.46-5.91,8.85c-6.38,4.42-12.25,1.7-16.64-4.64
			l-9.89-14.28l7.95-5.5l9.1,13.14c2.26,3.26,4.29,4.17,7.5,1.94c2.27-1.57,2.88-3.82,2.82-5.52l-10.77-15.56L678.74,618.18z"/>
									<path class="stci6" d="M695.86,606.31l1.9,2.74c0.49-3.14,2.16-6.18,5.32-8.37c6.38-4.42,12.45-1.42,16.64,4.64l3.99,5.77
			c0.85,1.23,1.85,2.07,3.09,1.22c0.76-0.53,1.41-1.4,1.95-2.33l4.59,6.12c-0.93,1.49-2.73,3.43-5.14,5.1
			c-3.97,2.75-7.58,2.38-11.09-2.68l-4.42-6.38c-2.19-3.17-4.87-3.9-7.57-2.04c-2.22,1.54-2.64,4.27-2.36,6.18l10.22,14.75
			l-7.94,5.5l-17.13-24.73L695.86,606.31z"/>
									<path class="stci6" d="M726.28,575.92l4.36,6.29l8.56-5.93l4.26,6.15l-8.56,5.93l5.96,8.61c1.74,2.51,3.5,2.83,5.39,1.52
			c1.7-1.18,2.91-3.28,3.49-4.87l6.6,4.38c-1.41,3.22-3.99,6.47-7.87,9.15c-5.15,3.57-10.48,2.64-14.61-3.32l-6.91-9.98l-4.21,2.91
			l-4.26-6.15l4.44-3.08l-3.36-6.98L726.28,575.92z"/>
									<path class="stci6" d="M753.24,579.14c-0.88-1.28-1.81-2.11-3.09-1.22c-0.99,0.69-1.85,1.98-1.99,2.28l-4.56-6.08
			c0.03-0.37,2.21-3.07,5.14-5.1c2.93-2.03,5.67-2.32,8.29-0.35c-0.36-3.04,0.36-5.64,3.49-7.8c2.32-1.6,4.8-2.06,5.22-1.86
			l4.02,7.92c-0.47-0.17-3.12,0.55-5.48,2.19c-2.93,2.03-2.33,5.11-0.8,7.83l8.94,12.91l-7.94,5.5L753.24,579.14z"/>
									<path class="stci6" d="M775.01,551.46l16.76,12.18l-5.46-20.01l7.99-5.53l7.06,31.21c2,8.55,1.42,13.36-4.45,17.42
			c-2.17,1.51-4.95,2.24-6.82,2.27l-3.06-6.84c1.61-0.2,3.67-0.65,5.28-1.77c1.99-1.38,2.15-2.96,1.74-5.26l-0.26-1.29l-27.59-16.3
			L775.01,551.46z"/>
								</g>
								<g>
									<path class="stci6" d="M684.52,664.71l13.73-10.54c6.88-5.29,12.97-5.76,17.35-0.06c2.38,3.1,2.41,6.63,0.54,9.95
			c5.03-2.41,9.52-1.95,12.88,2.43c3.99,5.2,2.43,11.47-4.86,17.07l-15.82,12.15L684.52,664.71z M706.36,667.29
			c2.74-2.1,3.59-4.2,1.98-6.3c-1.68-2.19-3.81-1.93-6.54,0.17l-4.93,3.78l4.66,6.06L706.36,667.29z M718.32,678.33
			c2.87-2.21,3.44-4.39,1.83-6.48c-1.75-2.28-4.2-2.07-7.21,0.24l-6.66,5.11l5.01,6.52L718.32,678.33z"/>
									<path class="stci6" d="M733.23,637.66c7.48-5.74,17.04-4.53,22.61,2.73c5.5,7.16,4.17,16.67-3.32,22.41
			c-7.39,5.67-16.92,4.5-22.42-2.66C724.54,652.88,725.84,643.33,733.23,637.66z M747.2,655.86c3.33-2.56,3.6-6.75,1.25-9.8
			c-2.42-3.15-6.53-3.98-9.86-1.42c-3.24,2.49-3.5,6.68-1.09,9.83C739.85,657.52,743.96,658.35,747.2,655.86z"/>
									<path class="stci6" d="M775.35,607.2l1.26,17.23l16.18,5.49l0.45,0.59l-8.26,6.34l-10.34-4.03l1.26,10.99l-8.31,6.37l-0.45-0.59
			l-1.17-17.01l-16.27-5.71l-0.42-0.55l8.21-6.3l10.49,4.13l-1.23-11.24l8.17-6.27L775.35,607.2z"/>
								</g>
							</g>
							</svg>


					</div>


				</div>

			</div >
			<div client="1" hall="2" session="0" id="rsr" className="hasSVG d-none">

				<ExampleDataTable />
			</div>
		</>
	)

}

export default Viewssvg